<div class="footer">
    <img src="assets/details/footer-pin.svg" class="bg-right">

    <div class="content">
        <div class="contact-info">
            <div>
                <img ngSrc="assets/icons/mail.svg" alt="Mail Icon" height="16" width="20">
                <a href="mailto:uicd.md@gmail.com" class="email">uicd.md&#64;gmail.com</a>
            </div>
            <div>
                <img ngSrc="assets/icons/phone.svg" alt="Phone Icon" height="18" width="20">
                <span>+373 78 432 439</span>
            </div>
        </div>
        <div class="partnership">
            <div class="partners">
                <span>{{ footerPartners }}</span>
                <img src="assets/images/footer/partners/p1.webp" alt="Univers Centrul de Resurse" title="Univers Centrul de Resurse">
                <img src="assets/images/footer/partners/p2.webp" alt="Posibilitatea mea" title="Posibilitatea mea" style="width: 55px; height: 58px; margin-top: -5px">
            </div>
            <div class="support">
                <span>{{ footerSupport }}</span>
                <img src="assets/images/footer/support/s1.webp" alt="Co-founder by the European Union" title="Co-founder by the European Union">
                <img src="assets/images/footer/support/s3.webp" alt="European Heritage Hub" title="European Heritage Hub" style="width: 100px; height: 50px; margin-top: 0">
                <img src="assets/images/footer/support/s4.webp" alt="Aliph" title="Aliph">
                <img src="assets/images/footer/support/s2.webp" alt="Europa Nostra" title="Europa Nostra" style="height: auto;">
            </div>
        </div>
        <hr/>
        <div class="copyright">
            <span>UICD © 2024</span>
        </div>
    </div>
</div>
