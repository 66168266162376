<app-header></app-header>

<div class="description">
    <div class="main-content">
        <div class="section">
            <div class="title">
                <h2>{{ aboutUs }}</h2>
            </div>
            <div class="content">
                <div class="image-content">
                    <i class="fa-solid fa-seedling"></i>
                </div>
                <div class="text-content">
                    <p>{{ aboutUsText1 }}</p>
                </div>
            </div>
            <div class="content">
                <div class="image-content">
                    <i class="fa-solid fa-person-hiking"></i>
                </div>
                <div class="text-content">
                    <p>{{ aboutUsText2 }}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="sponsors">
    <h2>{{ sponsors }}</h2>
    <p>{{ sponsorsDescription }}</p>
    <div class="carousel">
        <app-slider></app-slider>
    </div>
</div>
