<div class="container" *ngIf="paginatedProducts.length > 0; else notFoundTemplate">
    <div class="item" *ngFor="let product of paginatedProducts" (click)="navigateToPlace(product.id)">
        <div class="img-container">
            <img [src]="product.image" [alt]="product.name">
        </div>
        <p>{{ product.name }}</p>
    </div>
</div>

<ng-template #notFoundTemplate>
    <div class="not-found">
        {{ notFound }}
    </div>
</ng-template>

<div class="pagination-container" *ngIf="paginatedProducts.length > 0">
    <button class="prevPage"
            (click)="prevPage()"
            [disabled]="currentPage === 1">
        <i class="fa-solid fa-chevron-left"></i>
    </button>
    <div class="page-numbers">
        <button *ngFor="let page of pageNumbers"
                (click)="goToPage(page)"
                [class.active]="page === currentPage">
            {{ page }}
        </button>
    </div>
    <button class="nextPage"
            (click)="nextPage()"
            [disabled]="currentPage === totalPages">
        <i class="fa-solid fa-chevron-right"></i>
    </button>
</div>
