<div class="loading-container">
    <div class="van-body">
        <div class="window-layer">
            <div class="window">
                <div class="me"></div>
            </div>
            <div class="window"></div>
            <div class="window"></div>
            <div class="window"></div>
        </div>
        <div class="canopy"></div>
        <div class="roofrack">
            <div class="support"></div>
            <div class="support"></div>
            <div class="board"></div>
        </div>
        <div class="tailpipe">
            <div class="smoke"></div>
            <div class="smoke"></div>
            <div class="smoke"></div>
        </div>
        <div class="rear"></div>
        <div class="front"></div>
    </div>
    <div class="wheels">
        <div class="wheel"></div>
        <div class="wheel"></div>
    </div>
</div>
