import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    private translations: Translations = {
        en: {
            menuItemHome: 'Home',
            menuItemLibrary: 'Library',
            menuItemForum: 'Forum',
            aboutUs: 'About Us',
            aboutUsText1:
                '"Unity in Cultural Diversity" is a project aimed at preserving cultural heritage sites in Moldova at the intersection of the cultures of three neighboring countries: Ukraine, Moldova, and Romania. Our goal is to create an electronic library of cultural heritage sites to popularize them, preventing the destruction and neglect of some of them.',
            aboutUsText2:
                'Additionally, the project organizes seasonal thematic camps, culminating in the restoration of seasonal holidays according to their traditional celebrations, reproducing the customs and traditions at the intersection of the three cultures.',
            sponsors: 'Masterminds',
            sponsorsDescription: 'This project exists thanks to our ideas, which we have been able to implement with the support of our sponsors.',
            footerPartners: 'Partners',
            footerSupport: 'Supported by foundations',
            forumAboutUs: 'About Us',
            forumAboutUsText1: 'The "Unity in Cultural Diversity" project aims to preserve cultural heritage sites of various ethnic groups in Moldova by popularizing them on the Internet to prevent destruction and neglect.',
            forumAboutUsText2: 'Our team\'s task is to visit cultural heritage sites, take photos, videos, and tell you about them. We are confident that this electronic library will become a wonderful guide for you through Moldova and its cultural diversity.',
            forumFormTitle: 'Forum',
            forumFormText1: 'Our country is very rich in cultural heritage sites, and our goal is to tell you about these places, increasing interest to prevent their destruction. This forum was created so that anyone interested can suggest a heritage site (or vote for a favorite) that we should visit and describe.',
            forumFormText2: 'Here you can send us a letter to our email and other social networks or join the forum on Telegram.',
            formLabelName: 'Enter first and last name',
            formLabelEmail: 'Enter email',
            formLabelPhone: 'Enter phone number',
            formLabelMessage: 'Enter your message',
            formTelegramButton: 'Telegram Forum',
            formSendButton: 'Send',
            categoryTitleItem1: 'All',
            categoryTitleItem2: 'Sacred sites',
            categoryTitleItem3: 'Festivals and fairs',
            categoryTitleItem4: 'Museums and theaters',
            categoryTitleItem5: 'Structures',
            categoryTitleItem6: 'Fortresses',

            libraryTitleItem1: '"Ivana Kupala Camp"',
            libraryTitleDescription: 'The merging of people\'s culture at the border of three ethnic influences',
            item1DescriptionText: 'In early July, on the picturesque shores of a lake in the border region of Ukraine, Moldova, and Romania, in the city of Drochia a three-day event called "Ivana Kupala Camp" was held, dedicated to the traditional celebration of Ivana Kupala. This holiday has deep roots in the cultures of all three countries and is a symbol of unity and the richness of their cultural traditions.',
            itemArticle1: '<h4>Day 1 and 2: Familiarization with Culture and Traditions</h4> ' +
                '<br>The first and second days of the event were dedicated to introducing participants to the cultural features and traditions of celebrating Ivana Kupala in Ukraine, Moldova, and Romania. <br>' +
                '<br><b>Ukraine:</b> In Ukraine, Ivana Kupala (Kupala Night) is celebrated as the day of the summer solstice, symbolizing purification and rebirth. Traditional elements include jumping over the fire, weaving wreaths from wildflowers, and launching them on the river to predict one\'s future spouse.<br>' +
                '<br><b>Moldova:</b> In Moldova, this holiday is known as "Sânziene". It is also associated with the summer solstice and includes rituals of purification by fire and water. A special feature of the Moldovan celebration is the "Hora" dance, performed around the fire.<br>' +
                '<br><b>Romania:</b> In Romania, Sânziene is a holiday of mystical and magical rituals. Wreaths made of flowers, woven on Sânziene, are believed to have magical powers and protect against evil spirits. In the evening, bonfires are lit, and dances are performed around them.',
            itemArticle2: '<b>Common Traditions:</b><br>' +
                '<br><ul>' +
                '<li>Weaving wreaths from wildflowers</li>' +
                '<li>Lighting bonfires and jumping over them</li>' +
                '<li>Dancing and singing around the fire</li>' +
                '</ul><br>' +
                '<b>Unique Traditions of Each Country:</b>' +
                '<br><ul>' +
                '<li>Ukraine: Launching wreaths on the water for divination</li>' +
                '<li>Moldova: The "Hora" dance</li>' +
                '<li>Romania: Magical rituals with wreaths</li>' +
                '</ul><br>' +
                'During the first two days, participants could take part in master classes on weaving wreaths from wildflowers and creating protective amulets by painting pendants with ancient runes. This allowed each participant to immerse themselves in the traditions and culture of their neighbors.',
            itemArticle3: '<h4>Day 3: Celebrating Ivana Kupala</h4>' +
                '<br>' +
                '<p>The third day was the culmination of the camp when the celebration of Ivana Kupala took place on the lakeshore. Participants prepared the venue for the celebration by weaving wreaths, painting faces with special paints (for children), and creating three photo zones to capture memorable moments.</p>' +
                '<p>Folk ensembles were invited to the event, performing songs in Ukrainian, Moldovan, and Romanian, as well as organizing dance performances with folk elements.</p>',
            itemArticle4: '<p>A handicraft exhibition was organized by folk artisans, where guests could admire and purchase unique items created with love and craftsmanship.</p>' +
                '<p>To ensure the safety of participants, observers were appointed, and the city authorities provided two police crews. Local rescue teams ensured water safety.</p>' +
                '<p>Many participants shared their impressions of the "Ivana Kupala Camp". They noted that the event was extremely engaging and informative. <i>"Together with @uicd.md, for 3 days, we delved deeply into the traditions, myths, and customs of Ivana Kupala,"</i> shares Teodora. Alexander added: <i>"I want to express my immense gratitude to the organizers for the unforgettable Ivana Kupala celebration in the city of Drochia. Thanks to the organizers\' hard work and care, all the city\'s residents were able to enjoy the magnificent atmosphere and traditions. This day will remain in our hearts as a bright and joyful memory."</i> Participants also noted that the event was a great opportunity for new friendships and strengthening cultural ties between countries.</p>',
            itemArticle5: '<p>"Ivana Kupala Camp" was not just a celebration but a true cultural exchange between the three countries. Participants were able to immerse themselves in the richness of traditions, make new friends, and strengthen cultural ties. This camp demonstrated how important it is to preserve and develop cultural heritage, uniting people through traditions and customs.</p>' +
                '<p>We thank all the participants and guests for creating an unforgettable atmosphere and look forward to seeing you at future events!</p>',
            itemArticle6: 'Thanks to the support of the European Heritage Hub pilot Project co-funded by the European Union and supported by the ALIPH Foundation.',

            libraryTitleItem2: 'The Soroca Fortress',
            libraryTitleDescription2: 'Pearl of Moldovan History',
            item2DescriptionText: 'Soroca Fortress is one of Moldova’s most famous historical landmarks. The citadel, located on the right bank of the Dniester River, was built by order of the great Moldovan ruler Ștefan the Great more than five hundred years ago.',
            item2Article1:
                'Constructed in the last quarter of the 15th century (the exact completion date is unknown), it served as a reliable stronghold in defending the northern borders of the Principality of Moldova from frequent Crimean Tatar raids.<br>' +
                '<br>The citadel’s shape is unusual for Moldovan fortresses – a circular fortress, with a diameter of about 37.5 meters, and five towers. The towers themselves also surprise visitors – it’s not immediately apparent that four of the five towers are round, while one (the gate tower) is rectangular.<br>' +
                '<br>Such a design was innovative for its time and provided the fortress with excellent defensive capabilities, making it almost impregnable, as evidenced by the legends associaed with Soroca Fortress. ',
            item2Article2: '<h4>Restoration and Revival:</h4>' +
                '<br>After centuries of history, large-scale restoration work was completed in 2015. Thanks to these efforts, the historical and architectural monument was revealed to visitors in all its former glory. The restorers carefully reconstructed the walls, towers, and interior spaces. However, the restoration process did not stop there. From April 2021, the fortress was almost closed to visitors and fully regained its tourist attraction status only in April 2024, which tourists quickly took advantage of by flocking to the citadel.',
            item2Article3: '<h4>The Legendary Sword of Ștefan cel Mare</h4>' +
                '<br>' +
                '<p>A museum dedicated to the history of Moldova and specifically the era of Ștefan the Great was created within the fortress. One of the museum\'s main exhibits is a copy of the famous sword of the Moldovan ruler. This exhibit allows visitors to immerse themselves in the medieval atmosphere and imagine the valiant warrior defending his land.</p>' +
                '<p>The original sword is currently kept in Turkey, while neighboring Romania is negotiating its return to its historic homeland.</p>',
            item2Article4: 'Today, Soroca Fortress is not just a historical monument but also a popular tourist destination. Visiting it offers a unique opportunity to connect with Moldova’s rich historical heritage, learn more about the lives of our ancestors, and enjoy Moldova’s beautiful landscapes.',

            libraryTitleItem3: 'The Cosăuți Monastery',
            item3DescriptionText: 'Cosăuți Monastery is an amazing place where history and modernity intertwine into a harmonious whole. Located in the picturesque valley of the Dniester River, about 5 kilometers from the village of Cosăuți, this monastery attracts pilgrims and tourists from all over the country.',
            item3Article1: '<h4>History of the Monastery</h4>' +
                '<p>The first mentions of the monastery date back to 1729. At that time, a small wooden monastery dedicated to the Nativity of the Virgin Mary appeared at the place later known as "la Pristol." Its founders were monks Pavel and Gavriil. The monastery existed for about 160 years, but in 1833 it was closed.</p>' +
                '<p>In the 20th century, due to earthquakes and landslides, almost nothing remained of the old monastery. Only a small chapel survived, where locals and pilgrims continued to pray.</p>',
            item3Article2: '<h4>Revival of Spiritual Life:</h4>' +
                '<br>' +
                '<p>At the end of the 20th century, in 1994, at the initiative of local priest Ieronim Palii, the revival of the monastery began. A new church dedicated to the Dormition of the Mother of God was built on the site of the old monastery. The church project was developed with the blessing of the Metropolitan of Moldova and Bukovina, Daniel.</p>' +
                '<p>The construction of the monastery was carried out under difficult conditions, but thanks to the dedication of Father Ieronim and the support of parishioners, the church was consecrated in 1999. Gradually, a monastic complex, including cells, a refectory, and other auxiliary buildings, grew around the church.</p>',
            item3Article3: '<h4>The Monastery Today</h4>' +
                '<br>' +
                '<p>Today, Cosăuți Monastery is not only a place for prayer but also a popular tourist attraction. Pilgrims and tourists from all over the country come here to connect with the roots of Orthodox faith, enjoy the peace and beauty of nature.</p>' +
                '<p>The monastery offers prayer services (held in Romanian and Russian) and pilgrim tours (including visits to other holy sites in Moldova).</p>' +
                '<p>The monastery grounds are well-maintained and ideal for quiet relaxation.</p>',
            item3Article4:
                '<p>Another unique feature of the monastery is its mineral water spring – just a few minutes\' walk from the monastery, near picnic areas, there is a healing water spring.</p>',
            item3Article5:
                'Cosăuți Monastery is a living example of how faith and labor can change the world. It is a place where everyone can find spiritual comfort and feel connected to eternity. The monastery is open for visits daily.',

            libraryTitleItem4: 'The Gypsy Hill',
            item4DescriptionText: 'Soroca is famous not only for its ancient fortress but also for a unique neighborhood known as Gypsy Hill. Situated on an elevation, this quarter has become the true hallmark of the city thanks to its unusual houses.',
            item4Article1:
                'The history of the Roma\'s arrival in Soroca is shrouded in legend. According to one version, the lands on the hill were granted to the Roma by Moldavian ruler Stephen III as a token of gratitude for their help in arming the army. One way or another, the Roma have lived in Soroca for a long time, preserving their traditions and protecting their culture.<br>' +
                '<br>The architecture of the houses on Gypsy Hill is breathtaking. It’s a true architectural kaleidoscope where elements of classicism, baroque, modernism, and even oriental styles coexist. Each house is a work of art, adorned with stucco, columns, turrets, and various decorative elements. Here you can find figures of mythical creatures, religious symbols, as well as copies of famous architectural structures like the spire of the Admiralty or the dome of a mosque.' +
                '<br>However, many houses on Gypsy Hill remain unfinished. Nevertheless, even in their unfinished state, these buildings give the city a special charm and attract many tourists.',
            item4Article2:
                'Gypsy Hill is a place where the past and present intertwine, creating a unique atmosphere. It\'s a place you must visit to see for yourself what a true architectural wonder looks like. However, those who decide to tour this place should not forget about the traditions, customs, and culture of the Roma people, which may differ significantly from what tourists have seen in other parts of Moldova.',

            libraryTitleItem5: 'The Bechir’s Cave',
            item5DescriptionText: 'Bechir’s Gorge or Bechir\'s Cave is a unique historical and natural monument. Carved into the rock, the so-called monk\'s cell is considered one of the oldest religious monuments in the region, dating back to the 6th-8th centuries.',
            item5Article1:
                'The monastic cave complex is located 12-14 meters high on a steep limestone cliff. The cave is a small room with an altar facing north. Inside, there is a stone bed, a table, niches for icons and a lamp, and even a cellar.<br>' +
                '<br>Locals often associate the cave\'s name with the last Moldavian haiduc, Bechir, who was hiding from persecution. According to another legend, a monk lived in the cave and came out every Sunday to preach to the people. However, these remain mere legends. Based on research conducted by Vasilie Trofaile, a native of the Soroca district, Bechir was actually a Turkish merchant who traded in the region and sought to expand his influence.' +
                '<br>It seems we may never know the true identity of Bechir, but the monument that bears his name continues to attract tourists, though accessing the cave is difficult.',
            item5Article2:
                'Bechir\'s Cave is not only a religious but also a geological monument. It is located in a picturesque canyon formed by the erosion of limestone rocks. You can reach it by walking through the forest, not far from the climb to the Candle of Gratitude.' +
                '<br>The path tourists need to take is scenic – on the left, a river flows with large boulders, fallen from the hilltop long ago, while trees rustle around, birds sing, and it feels like you\'ve entered an enchanted forest.',
            item5Article3:
                'The climb to Bechir\'s Cave begins near two monumental neighbors – a massive poplar tree with a trunk several arm spans wide and a giant boulder covered with tourists\' signatures.' +
                '<br>From these memorable "friends," you must ascend a rather steep path a few dozen meters up to the cave itself. So, if you decide to see Bechir’s Gorge with your own eyes and make the climb to the cave, it’s best to wear non-slip shoes to ensure nothing distracts you from your walk through the forest and canyon.',
            item5Article4:
                'Despite its historical and cultural value, the cave was long unprotected by the state. However, thanks to the efforts of the public and local authorities, Bechir’s Cave was officially added to Moldova\'s list of protected monuments.' +
                '<br>Currently, efforts are underway to preserve and restore this unique site, and scientists continue to study the cave to uncover all the secrets of its past.' +
                '<br>Bechir’s Cave is a unique place where history intertwines with nature, offering tourists unforgettable impressions and the chance to touch the ancient past.',

            libraryTitleItem6: 'Țaul Park',
            libraryTitleDescription6: 'A Lost Paradise in Moldova',
            item6DescriptionText: 'In the north of Moldova, in the village of Țaul in the Dondușeni district, lies a true treasure – Țaul Park. This unique piece of nature, sprawling over 46.2 hectares, is one of the largest and most beautiful parks in Moldova. Created in the early 20th century based on the project of renowned landscape architect Ipolit Vladislavski-Padalko, the park represents a harmonious blend of natural beauty and human creativity.',
            item6Article1: 'The initiator of this green paradise was Andrei Pommer, a well-known banker and philanthropist. Desiring to create a secluded place for rest and contemplation, he purchased these lands and invested his heart and resources into their development. The result of his efforts is a magnificent park that continues to amaze with its beauty and diversity.',
            item6Article2: 'Țaul Park is truly an open-air botanical garden. It hosts hundreds of plant species from around the world, from mighty oaks to exotic vines. Every alley, every corner of the park, is a work of landscape art. The atmosphere is especially enhanced by artificially created ponds, where graceful swans glide.',
            item6Article3: 'At the heart of the park stands the Pommer Estate, an exquisite mansion built in the early 20th century. This magnificent building, designed in a classical style, impresses with its elegance and grandeur. Unfortunately, the years and lack of proper care have left the estate in a deplorable state today. However, even in ruins, it retains its splendor and serves as a reminder of its former wealth and luxury.<br>' +
                '<br>The history of the Pommer estate and Țaul Park is full of interesting events and legends. It is said that famous personalities of the time visited these walls, and lovers once strolled through the park. Today, Țaul Park is not just a historical monument, it is a place where one can immerse oneself in the atmosphere of the past and enjoy the beauty of nature.',
            item6Article4: 'Those who visit Țaul Park and the Pommer Estate will encounter unique nature and historical heritage. Nature lovers will also be delighted by the serene rest this unique place offers – Țaul Park is ideal for walks, picnics, and escaping the hustle and bustle of city life.<br>' +
                '<br>Țaul Park is one of Moldova\'s jewels that needs our care. Each of us can contribute to preserving this unique piece of nature. Let us work together to ensure that Țaul Park and the Pommer Estate remain for future generations.',

            libraryTitleItem7: 'St. Nicholas Wooden Church',
            libraryTitleDescription7: 'In Brăicău',
            item7DescriptionText: 'Nestled in a quiet corner of the village of Brăicău in the Dondușeni district, the wooden Church of St. Nicholas is not just a religious structure, but a true treasure of Moldovan architecture. Built in 1829, it is a national monument and represents a unique example of wooden craftsmanship.',
            item7Article1: 'This ancient church harmoniously combines folk traditions and Byzantine motifs and is a source of pride for the local community.<br>' +
                '<br>The church is listed in the Register of State-Protected Monuments. Unfortunately, despite its historical value, this architectural monument is in a state of disrepair. The wooden structures have been significantly damaged by time and are in urgent need of restoration. However, local residents remain hopeful and continue to care for their spiritual heritage.',
            item7Article2: 'A visit to the wooden church in Brăicău will captivate history enthusiasts. <br>' +
                '<br>If you wish to immerse yourself in the 19th-century atmosphere, touch history, and feel the spirit of that time, be sure to visit the village of Brăicău.' +
                '<br>By visiting St. Nicholas Church, you will not only encounter a unique architectural monument but also contribute to its preservation. Every visitor is a step toward saving this priceless treasure of Moldova.',

            libraryTitleItem8: 'Bender Fortress',
            libraryTitleDescription8: 'The Guardian on the Dniester',
            item8DescriptionText: 'Bender Fortress is one of the most significant historical landmarks in Moldova, embodying the rich and turbulent history of this region. Located on the right bank of the Dniester River, it has endured numerous sieges, battles, and reconstructions.',
            item8Article1:
                'Information about the first fortifications on the site of the present-day fortress varies. According to the most common, albeit unproven, version, the first citadel appeared here in the 13th century. The Genoese, active traders of the time, built a small fortification to control trade routes along the Dniester. Later, the fortress was rebuilt, reinforced, and expanded by Moldavian rulers and the Ottomans.',
            item8Article2:
                'However, the true history of Bender Fortress begins in the 16th century when these lands fell under the control of the Ottoman Empire. According to the most scientifically supported version, in 1538, the Turks began constructing a powerful fortress, intended to serve as one of their strongholds in the region. The fortress project was designed by the renowned Turkish architect Sinan. The citadel was built using the latest achievements in military engineering of the time and featured a complex system of fortifications, including towers, walls, moats, and underground passages.' +
                '<br><br>Throughout its long history, Bender Fortress changed hands many times. It survived numerous sieges and battles, served as a refuge for rebels, and became a place of exile for notable historical figures.',
            item8Article3:
                'Bender Fortress is an outstanding monument of 16th-century fortification art. Its architecture combines elements of Eastern and Western European fortifications. The massive walls, towers, and gates create an impression of impregnability.' +
                '<br><br>Bender Fortress is often compared to Soroca Fortress. While partially accurate, this comparison has its distinctions. Built during a similar historical period and serving similar functions, the citadels differ in many ways. Soroca Fortress, being more compact and situated on a high hill, was primarily focused on defense against nomadic raids. In contrast, Bender Fortress, larger and more complex in its structure, was designed to control an important trade route and repel large-scale military actions.',
            item8Article4:
                '<b>Key Features of Bender Fortress:</b>' +
                '<br><ul>' +
                '<li><b>Bastions</b>: Powerful bastions protruding beyond the walls provided flanking fire against the enemy.</li>' +
                '<li><b>Moats</b>: Deep moats made it difficult for attackers to approach the fortress.</li>' +
                '<li><b>Underground Passages</b>: A system of underground passages was used for secret communications, storage of supplies, and evacuation.</li>' +
                '<li><b>Gates</b>: Massive gates protected by towers served as the fortress\'s only entry points.</li>' +
                '<li><b>Artillery Batteries</b>: Numerous cannons on the bastions ensured powerful firepower against opponents.</li>' +
                '</ul><br>',
            item8Article5:
                'Many legends and tales are associated with Bender Fortress. One of the most famous stories speaks of underground tunnels that supposedly connect the fortress with other cities and distant monasteries. Another legend tells of treasures hidden within the fortress walls.',
            item8Article6:
                'A special place in the fortress\'s history is occupied by Baron Munchausen. According to legends, it was in Bender that he performed one of his most incredible feats – flying on a cannonball. Although these stories are fictional, the baron did participate in the Russo-Turkish War of 1735–1739 and may have visited Bender Fortress. To commemorate this, a monument in the form of a cannonball was erected on the fortress grounds.' +
                '<br><br>Today, Bender Fortress is a popular tourist attraction. Every year, thousands of visitors come to touch history and immerse themselves in the atmosphere of the Middle Ages. The fortress hosts various cultural events, festivals, and historical reenactments.',
            item8Article7:
                '<b>What Makes Bender Fortress Unique?</b>' +
                '<br><ul">' +
                '<li>It is a living testament to the region\'s rich past, combining Eastern and Western European architectural traditions.</li>' +
                '<li>A museum on the fortress grounds displays exhibits that tell the history of the fortress and the region. Some items, such as torture devices and descriptions of their use, still inspire awe and horror today.</li>' +
                '<li>Walking along the ancient walls, you will immediately feel enveloped by the fortress\'s medieval atmosphere and air of mystery.</li>' +
                '</ul>' +
                '<br>Bender Fortress is a true Guardian on the Dniester, a place that will not soon reveal all the secrets and mysteries of its builders to their descendants.',

            libraryTitleItem9: 'The Bottle Museum',
            libraryTitleDescription9: 'Did You Know Such a Place Exists in Moldova?',
            item9DescriptionText: 'The Bottle Museum is not just a collection of glass containers but a true work of art crafted by human hands. This museum showcases a unique collection of bottles from around the world, amassed over many years. The museum is located in the village of Tîrnova, near the city of Bender.',
            item9Article1: 'The idea of creating the museum originated with a collector who spent his entire life gathering various bottles. Over time, the collection grew so large that a separate building was needed for its display. Thus, in the 2000s, an extraordinary building shaped like a giant bottle was created.<br>' +
                '<br>The museum building is impressive in size. It stands 28 meters tall and is made of stone. Inside, visitors are greeted by a combination of stone and wood, reminiscent of an ancient fortress cellar. The collection is housed inside the building, spread across several floors, with each floor dedicated to a specific theme. Here, you can see bottles from different eras, countries, and materials.',
            item9Article2: 'The museum\'s exhibition is stunning in its diversity. It features bottles of the most unusual shapes and sizes: from miniature vials to large vessels. There are bottles adorned with paintings, engravings, and inlays. A special section of the collection is dedicated to bottles connected with historical events and famous figures.<br>' +
                '<br>In addition to bottles, the museum displays various items related to the history of glass production and alcoholic beverages. Visitors can see antique glassmaking tools, drink labels, advertising posters, and glasses, goblets, and cups.',
            item9Article3: 'Perhaps the most intriguing aspect is that the bottles on display are not empty. Mounted on special stands and showcases, they form a true historical treasure trove.',
            item9Article4: 'The Bottle Museum is an extraordinary place that leaves no one indifferent. Not only does such a collection of bottles exist nowhere else in the world, but the museum building itself is a work of art, and each bottle tells its own story. Additionally, the museum grounds feature a cozy relaxation area where visitors can enjoy a snack and take in the beautiful views.<br>' +
                '<br>In this remarkable museum, you can not only admire the unique collection but also learn many fascinating facts about the history of glass and alcoholic beverages.',

            libraryTitleItem10: 'Călărașovca Monastery',
            libraryTitleDescription10: 'Assumption Monastery: History, Nature, and Spirituality',
            item10DescriptionText: 'Located in the picturesque valley of the Dniester River, the monastery attracts pilgrims and tourists from all over the world with its ancient history, architecture, and unique atmosphere. The monastery is situated 220 km from Chișinău, and the road leading to it passes through scenic areas, making the journey even more pleasant.',
            item10Article1: 'The exact date of the monastery\'s founding is unknown. According to legend, a hermit monk lived in a cave opposite the current monastery and founded the monastic skete. It is believed that the first wooden church was built here at the end of the 16th or the beginning of the 17th century.',
            item10Article2: 'In 1780, a stone church and bell tower were built on the monastery grounds, taking only one year to complete. The local boyar, Marko Donici, who funded the construction, was granted a lifelong title as the administrator and abbot of the monastery. <br>' +
                '<br>The monastery experienced periods of growth and decline tied to historical events in Moldova. During the Soviet era, it was closed, icons were confiscated, and various institutions occupied the monastery buildings. In 1991, the monastery was reopened and has since been actively restored.',
            item10Article3: 'Călărașovca Monastery is located in a picturesque natural setting, surrounded by cliffs and forests.<br>' +
                '<br>The monastery grounds feature:' +
                '<ul>' +
                '<li>The Assumption Church, built in the 18th century.</li>' +
                '<li>The Church of Saint Mitrophan: A later construction distinguished by its elegant forms and vivid frescoes.</li>' +
                '<li>Monastic cells.</li>' +
                '<li>The rock cell or hermit\'s cave, where, according to legend, the monk who founded the monastery lived. It is also said that for 500 years, monks would retreat here one by one to escape worldly life.</li>' +
                '<li>The refectory.</li>' +
                '<li>The archbishop\'s house.</li>' +
                '<li>A lake.</li>' +
                '<li>The monastery library.</li>' +
                '<li>The bell tower with bells that summon the faithful to worship.</li>' +
                '</ul>' +
                '<br>The monastery is surrounded by cliffs that form picturesque gorges. From the tops of the cliffs, breathtaking views of the Dniester Valley unfold. In the vicinity of the monastery, there are numerous springs with crystal-clear water, which are also surrounded by legends.',
            item10Article4: 'According to one legend, the three springs near the monastery appeared where an angel with candles pointed the monks in the right direction. Today, a well-maintained trail through the "Călărașovca" nature reserve leads to these sacred springs. A journey along this trail is not only an opportunity to quench your thirst with water considered healing but also to enjoy the stunning landscapes: a dense forest, a high hill, and a deep gorge.<br>' +
                '<br>Today, Călărașovca Monastery is one of Moldova\'s most significant spiritual centers. Services, pilgrimages, and various cultural events are held here. The monks also engage in active social work, helping those in need.<br>'+
                '<br>When planning your leisure time, be sure to include a visit to Călărașovca Monastery in your itinerary.',

            libraryTitleItem11: 'Orpheus and Stephen the Great',
            libraryTitleDescription11: 'Legends of the Țipova Monastery',
            item11DescriptionText: 'Situated on steep cliffs, the Țipova Monastery offers visitors a breathtaking view of the Dniester Valley. The complex is one of the most impressive cave monasteries, not only in Moldova but in all of Europe. Its history dates back long before the formation of the Principality of Moldova. According to legends, the first cells were carved into the cliffs as early as the 10th–12th centuries to protect monks from invasions by nomads coming from the Ukrainian steppes.',
            item11Article1:'The craftsmen who created this marvel utilized the natural terrain, transforming the monastery into an impregnable citadel. Even today, accessing the cells is no easy task—visitors must descend carved stone steps of varying heights. Those who persevere beyond just admiring the Dniester\'s views can attempt to pass through a four-meter crack in the cliff, which served as additional protection for the monks.<br>' +
                '<br>The multi-level structure of the monastery spans a vast area. To this day, numerous cells can be found at Țipova.',
            item11Article2: 'The holy site is located on cliffs up to 100 meters high and includes three monastic complexes:  <br>' +
                '<ul>' +
                '<li>The first complex consists of several cells and a church dedicated to the Elevation of the Holy Cross, dating back to the 11th–12th centuries. </li>' +
                '<li>The Church of St. Nicholas was the center of the second monastic complex, founded in the 14th century.</li>' +
                '<li>Most of the cells are found around the Church of the Assumption of the Holy Virgin, dating to the 16th–18th centuries.</li>' +
                '</ul>' +
                '<br>In the early 19th century, this complex continued to function as a skete, and in 1842 it was closed. In 1828, a church was built on the hill above the rocky complexes, and it became a monastery again in 1919. Until that time, the skete was under the administration of the Saharna Monastery.<br>' +
                '<br>During the Soviet era, all monks were expelled, and the buildings on the hill were transferred to the collective farm. The church was converted into a tobacco warehouse. Looting was frequent, and the cliff cells were destroyed. In 1988, the cells were restored and turned into a museum.',
            item11Article3: 'The Țipova Monastery is striking in its complexity. The spaces are interconnected by staircases and internal galleries, creating a true labyrinth. <br>' +
                '<br>The monastery is surrounded by numerous legends. According to one, this is where Moldavian ruler Stephen the Great married Maria Voichița. Another legend claims that this is where the ancient Greek poet Orpheus died and was buried in one of the monastery’s niches.',
            item11Article4: 'The surroundings of the monastery are part of the Țipova Nature Reserve, a true paradise for nature lovers. The local landscape is stunning in its beauty: the calm waters of the Dniester, steep cliffs with hundreds of caves and grottoes, waterfalls, and cascades.<br>' +
                '<br>If you want to immerse yourself in the atmosphere of the past, touch the roots of Christianity in Moldova, and enjoy picturesque landscapes, the Țipova Monastery is a place you must visit. However, as with some other Moldovan landmarks, it’s wise to prepare for the trip—choose clothing and footwear suitable for hiking in rugged terrain.<br>'+
                '<br>At Țipova, history intertwines with legends, and nature amazes with its beauty. Visit and see for yourself! Who knows, perhaps you’ll uncover the secret of Orpheus...',

            libraryTitleItem12: 'Saharna Monastery',
            libraryTitleDescription12: 'A Bastion of Spirituality in the Heart of Moldova',
            item12DescriptionText: 'Saharna Monastery is not just a religious structure but a unique historical and cultural complex located in the picturesque gorge of the Dniester River in the village of Saharna. This monastery, combining ancient cave churches with modern buildings, is one of the most significant pilgrimage centers in the country.',
            item12Article1:'The exact date of the monastery’s founding is unknown, but the first mentions of it date back to the 15th century. It is believed that the first monks took refuge in the cave hermitage to escape enemy raids. They carved cells and churches into the rocks, creating a unique monastic sanctuary.<br>' +
                '<br>The monastery has experienced both periods of prosperity and decline. In the 17th-18th centuries, it flourished, with the construction of a large stone church and numerous utility buildings. At the beginning of the 20th century, it served as a spiritual center of Bessarabia, but in 1964, it was closed and repurposed as a psychiatric hospital. The revival of the monastery began in 1991. Today, around 20 monks reside there.',
            item12Article2: 'The Saharna Monastery complex represents a unique blend of natural and man-made elements. The main attraction is the cave monastery, carved into the cliffs. Churches, monastic cells, and underground passages have been preserved here.<br>' +
                '<br>In addition to the cave monastery, the complex includes:' +
                '<ul>' +
                '<li>A stone church,</li>' +
                '<li>A chapel,</li>' +
                '<li>Residential buildings,</li>' +
                '<li>Utility structures.</li>' +
                '</ul>' +
                '<br>Saharna Monastery is an active men’s monastery. The monks lead a strict lifestyle, dedicating themselves to prayer and work. Regular religious services and pilgrimage tours are held at the monastery.<br>',
            item12Article3: 'One of the main relics of the monastery is the relics of St. Macarius. Thousands of pilgrims come every year to pray and seek assistance.<br>' +
                '<br>The monastery grounds are peaceful and serene – the cliffs, forest, and sanctuary seem to invite visitors to step away from worldly distractions and delve into self-reflection. At the same time, visiting the monastery will also appeal to tourism enthusiasts – reaching certain locations requires walking and overcoming obstacles (which is to be expected for a cave monastery). The reward, however, is worth it – a picturesque waterfall and breathtaking views that leave a lasting impression.' +
                '<br>A delightful bonus is a beautiful but slightly temperamental white peacock that resides on the monastery grounds.<br>' +
                '<br>Saharna Monastery is a place where you can escape the hustle and bustle of city life and find inner harmony.',

            libraryTitleItem13: 'Old Orhei',
            libraryTitleDescription13: 'A Journey Through Time',
            item13DescriptionText: 'Old Orhei is a living embodiment of Moldova\'s history, a place where every rock holds its secrets, and every stone bears witness to events of past centuries. Situated in the picturesque valley of the Răut River, this natural and cultural complex invites visitors on a journey through time, from the era of the Geto-Dacians to the Middle Ages.',
            item13Article1: 'One of the main attractions of Old Orhei is the rock monastery. This hermitage, with its beautiful church and cells carved into the rock, stands as a symbol of spirituality and religious tradition.<br>' +
                '<br>The path to the archaeological and religious monuments is quite long but not overly challenging. However, what immediately strikes visitors upon entering the valley is the breathtaking view. The valley, surrounded by hills, and the horseshoe-shaped river... whether on a sunny or cloudy day, this panorama leaves an unforgettable impression.' +
                'If you decide to embark on a multi-kilometer excursion through Old Orhei, prepare comfortable shoes, plenty of space on your phone or camera, and a sea of emotions—you are about to immerse yourself in the amazing world of a site included in the UNESCO World Heritage list.',
            item13Article2: 'The first settlements in the Old Orhei area appeared before Christ—the oldest archaeological findings date back to the first millennium BC. However, the city flourished most during the existence of the medieval Moldavian state. Here stood a voivode’s palace, numerous craft workshops, baths, and the homes of townspeople.<br>' +
                '<br>Many legends surround Old Orhei. One of the most famous tells of treasures hidden in these caves by the legendary Moldavian ruler Stephen the Great. Another legend claims that the mythical hero Orpheus was imprisoned here. Of course, these stories lack historical evidence but add an air of mystery and romance to the site.',
            item13Article3: 'The architectural ensemble of Old Orhei is a unique blend of natural and man-made elements. The main attractions include:<br>' +
                '<ul>' +
                '<li><b>The Geto-Dacian Fortress:</b> The oldest structure in the complex, dating back to the 7th–5th centuries BC. The fortress was built by the Dacians, an ancient Thracian tribe that inhabited these lands.</li>' +
                '<li><b>The Rock Monastery:</b> Carved into the cliffs, the monastery was founded in the 15th century. Its cells, church, and underground tunnels still amaze with their scale and craftsmanship.</li>' +
                '<li><b>The Church and Monastery of the Nativity of the Virgin Mary:</b> Founded in the 15th–16th centuries, this monastery is closely tied to the ancient rock hermitage "The Cave." After a period of decline in the 18th–19th centuries, the monastery was revived in the early 20th century. During Soviet rule, it and the hermitage were transformed into an open-air museum, with valuables, including bells, removed. Restoration of the monastery began in the mid-1990s.</li>' +
                '<li><b>Structures typical of Golden Horde cities:</b> Caravanserais, public baths, mosques, mausoleums—discovered by archaeologists. Remains of a bathhouse, equipped with rooms for bathing, relaxation, massage, heating, and ventilation, have been preserved.</li>' +
                '</ul>',
            item13Article4: 'Today, Old Orhei is a popular tourist destination. Various cultural events, festivals, and conferences are held here. In Butuceni village, part of the Old Orhei complex, there is a museum and a stage for the unique festival "Opera in Butuceni." You can also enjoy the Ethno Villa, which immerses you even deeper into the atmosphere of this remarkable place.<br>' +
                '<br>As for history and archaeology, researchers continue to study this fascinating historical site, uncovering new secrets of the past.<br>'+
                '<br><br><br><b>Why Visit Old Orhei?</b> <br>' +
                '<ul>' +
                '<li>Old Orhei is a living museum where you can touch the history of Moldova.</li>' +
                '<li>The picturesque landscapes of the Răut and Cogâlnic river valleys create an unparalleled atmosphere.</li>' +
                '<li>You’ll find peace and solitude here.</li>' +
                '<li>The rock monastery, Geto-Dacian fortress, and other structures amaze with their antiquity and beauty.</li>' +
                '</ul>' +
                'Old Orhei is a must-visit destination to experience the rich history and culture of Moldova.',

            libraryTitleItem14: 'Holy Trinity Monastery in Rudi',
            libraryTitleDescription14: 'History, Spirituality, and the Majesty of Stone',
            item14DescriptionText: 'In the picturesque gorge of the Dniester River, 200 kilometers north of Chișinău, lies the village of Rudi. Here, among the hills and forests, the Holy Trinity Monastery shines like a precious stone – the spiritual heart of this region, founded in the distant year of 1777.',
            item14Article1:
                'This monastery, one of the oldest in Moldova, attracts pilgrims and tourists seeking solitude and spiritual peace. Its architecture, executed in the old Moldovan style, impresses with its simplicity and elegance. The stone that forms the walls retains the warmth of centuries and the energy of many generations of monks.',
            item14Article2:
                'The Holy Trinity Church, the heart of the monastic complex, stands out in particular. Its walls, adorned with unique frescoes and icons, tell the story of faith and devotion. Here, beneath the church’s arches, there is a special atmosphere of grace and tranquility.<br>' +
                '<br>The monastery has seen many trials throughout its existence. There were periods of prosperity and decline when it was closed and repurposed for various uses. During the Soviet period, the site housed an orphanage and a sanatorium. Yet, despite all hardships, the monastery was reborn, like a phoenix from the ashes. Today, just as in the past, people come here to pray, seek solace, and restore their inner balance.',
            item14Article3:
                'The nuns who now live in the monastery lovingly and carefully maintain its grounds. The area is always clean, flowers delight the eyes with their beauty, and the silence and peace invite prayer and reflection.<br>' +
                '<br>Visiting the Holy Trinity Monastery in Rudi is not just an excursion but a true pilgrimage to the roots of faith and spirituality. Here, you can touch history, feel the connection of times, and find hope for a better future.',
            item14Article4:
                'If you are looking for a place to escape the hustle and bustle of the city, enjoy the beauty of nature, and find inner peace, the Holy Trinity Monastery in Rudi is just the place you need.<br>' +
                '<br>If you plan a trip to Rudi and want to avoid crowds of tourists, it is better to visit on a weekday – on weekends and, especially, on religious holidays, the monastery attracts many pilgrims. It’s also important to consider the surrounding landscape: if you want to climb to the spring, walk through the forest, or reach the Turkish Plate, you should choose comfortable clothing and footwear suitable for forest trails with elevation changes.',
            item14Article5: 'Is it worth visiting Rudi? Definitely yes. In this place, everyone will find something for themselves: history enthusiasts can explore ancient artifacts, nature lovers can enjoy scenic landscapes, and pilgrims can find spiritual peace within the walls of the ancient monastery.',

            libraryTitleItem15: 'Struve Geodetic Arc',
            libraryTitleDescription15: 'How a Moldovan Cube Holds the Secret of the Earth',
            item15DescriptionText: 'In northern Moldova, near the village of Rudi, hidden from prying eyes, lies a stone cube. At first glance, it appears unremarkable, yet it stands as a silent witness to a grand scientific project known as the Struve Geodetic Arc.',
            item15Article1:
                'Two hundred years ago, when science was just beginning to unlock the mysteries of our planet, Russian astronomer Friedrich Georg Wilhelm Struve set himself an ambitious goal: to measure a meridian arc to reveal the Earth\'s shape and size.<br>' +
                '<br>For thirty-five years, from 1816 to 1852, “geometers of three nations” – Russians, Norwegians, and Swedes – worked to create a unique geodetic arc stretching 2,820 kilometers from the Arctic expanses to the shores of the Black Sea.',
            item15Article2:
                'Two hundred sixty-five points, marked with stone cubes like the one near Rudi, were embedded in the ground across the territories of ten modern countries. Each of these markers was part of a vast scientific puzzle intended to unlock the Earth\'s secrets.<br>' +
                '<br>The Struve Geodetic Arc became not just an outstanding scientific achievement but also a symbol of international cooperation and an unquenchable thirst for knowledge. Its results laid the foundation for cartography and scientific research for many years.<br>' +
                '<br>In the 21st century, even as satellite technologies have replaced stone cubes, the Struve Arc remains significant. It is still regarded as one of the most important events in the history of global astronomy, geodesy, and geography.',
            item15Article3:
                'In 2005, the UNESCO World Heritage Committee recognized the Struve Geodetic Arc as a cultural heritage site of “outstanding universal value.” It is the only geodetic site and the only purely intellectual monument included in the World Heritage List.<br>' +
                '<br>The stone cube near the village of Rudi is not just a point on a map; it is a symbol of scientific achievement and humanity\'s quest to understand the world. It reminds us that science knows no borders and that the pursuit of truth unites people of different nations and cultures.',

            libraryTitleItem16: 'The "Stone Age" Tourist Complex',
            libraryTitleDescription16: 'The "Stone Age" Tourist Complex at Brănești Winery – A Journey into Prehistoric Times',
            item16DescriptionText: 'Stone walls and floors, mammoth drawings on the walls, tons of rock overhead, wooden furniture, animal skins, and a live fire… This is not the description of a primitive family\'s dwelling, but the unique "Stone Age" tourist complex at Brănești Winery, located in the heart of the Republic of Moldova.',
            item16Article1:
                'The complex blends Moldova\'s winemaking traditions with the mysterious atmosphere of underground caves. Carved into limestone at a depth of over 60 meters, it spans an extensive network of underground galleries stretching 70 km.<br>' +
                '<br>Here, visitors can see dusty wine bottles stored in round niches carved directly into the rock, while cave paintings on the walls transport them back to prehistoric times.',
            item16Article2:
                'The "Stone Age" is not just a winery—it is a unique tourist destination where guests can:<br>' +
                '<ul>' +
                '<li>Taste wines directly in the underground galleries while enjoying traditional Moldovan dishes.</li>' +
                '<li>Explore impressive prehistoric-style decorations, transforming the galleries into a true underground museum.</li>' +
                '<li>Stay in an unusual hotel, where cozy rooms are designed in the style of traditional Moldovan homes.</li>' +
                '<li>Attend festivals and events held inside the complex, blending music, gastronomy, and wine culture.</li>' +
                '</ul>',
            item16Article3:
                'If you are a wine enthusiast, a history lover, or simply a traveler in search of new experiences, the "Stone Age" complex invites you into a world where nature and winemaking traditions harmoniously coexist.<br>' +
                '<br>Come and discover the true taste of Moldova while immersing yourself in the atmosphere of a place unlike any other in the country!',

            libraryTitleItem17: 'Château Vartely',
            item17DescriptionText: 'Is one of Moldova’s most famous wineries, where winemaking traditions blend harmoniously with modern technologies.',
            item17Article1:
                'Located near Orhei, it attracts visitors with high-quality wines, hospitality, and a unique tourist complex. Château Vartely uses grape varieties grown in Moldova’s renowned wine regions – Codru and the southern part of the country. Here, both classic European wines and authentic Moldovan varieties such as Feteasca Albă, Feteasca Neagră, and Rară Neagră are produced, winning the hearts of wine lovers worldwide.<br>' +
                '<br>However, Château Vartely is not just a winery but also a true cultural center. In the wine museum, visitors can see sheets of cork oak bark and long cork tubes – the raw materials that later become the wine corks we are all familiar with.',
            item17Article2:
                'The museum also presents wines from different countries and showcases samples of various soil types used for growing grapes, helping visitors understand the impact of terroir on wine flavor. The winery’s collection includes a vast number of wine varieties and bottles. In the production facilities, visitors can observe tall tanks where the wine fermentation process takes place.',
            item17Article3:
                'One of the highlights of visiting Château Vartely is the light show using video mapping technology. Vivid animated images appear on the wine barrels stored in the cellar. Even if you are not a dedicated wine enthusiast, this visual spectacle will leave you amazed.<br>' +
                '<br>Château Vartely offers its guests not just a look into winemaking but also a full-fledged retreat. Visitors can see the entire production process, taste the wines, and relax in villas designed in the style of the world’s best wine regions.',
            item17Article4:
                'Château Vartely is more than just a winery—it is a cultural center that combines history, winemaking expertise, and Moldovan hospitality. The wines produced here are exported to over 30 countries and serve as Moldova’s hallmark on the international stage.',

            libraryTitleItem18: 'Village Museum',
            libraryTitleDescription18: 'A Journey into the Heart of Rural Moldova',
            item18DescriptionText: 'Imagine a journey through time, into the very heart of a traditional Moldovan village. This is exactly what awaits you at the Village Museum in Chișinău. It is not just a museum, but a living corner of history where you can touch the past and experience how our ancestors lived.',
            item18Article1:
                'As you stroll through the museum, you will find yourself in a 17th-century Moldovan village. Here is a traditional Moldovan house with carved doors, a stone foundation, and sturdy wooden stairs. Nearby, you will see household items: clay pottery, weaving looms, old spinning wheels, and agricultural tools. And of course, a well with a traditional water crane providing fresh drinking water.<br>' +
                '<br>The atmosphere is enhanced by the presence of a heavy copper bell, gently swaying in the wind.<br>' +
                '<br>Every exhibit represents a piece of the Moldovan people\'s soul, their hard work, and their traditions.',
            item18Article2:
                'Following a cobblestone path, you will arrive at the museum’s most treasured jewel – the Wooden Church of the Assumption of the Virgin Mary, brought from the village of Hirișeni. Built in 1642, this church is one of Moldova’s oldest architectural monuments. Standing at 27 meters high, it is one of the tallest wooden churches in Moldova, impressing visitors with its beauty and elegance.<br>' +
                '<br>The Village Museum is not just a place for excursions—it is a place where you can rest your soul and enjoy a peaceful atmosphere.',
            item18Article3:
                '<h4>Why visit the Village Museum?</h4><br>' +
                '<ul>' +
                '<li>To immerse yourself in the atmosphere of an authentic Moldovan village.</li>' +
                '<li>To see unique exhibits that tell the story of Moldova’s history and culture.</li>' +
                '<li>To admire a centuries-old wooden church.</li>' +
                '<li>To take beautiful photos as a keepsake.</li>' +
                '<li>Simply to relax and enjoy the tranquility.</li>' +
                '</ul>' +
                'The Village Museum is located near the City Gates, at the entrance to Moldova’s capital. It is the perfect place for those who want to learn more about Moldova’s past and its people.',

            notFound: 'NOT FOUND :(',
        },
        ru: {
            menuItemHome: 'Главная',
            menuItemLibrary: 'Библиотека',
            menuItemForum: 'Форум',
            aboutUs: 'О Нас',
            aboutUsText1:
                '"Единство в Культурном Разнообразии"- проект, предназначенный для сохранения мест культурного наследия Молдовы на пересечении культуры трёх стран-соседей Украина-Молдова-Румыния. Наша задача - создать электронную библиотеку мест культурного наследия для их популяризации во избежание разрушения и забвения некоторых.',
            aboutUsText2:
                'Кроме этого, в рамках проекта проводятся сезонные тематические лагеря, заключением которых является восстановление по канонам празднования сезонных праздников с воспроизведением традиций и обычаев на пересечении трёх культур.',
            sponsors: 'Идейные вдохновители',
            sponsorsDescription: 'Данный проект существует благодаря нашим идеям, которые мы смогли воплотить при поддержке наших спонсоров.',
            footerPartners: 'Партнеры',
            footerSupport: 'При поддержке фондов',
            forumAboutUs: 'О Нас',
            forumAboutUsText1: 'Проект "Единство в Культурном Разнообразии" нацелен на сохранение мест культурного наследия различных этнических групп на территории Молдовы через их популяризацию в сети интернет во избежание разрушения и забвения.',
            forumAboutUsText2: 'Задача нашей команды - выезжать на объекты культурного наследия, делать фото, видео, рассказывать о них Вам. Мы уверенны, что данная электронная библиотека станет для Вас прекрасным путеводителем по Молдове и ее культурному разнообразию.',
            forumFormTitle: 'Форум',
            forumFormText1: 'Наша страна весьма богата местами культурного наследия, а наша цель - рассказать об этих местах, повысить интерес во избежание их разрушения. Этот форум создан для того, чтоб каждый желающий смог предложить объект (или проголосовать за понравившийся) культурного наследия который нам стоит посетить и описать.',
            forumFormText2: 'Здесь Вы можете отправить письмо нам на почту и другие соц.сети или присоединиться к форуму в Telegram.',
            formLabelName: 'Введите имя и фамилию',
            formLabelEmail: 'Введите e-mail',
            formLabelPhone: 'Введите номер телефона',
            formLabelMessage: 'Введите ваше сообщение',
            formTelegramButton: 'Форум в Телеграм',
            formSendButton: 'Отправить',
            categoryTitleItem1: 'Все',
            categoryTitleItem2: 'Святыни',
            categoryTitleItem3: 'Фестивали и ярмарки',
            categoryTitleItem4: 'Музеи и театры',
            categoryTitleItem5: 'Строения',
            categoryTitleItem6: 'Крепости',

            libraryTitleItem1: '"Ivana Kupala Camp"',
            libraryTitleDescription: 'Слияние культуры народа на границе трех этнических влияний',
            item1DescriptionText: 'В начале июля на берегу живописного озера в межграничном регионе Украина-Молдова-Румыния, в городе Дрокия прошло трехдневное мероприятие "Ivana Kupala Camp", посвященное традиционному празднованию Ивана Купала. Этот праздник имеет глубокие корни в культурах всех трех стран и является символом единства и богатства их культурных традиций.',
            itemArticle1: '<h4>День 1 и 2: Знакомство с культурой и традициями</h4> ' +
                '<br>Первый и второй дни мероприятия были посвящены знакомству участников с культурными особенностями и традициями празднования Ивана Купала в Украине, Молдове и Румынии. <br>' +
                '<br><b>Украина:</b> В Украине Иван Купала (Купальская ночь) отмечается как день летнего солнцестояния, символизируя очищение и возрождение. Традиционные элементы включают прыжки через костер, плетение венков из полевых цветов и запуск их по реке для гадания на суженого.<br>' +
                '<br><b>Молдова:</b> В Молдове этот праздник известен как "Синзиенеле". Он также связан с летним солнцестоянием и включает в себя ритуалы очищения огнем и водой. Особенностью молдавского празднования является танец "Hora", который исполняется вокруг костра.<br>' +
                '<br><b>Румыния:</b> В Румынии Синзиенеле – это праздник мистических и магических ритуалов. Венки из цветов, сплетенные на Синзиенеле, обладают магической силой и защищают от злых духов. Вечером разжигаются костры, вокруг которых ведутся хороводы.',
            itemArticle2: '<b>Общие традиции:</b><br>' +
                '<br><ul>' +
                '<li>Плетение венков из полевых цветов</li>' +
                '<li>Разжигание костров и прыжки через них</li>' +
                '<li>Танцы и пение вокруг костра</li>' +
                '</ul><br>' +
                '<b>Особенные традиции каждой страны:</b>' +
                '<br><ul>' +
                '<li>Украина: Запуск венков по воде для гадания</li>' +
                '<li>Молдова: Танец "Hora"</li>' +
                '<li>Румыния: Магические ритуалы с венками</li>' +
                '</ul><br>' +
                'Во время первых двух дней участники могли принять участие в мастер-классах по плетению венков из полевых цветов и созданию оберегов, расписывая кулоны древними рунами. Это позволило каждому участнику погрузиться в традиции и культуру своих соседей.',
            itemArticle3: '<h4>День 3: Празднование Ивана Купала</h4>' +
                '<br>' +
                '<p>Третий день был кульминацией лагеря, когда на берегу озера развернулось само празднование Ивана Купала. Участники готовили площадку для праздника, плетя венки, расписывая лица специальными красками (для детей) и создавая три фотозоны для запечатления памятных моментов.</p>' +
                '<p>На мероприятие были приглашены народные ансамбли, которые исполняли песни на украинском, молдавском и румынском языках, а также устраивали танцевальные представления с элементами народных танцев.</p>',
            itemArticle4: '<p>Была организована выставка рукоделия от народных мастеров, где гости могли увидеть и приобрести уникальные изделия, созданные с любовью и мастерством.</p>' +
                '<p>Для обеспечения безопасности участников были назначены наблюдатели, а городская власть выделила два экипажа правоохранительных органов. Безопасность на воде обеспечивала местная команда спасателей.</p>' +
                '<p>Многие участники поделились своими впечатлениями о "Ivana Kupala Camp". Они отметили, что мероприятие было чрезвычайно увлекательным и познавательным. <i>"Вместе с @uicd.md, в течение 3 дней, мы погружались в глубокое познание традиций, мифов и обычаев Ивана Купала,"</i> – делится Теодора. Александр добавил: <i>"Хочу выразить огромную благодарность организаторам за незабываемый праздник Ивана Купала в городе Дрокия. Благодаря труду и заботе со стороны организаторов, все жители города смогли насладиться великолепной атмосферой и традициями. Этот день останется в наших сердцах как яркое и радостное воспоминание."</i> Участники также отметили, что мероприятие стало прекрасной возможностью для новых знакомств и укрепления культурных связей между странами.</p>',
            itemArticle5: '<p>"Ivana Kupala Camp" стал не просто праздником, а настоящим культурным обменом между тремя странами. Участники смогли погрузиться в богатство традиций, завести новые знакомства и укрепить культурные связи. Этот лагерь показал, как важно сохранять и развивать культурное наследие, объединяя людей через традиции и обычаи.</p>' +
                '<p>Мы благодарим всех участников и гостей за создание незабываемой атмосферы и ждем вас на следующих мероприятиях!</p>',
            itemArticle6: 'Thanks to the support of the European Heritage Hub pilot Project co-funded by the European Union and supported by the ALIPH Foundation.',

            libraryTitleItem2: 'Сорокская крепость',
            libraryTitleDescription2: 'Жемчужина молдавской истории',
            item2DescriptionText: 'Сорокская крепость - один из самых известных исторических памятников Молдовы. Цитадель, расположенная на правом берегу Днестра, была возведена по приказу великого молдавского господаря Штефана Великого более пятисот лет назад.',
            item2Article1:
                'Построенная в последней четверти XV века (точная дата завершения строительства неизвестна), она служила надежным оплотом в защите северных границ Молдавского княжества от частых набегов крымских татар.<br>' +
                '<br>Форма цитадели необычна для молдавских крепостей - круглая крепость, диаметром около 37,5 метров, с пятью башнями. Сами башни так же удивляют посетителей — не сразу отдаешь себе отчет, что 4 из пяти башен круглые, а одна (надвратная) — прямоугольной формы.<br>' +
                '<br>Такая конструкция была новаторской для своего времени и обеспечивала крепости отличную обороноспособность, делая ее практически неприступной, о чем свидетельствуют и легенды, связанные с Сорокской крепостью. ',
            item2Article2: '<h4>Реставрация и возрождение:</h4>' +
                '<br>После столетий, пережитых крепостью, в 2015 году были завершены масштабные реставрационные работы. Благодаря им, памятник истории и архитектуры предстал перед посетителями во всем своем былом великолепии. Реставраторы бережно восстановили стены, башни, а также внутренние помещения. Однако процесс реставрации на этом не остановился. С апреля 2021 года крепость была практически закрыта для посещений и полностью вернула свои позиции туристического объекта лишь в апреле 2024 года. Чем сразу воспользовались туристы, наводнившие цитадель.',
            item2Article3: '<h4>Легендарный меч Штефана чел Маре</h4>' +
                '<br>' +
                '<p>В крепости был создан музей, посвященный истории Молдовы и, в частности, эпохе правления Штефана Великого. Одной из главных экспозиций музея является копия знаменитого меча молдавского господаря. Этот экспонат позволяет посетителям окунуться в атмосферу средневековья и представить себе доблестного воина, защищавшего свою землю.</p>' +
                '<p>Оригинал меча в настоящее время хранится в Турции, а соседняя Румыния ведет переговоры о возвращении реликвии на ее историческую родину.</p>',
            item2Article4: 'Сегодня Сорокская крепость – это не просто исторический памятник, но и популярное туристическое место. Ее посещение – это уникальная возможность прикоснуться к богатому историческому наследию Молдовы, узнать больше о жизни наших предков и насладиться прекрасными видами Молдовы.',

            libraryTitleItem3: 'Монастырь Косэуць',
            item3DescriptionText: 'Монастырь Косэуць – это удивительное место, где история и современность сплетаются в гармоничное целое. Расположенный в живописной долине реки Днестр, примерно в 5 километрах от села Косэуць, этот монастырь привлекает паломников и туристов со всей страны.',
            item3Article1: '<h4>История монастыря</h4>' +
                '<p>Первые упоминания о монастыре датируются 1729 годом. Именно тогда на месте, которое впоследствии стало известно как "ла Пристол", появился небольшой деревянный монастырь, посвященный Рождеству Богородицы. Его основателями стали монахи Павел и Гавриил. Монастырь просуществовал около 160 лет, но в 1833 году был закрыт.</p>' +
                '<p>В XX веке на месте старого монастыря практически ничего не осталось из-за землетрясений и оползней. Сохранилась лишь небольшая часовня, где местные жители и паломники продолжали молиться.</p>',
            item3Article2: '<h4>Возрождение духовной жизни:</h4>' +
                '<br>' +
                '<p>В конце XX века, в 1994 году, по инициативе местного священника Иеронима Палия началось возрождение монастыря. На месте старого монастыря был построен новый храм, посвященный Успению Божьей Матери. Проект храма был разработан по благословению митрополита Молдовы и Буковины Даниила.</p>' +
                '<p>Строительство монастыря велось в сложных условиях, но благодаря самоотверженности отца Иеронима и поддержке прихожан, уже в 1999 году храм был освящен. Постепенно вокруг храма вырос монастырский комплекс, включающий в себя кельи, трапезную и другие хозяйственные постройки.</p>',
            item3Article3: '<h4>Монастырь сегодня</h4>' +
                '<br>' +
                '<p>Сегодня монастырь Косэуць – это не только место для молитвы, но и популярный туристический объект. Паломники и туристы со всей страны приезжают сюда, чтобы прикоснуться к истокам православной веры, насладиться тишиной и красотой природы.</p>' +
                '<p>В монастыре можно посетить молитвенные службы (богослужения проводятся на румынском и русском языках) и стать участником паломнических туров (в том числе и по другим святым местам Молдовы).</p>' +
                '<p>Также, территория монастыря благоустроена и идеально подходит для спокойного отдыха.</p>',
            item3Article4:
                '<p>Еще одной отличительной особенностью монастыря является источник минеральной воды в нескольких минутах ходьбы от монастыря, рядом с площадками для пикника, находится источник с целебной водой.</p>',
            item3Article5:
                'Монастырь Косэуць – это живой пример того, как вера и труд могут изменить мир. Это место, где каждый может найти духовное утешение и почувствовать связь с вечностью. Монастырь открыт для посещения ежедневно.',

            libraryTitleItem4: 'Цыганская гора',
            item4DescriptionText: 'Сороки знамениты не только своей древней крепостью, но и уникальным районом, известным как Цыганская гора. Расположенный на возвышенности, этот квартал стал настоящей визитной карточкой города благодаря своим необычным домам.',
            item4Article1:
                'История появления ромов в Сороках окутана легендами. Согласно одной из них, земли на холме были дарованы цыганам молдавским господарем Штефаном III в знак благодарности за помощь в вооружении армии. Так или иначе, ромы в Сороках живут очень давно. Соблюдая свои традиции и оберегая культуру.<br>' +
                '<br>Архитектура домов на Цыганской горе поражает воображение. Это настоящий архитектурный калейдоскоп, где соседствуют элементы классицизма, барокко, модерна и даже восточных стилей. Каждый дом – это произведение искусства, украшенное лепниной, колоннами, башенками и различными декоративными элементами. Здесь можно встретить фигуры мифических существ, религиозные символы, а также копии известных архитектурных сооружений, таких как шпиль Адмиралтейства или купол мечети. ' +
                '<br>Однако многие дома на Цыганской горке остались недостроенными. Тем не менее, даже незавершенные, эти здания придают городу особый шарм и привлекают множество туристов.',
            item4Article2:
                'Цыганская гора – это место, где прошлое и настоящее сплетаются воедино, создавая неповторимую атмосферу. Это место, которое обязательно стоит посетить, чтобы увидеть своими глазами, как выглядит настоящее чудо архитектуры. Однако, тем, кто решит совершить экскурсию в это место, не стоит забывать о традициях, обычаях и культуре народа ромов, которые могут существенно отличаться от того, что туристы видели в других уголках Молдовы. ',

            libraryTitleItem5: 'Бекиров Яр',
            item5DescriptionText: 'Бекиров Яр или Бекирова Пещера, является уникальным историческим и природным памятником. Высеченная в скале, так называемая келья монаха считается одним из самых старых религиозных памятников в регионе и датируется VI-VIII веками.',
            item5Article1:
                'Монастырский пещерный комплекс расположен на высоте 12-14 метров на отвесной известняковой скале. Пещера представляет собой небольшое помещение с алтарем, ориентированным на север. Внутри пещеры есть высеченные лежанка, стол, ниши для иконы и лампады, и даже есть погреб.<br>' +
                '<br>Местные жители часто ассоциируют название пещеры с именем последнего молдавского гайдука Бекира, укрывавшегося от гонений. По другой легенде, в пещере жил монах, который каждое воскресенье выходил к людям и читал проповеди. Однако, эти легенды остаются всего лишь легендами – судя по исследованиям, проведенным уроженцем Сорокского района, Васисилем Трофэилэ, Бекир был турецким купцом, который торговал когда-то на э той территории и старался расширить свою сферу влияния.' +
                '<br>Видимо, кем был исторический Бекир, доподлинно нам не узнать. Но памятник, носящий его имя, не теряет привлекательности для туристов, хоть попасть внутрь пещеры очень сложно.',
            item5Article2:
                'Пещера Бекир представляет собой не только религиозный, но и геологический памятник. Она расположена в живописном каньоне, образованном в результате эрозии известняковых пород. Подняться к ней можно, пройдя по лесу, недалеко от подъема к Свече Благодарности.' +
                '<br>Тропинка, по которой предстоит пройти туристам живописна – слева течет речка, в которой виднеются огромные валуны, неизвестно когда упавшие с вершины холма, вокруг шумят деревья, поют птицы и кажется, что попал в самый настоящий волшебный лес.',
            item5Article3:
                'Подъем к Бекировой пещере начинается возле двух монументальных соседей – огромного тополя со стволом в несколько обхватов и громадного валуна, испещренного подписями туристов.' +
                '<br>Именно от этих запоминающихся «друзей», по довольно крутой тропинке надо подняться на несколько десятков метром вверх, к самой пещере. Так что, если вы решите своими глазами увидеть Бекиров Яр и подняться к пещере, лучше позаботиться о нескользящей обуви, чтобы ничто не отвлекало от прогулки по лесу и ущелью.',
            item5Article4:
                'Несмотря на свою историческую и культурную ценность, пещера долгое время не находилась под государственной охраной. Однако благодаря усилиям общественности и местных властей, пещера Бекир была официально включена в список охраняемых памятников Молдовы.' +
                '<br>В настоящее время ведутся работы по сохранению и реставрации этого уникального объекта, а ученые продолжают изучать пещеру, чтобы раскрыть все тайны ее прошлого.' +
                '<br>Бекирова пещера – это уникальное место, где история сплетается с природой, предлагая туристам незабываемые впечатления и возможность прикоснуться к древности.',

            libraryTitleItem6: 'Парк Цауль',
            libraryTitleDescription6: 'Затерянный рай Молдовы',
            item6DescriptionText: 'На севере Молдовы, в селе Цауль Дондюшанского района, скрывается настоящее сокровище – парк Цауль. Этот уникальный уголок природы, раскинувшийся на 46,2 гектарах, является одним из крупнейших и красивейших парков в Молдове. Созданный в начале XX века по проекту известного ландшафтного архитектора Иполита Владиславского-Падалко, парк представляет собой гармоничное сочетание природной красоты и человеческого творчества.',
            item6Article1: 'Инициатором создания этого зеленого рая стал Андрей Поммер, известный банкир и меценат. Желая создать уединенное место для отдыха и созерцания, он приобрел эти земли и вложил в их благоустройство душу и средства. Результатом его усилий стал великолепный парк, который и сегодня восхищает своей красотой и разнообразием.',
            item6Article2: 'Парк Цауль – это настоящий ботанический сад под открытым небом. Здесь собраны сотни видов растений со всего мира: от могучих дубов до экзотических лиан. Каждая аллея, каждый уголок парка – это произведение ландшафтного искусства. Особую атмосферу создают искусственно созданные водоемы, где плавают грациозные лебеди.',
            item6Article3: 'Сердцем парка является усадьба Поммера – изысканный особняк, построенный в начале XX века. Это великолепное здание, выполненное в классическом стиле, поражает своей элегантностью и масштабностью. К сожалению, годы и отсутствие должного ухода привели к тому, что усадьба сегодня находится в плачевном состоянии. Однако, даже в руинах она сохраняет свое великолепие и напоминает о былом богатстве и роскоши.<br>' +
                '<br>История усадьбы Поммера и парка Цауль полна интересных событий и легенд. Говорят, что в этих стенах бывали известные личности того времени, а в парке гуляли влюбленные пары. Сегодня парк Цауль – это не просто исторический памятник, это место, где можно окунуться в атмосферу прошлого и насладиться красотой природы.',
            item6Article4: 'Тех, кто посетит Цаульский парк и Усадьбу Поммера ждет встреча с уникальной природой и историческим наследием. Также, любителей природы порадует отдых в этом уникальном месте - Цаульский парк это идеальное место для прогулок, пикников и отдыха от городской суеты.<br>' +
                '<br>Парк Цауль – это одна из жемчужин Молдовы, которая нуждается в нашей заботе. Каждый из нас может внести свой вклад в сохранение этого уникального уголка природы. Давайте вместе позаботимся о том, чтобы парк Цауль и усадьба Поммера сохранились для будущих поколений.',

            libraryTitleItem7: 'Деревянная церковь',
            libraryTitleDescription7: 'Святого Николая в Брэйкэу',
            item7DescriptionText: 'Затерянная в тихом уголке села Брэйкэу Дондюшанского района, деревянная церковь Святого Николая – это не просто религиозное сооружение, а подлинное сокровище молдавской архитектуры. Построенная в 1829 году, она является памятником национального значения и представляет собой уникальный образец деревянного зодчества.',
            item7Article1: 'Этот старинный храм гармонично сочетает народные традиции и византийские мотивы и является настоящей гордостью местных жителей.<br>' +
                '<br>Церковь внесена в Реестр памятников, охраняемых государством. К сожалению, несмотря на свою историческую ценность, памятник архитектуры находится в плачевном состоянии. Деревянные конструкции сильно повреждены временем и требуют срочного ремонта. Однако, местные жители не теряют надежды и продолжают заботиться о своем духовном наследии.',
            item7Article2: 'Посещение деревянной церкви в Брэйкэу не оставит любителей истории равнодушными. <br>' +
                '<br>Если вы хотите погрузиться в атмосферу XIX века, прикоснуться к истории,  и прочувствовать дух того времени, непременно посетите село Брэйкэу.' +
                '<br>Посетив церковь Святого Николая, вы не только познакомитесь с уникальным памятником архитектуры, но и внесете свой вклад в его сохранение. Ведь каждый посетитель – это шаг к спасению этого бесценного сокровища Молдовы.',

            libraryTitleItem8: 'Бендерская крепость',
            libraryTitleDescription8: 'Страж на Днестре',
            item8DescriptionText: 'Бендерская крепость – одна из самых значимых исторических достопримечательностей Молдовы, олицетворяющая собой богатую и бурную историю этого региона. Расположенная на правом берегу реки Днестр, на она пережила множество осад, сражений и перестроек.',
            item8Article1:
                'Информация о первых укреплениях на месте нынешней крепости разнится. По самой распространенной, пусть и не доказанной, версии, первая цитадель на этом месте появилась еще в XIII веке. Генуэзцы, активные торговцы того времени, возвели здесь небольшое укрепление для контроля над торговыми путями по Днестру. А позже  крепость перестраивали, укрепляли и достраивали молдавские господари и турки.',
            item8Article2:
                'Однако настоящая история Бендерской крепости начинается с XVI века, когда эти земли оказались под властью Османской империи. Hаиболее научно обоснованной версии, в 1538 году турки начали строительство мощной крепости, которая должна была стать одним из опорных пунктов их владений в регионе. Проект крепости был разработан знаменитым турецким архитектором Синаном. Цитадель была построена с учетом новейших достижений военной инженерии того времени и представляла собой сложную систему укреплений, включающую в себя башни, стены, рвы и подземные ходы.' +
                '<br><br>За свою долгую историю Бендерская крепость неоднократно переходила из рук в руки. Она пережила множество осад и сражений, служила убежищем для повстанцев и местом ссылки для знаменитых исторических личностей.',
            item8Article3:
                'Бендерская крепость – это выдающийся памятник фортификационного искусства XVI века. Ее архитектура сочетает в себе элементы восточной и западноевропейской фортификации. Мощные стены, башни, ворота – все это создает впечатление неприступности.' +
                '<br><br>Бендерскую крепость часто сравнивают с Сорокской. Отчасти, это сравнение верно, однако, будучи построены в схожий исторический период и имея схожие функции, цитадели обладают рядом отличий. Сорокская крепость, более компактная и расположенная на высоком холме, была ориентирована в первую очередь на оборону от набегов кочевников. Бендерская же крепость, более крупная и сложная по своей структуре, была предназначена для контроля над важным торговым путем и отражения более масштабных военных действий.',
            item8Article4:
                '<b>Особенности Бендерской крепости:</b>' +
                '<br><ul>' +
                '<li><b>Бастионы</b>: Мощные бастионы, выступающие за линию стен, обеспечивали фланговый огонь по противнику.</li>' +
                '<li><b>Рвы</b>: Глубокие рвы затрудняли нападающим подступ к крепости.</li>' +
                '<li><b>Подземные ходы</b>: Систему подземных ходов использовали для тайных сообщений, хранения припасов и эвакуации.</li>' +
                '<li><b>Ворота</b>: Мощные ворота, защищенные башнями, являлись единственными входами в крепость.</li>' +
                '<li><b>Артиллерийские батареи</b>: На бастионах были размещены многочисленные пушки, обеспечивавшие мощный огонь по противнику.</li>' +
                '</ul><br>',
            item8Article5:
                'С Бендерской крепостью связано множество легенд и преданий. Одна из самых известных рассказывает о подземных ходах, которые якобы соединяют крепость с другими городами и даже с отдаленными монастырями. Другая легенда повествует о спрятанных в стенах крепости сокровищах.',
            item8Article6:
                'Особое место в истории крепости занимает барон Мюнхгаузен. Согласно преданиям, именно в Бендерах он совершил один из своих самых невероятных подвигов – полет на ядре. Хотя эти рассказы являются вымыслом, барон действительно участвовал в Русско-турецкой войне 1735-1739 годов и, возможно, посещал Бендерскую крепость. В память об этом событии на территории крепости установлен памятник в виде пушечного ядра.' +
                '<br><br>Сегодня Бендерская крепость – это популярный туристический объект. Ежегодно ее посещают тысячи туристов, желающих прикоснуться к истории и окунуться в атмосферу средневековья. На территории крепости проводятся различные культурные мероприятия, фестивали и исторические реконструкции.',
            item8Article7:
                '<b>Чем же уникальна Бендерская крепость?</b>' +
                '<br><ul>' +
                '<li>Помимо того, что это живое свидетельство богатого прошлого региона, она сочетает восточные и западноевропейские архитектурные традиции;</li>' +
                '<li>В музее, расположенном на территории крепости, можно увидеть экспонаты, рассказывающие об истории крепости и региона. Некоторые из них, к примеру, пыточные орудия и описания их действия, и сегодня вызывают непередаваемый ужас.</li>' +
                '<li>Если вы решите прогуляться по старинным стенам, будьте готовы к тому, что атмосфера средневековья и тайны крепости, окутают вас с первых же шагов.</li>' +
                '</ul>' +
                '<br>Бендерская крепость – это настоящий Страж на Днестре, который еще не скоро откроет потомкам своих строителей все их секреты и тайны.',

            libraryTitleItem9: 'Музей Бутылка',
            libraryTitleDescription9: 'а вы знали, что такое есть в Молдове?',
            item9DescriptionText: 'Музей Бутылки – это не просто собрание стеклянных сосудов, это настоящее произведение искусства, созданное руками человека. Этот музей представляет собой уникальную коллекцию бутылок со всего мира, которую собирали долгие годы. Музей находится в селе Терновка, недалеко от города Бендеры.',
            item9Article1: 'Идея создания музея родилась у коллекционера, который всю свою жизнь собирал различные бутылки. С годами коллекция настолько разрослась, что потребовалось отдельное помещение для ее экспозиции. Так, в 2000-х годах появилось необычное здание в форме гигантской бутылки.<br>' +
                '<br>Здание музея впечатляет своими размерами. Его высота составляет 28 метров и построено из камня. Внутри, вас встретит сочетание из камня и дерева, что навевает ассоциации с подвалом древней крепости. Сама коллекция размещена внутри здания, на нескольких  этажах. Каждый этаж посвящен определенной теме: здесь можно увидеть бутылки из разных эпох, стран, изготовленные из различных материалов.',
            item9Article2: 'Экспозиция музея поражает своим разнообразием. Здесь представлены бутылки самых причудливых форм и размеров: от миниатюрных флакончиков до огромных сосудов. Есть бутылки, украшенные росписью, гравировкой, инкрустацией. Особое место в коллекции занимают бутылки, связанные с историческими событиями и знаменитыми людьми.<br>' +
                '<br>Помимо бутылок, в музее представлены различные предметы, связанные с историей производства стекла и алкогольных напитков. Здесь можно увидеть старинные инструменты для изготовления стекла, этикетки от различных напитков, рекламные плакаты и, собственно, конечно, рюмки, фужера и стаканы.',
            item9Article3: 'Пожалуй, самое интересное, что бутылки, представленные в экспозиции, не пустые. Выставленная на специальных подставках, и стендах, экспозиция представляет настоящую историческую сокровищницу.',
            item9Article4: 'Музей Бутылки – это удивительное место, которое никого не оставит равнодушным. Помимо того, что такой коллекции бутылок нет нигде в мире, само здание музея является произведением искусства, а каждая бутылка имеет свою историю. Также, на территории музея есть уютная зона отдыха, где можно перекусить и насладиться прекрасными видами.<br>' +
                '<br>В этом удивительном музее можно не только полюбоваться уникальной коллекцией, но и узнать много интересного об истории стекла и алкогольных напитков.',

            libraryTitleItem10: 'Каларашовский монастырь',
            libraryTitleDescription10: 'Свято-Успенский монастырь: история, природа и духовность',
            item10DescriptionText: 'Расположенный в живописной долине реки Днестр, монастырь привлекает паломников и туристов со всего мира своей древней историей, архитектурой и неповторимой атмосферой.Монастырь расположен в 220 км от Кишинева, дорога к нему проходит через живописные места, что делает путешествие еще более приятным.',
            item10Article1:'Точная дата основания монастыря неизвестна. Согласно легенде, в пещере напротив нынешнего монастыря жил монах-схимник, который и основал монашеский скит. Предполагается, что в конце XVI — начале XVII века здесь была построена первая деревянная церковь.',
            item10Article2: 'В 1780 году на территории монастыря были построены каменная церковь и колокольня, на возведение которых ушел всего один год. Местному боярину, Марко-Доничу, на чьи деньги и возвели храм с колокольней, был пожалован пожизненный титул администратора и настоятеля монастыря. <br>' +
                '<br>Монастырь переживал периоды расцвета и упадка, связанные с историческими событиями в Молдавии. В советское время он был закрыт, иконы были изъяты, а в помещениях монастыря размещались различные учреждения. В 1991 году монастырь был вновь открыт и с тех пор активно восстанавливается.',
            item10Article3: 'Каларашовский монастырь расположен в живописном уголке природы, среди скал и лесов. <br>' +
                '<br>На территории монастыря расположены:' +
                '<ul>' +
                '<li>Свято-Успенская церковь, построенная в XVIII веке.</li>' +
                '<li>Церковь Святого Митрофана: Более поздняя постройка, отличающаяся изящными формами и яркой росписью.</li>' +
                '<li>Монашеские кельи.</li>' +
                '<li>Скальная келья или пещера отшельника, в которой, по преданию, жил монах, положивший начало обители. Также, согласно легендам, на протяжении 500 лет, именно сюда поочередно удалялись от всего мирского монахи.</li>' +
                '<li>Трапезная.</li>' +
                '<li>Архиерейский дом.</li>' +
                '<li>Озеро.</li>' +
                '<li>Монастырская библиотека.</li>' +
                '<li>Колокольня с колоколами, созывающими верующих на службу.</li>' +
                '</ul>' +
                '<br>Монастырь окружен скалами, образующими живописные ущелья. С вершин скал открываются потрясающие виды на долину Днестра. В окрестностях монастыря находятся многочисленные родники с чистейшей водой, которые, в свою очередь, также окружены легендами.',
            item10Article4: 'Легенда гласит, что три родника неподалеку от монастыря появились там, где ангел со свечами указывал монахам путь. Сегодня к этим священным источникам ведет ухоженная тропа через заповедный лес "Каларашовка". Путешествие по этой тропе – это не только возможность утолить жажду водой, которая считается целебной, но и насладиться живописными пейзажами: густым лесом, высоким холмом и глубоким ущельем.<br>' +
                '<br>Сегодня Каларашовский монастырь является одним из важнейших духовных центров Молдовы. Здесь проводятся богослужения, паломнические поездки, а также различные культурные мероприятия. Монахи ведут активную социальную деятельность, помогая нуждающимся.<br>'+
                '<br>Планируя свой досуг, обязательно включите в свой маршрут посещение Каларашовского монастыря.',

            libraryTitleItem11: 'Орфей и Штефан Великий',
            libraryTitleDescription11: 'Легенды монастыря Ципова',
            item11DescriptionText: 'Расположенный в отвесных скалах, монастырь Ципова предлагает посетителям захватывающий вид на долину Днестра. Комплекс является одним из самых впечатляющих скальных монастырей не только в Молдове, но и во всей Европе, а его история начинается задолго до образования Молдавского княжества. Согласно легендам, первые кельи были высечены в скалах еще в X-XII веках, чтобы защитить монахов от набегов кочевников, приходивших со стороны  украинских степей.',
            item11Article1:'Мастера, создавшие это чудо, использовали природный рельеф, что позволило  превратить монастырь в неприступную цитадель. Даже в наши дни подобраться к кельям не так-то просто - посетителей ждет непростой спуск по вырубленным в камнях ступеням разной высоты. Те же, кто не остановится на полпути и решит не только полюбоваться видами на Днестр, могут попробовать пройти сквозь четырехметровую щель в скале, служившую монахам дополнительной защитой. <br>' +
                '<br>Многоуровневая структура монастыря охватывает обширную территорию. И по сей день в Ципова можно обнаружить множество келий.',
            item11Article2: 'Святыня расположена в скалах высотой до 100 м, и включает три монастырских комплекса:  <br>' +
                '<ul>' +
                '<li>Первый комплекс состоит из нескольких келий и церкви с посвящением Воздвижению Святого Креста и датируется XI-XII веками. </li>' +
                '<li>Церковь Святого Николая была центром второго монастырского комплекса, основанного в XIV веке.</li>' +
                '<li>Больше всего келий находится вокруг церкви Успения Пресвятой Богородицы, которая датируется XVI-XVIII веками.</li>' +
                '</ul>' +
                '<br>В начале XIX века этот комплекс продолжал функционировать как скит, а в 1842 году был ликвидирован. В 1828 году на холме над скальными комплексами была возведена церковь скита, которая вновь стала монастырем в 1919 году. До этого времени скит находился под управлением монастыря Сахарна.<br>' +
                '<br>В советский период все монахи были разогнаны, а здания на холме перешли под управление колхоза. Церковь была преобразована в склад для табака. Грабежи были частыми, а кельи в скале были разрушены. В 1988 году кельи были восстановлены и превращены в музей.',
            item11Article3: 'Монастырь Ципова поражает своей сложностью. Помещения соединены между собой лестницами и внутренними галереями и создают настоящий лабиринт. <br>' +
                '<br>С монастырем связано множество легенд. По одной из них, именно здесь молдавский господарь Штефан Великий венчался с Марией Войкицей. Еще одна легенда гласит,  что именно тут погиб и захоронен в одной из ниш монастыря древнегреческий поэт Орфей. ',
            item11Article4: 'Окрестности монастыря входят в состав природного заповедника Ципова, и это настоящий рай для любителей природы. Здешний пейзаж поражает своей красотой: спокойные воды Днестра, отвесные скалы с сотнями пещер и гротов, водопады и каскады.<br>' +
                '<br>Если вы хотите окунуться в атмосферу прошлого, прикоснуться к истокам христианства в Молдове и насладиться живописными пейзажами, то монастырь Ципова – это то место, которое обязательно стоит посетить. Однако, как и в случае с некоторыми другими достопримечательностями Молдовы, к поездке стоит и подготовиться - подобрать одежду и обувь, подходящую для похода по гористой местности.<br>'+
                '<br>В Цыпова история переплетается с легендами, а природа поражает своей красотой. Приезжайте и убедитесь в этом сами! Вдруг вам откроется тайна Орфея?',

            libraryTitleItem12: 'Монастырь Сахарна',
            libraryTitleDescription12: 'Оплот духовности в сердце Молдовы',
            item12DescriptionText: 'Монастырь Сахарна – это не просто религиозное сооружение, а уникальный историко-культурный комплекс, расположенный в живописном ущелье реки Днестр, в селе Сахарна. Этот монастырь, сочетающий в себе древние пещерные церкви и современные строения, является одним из самых значимых паломнических центров страны.',
            item12Article1:'Точная дата основания монастыря неизвестна, однако первые упоминания о нем встречаются еще в XV веке. Считается, что первые монахи укрывались в скальном ските от вражеских набегов. Они вырубили в скалах кельи и церкви, создав уникальный пещерный монастырь. <br>' +
                '<br>Монастырь пережил взлеты и падения. В XVII-XVIII веках монастырь переживал расцвет. Был построен большой каменный храм, а также множество хозяйственных построек. В начале XX века он был духовным центром Бессарабии, но в 1964 году был закрыт и перепрофилирован в психиатрическую лечебницу. Возрождение обители началось в 1991 году. Сегодня здесь проживает около 20 монахов.',
            item12Article2: 'Монастырский комплекс Сахарна представляет собой уникальное сочетание природных и рукотворных элементов. Главной достопримечательностью является пещерный монастырь, вырубленный в скалах. Здесь сохранились церкви, кельи монахов и подземные ходы.<br>' +
                '<br>Кроме пещерного монастыря, на территории комплекса расположены:' +
                '<ul>' +
                '<li>Каменный храм,</li>' +
                '<li>Часовня,</li>' +
                '<li>Жилые корпуса,</li>' +
                '<li>Хозяйственные постройки.</li>' +
                '</ul>' +
                '<br>Монастырь Сахарна является действующим мужским монастырем. Здесь монахи ведут строгий образ жизни, посвящая себя молитве и труду. В монастыре проводятся регулярные богослужения, а также паломнические туры.<br>',
            item12Article3: 'Одной из главных святынь монастыря являются мощи преподобного Макария. К ним ежегодно приезжают тысячи паломников, чтобы помолиться и попросить о помощи. <br>' +
                '<br>На территории комплекса тихо и спокойно - скалы, лес и обитель будто приглашают отвлечься от мирской суеты и погрузиться в изучение собственной души. Однако,  время, посещение монастыря доставит радость и любителям туризма - для того, чтобы добраться до некоторых локаций, нужно не только пройтись пешком, но и преодолеть препятствия (что, впрочем, неудивительно для скального монастыря). Зато и награда будет по заслугам - живописный водопад и потрясающие виды, от которых захватывает дух.' +
                '<br>Приятный бонус - прекрасный, но не очень сговорчивый белый павлин, живущий на территории монастыря.<br>' +
                '<br>Монастырь Сахарна – это место, где можно отдохнуть от городской суеты и найти внутреннюю гармонию.',

            libraryTitleItem13: 'Старый Орхей',
            libraryTitleDescription13: 'Путешествие во времени',
            item13DescriptionText: 'Старый Орхей – это живое воплощение истории Молдовы, место, где каждая скала хранит свои тайны, а каждый камень – свидетель событий минувших веков. Расположенный в живописной долине реки Реут, этот заповедник приглашает путешествие во времени, от гето-даков до средневековья.',
            item13Article1:'Одной из главных достопримечательностей Старого Оргеева является монастырь - скальная обитель. Этот скит с красивейшей церковью и кельями, вырубленными в скале, является символом духовности и духовной традиции. <br>' +
                '<br>Путь к  археологическим и религиозным памятникам довольно длинный, но не очень сложный, но что поражает сразу при въезде в долину - это открывающийся вид. Долина, окруженная горами, река в форме подковы… как в солнечный день, так и  в пасмурный, этот вид запечатлится в памяти навсегда.' +
                'Если вы решитесь на многокилометровую экскурсию по Старому Оргееву, подготовьте удобную обувь, много места в телефонах или фотоаппаратах, и море эмоций - вас ждет погружение в удивительный мир заповедника, внесенного в список Всемирного Наследия ЮНЕСКО.',
            item13Article2: 'Первые поселения на территории Старого Орхея появились еще до Нашей эры - самые древние археологические находки датированы первым тысячелетием до Р.Х. Однако наибольший расцвет город пережил в период существования Древнемолдавского государства. Здесь располагалась дворец воеводы, а также многочисленные ремесленные мастерские, баня, жилища горожан.<br>' +
                '<br>О Старом Орхее сложено немало легенд. Одна из самых известных гласит, что в этих пещерах скрывались сокровища легендарного молдавского господаря Штефана Великого. Другая легенда рассказывает о том, что именно здесь был заключен в темницу мифический герой Орфей. Конечно, эти истории не имеют исторического подтверждения, но придают месту особую атмосферу таинственности и романтики.',
            item13Article3: 'Архитектурный ансамбль Старого Орхея представляет собой уникальное сочетание природных и рукотворных элементов. Главными достопримечательностями являются:  <br>' +
                '<ul>' +
                '<li><b>Гето-дакская крепость:</b> Самое древнее сооружение комплекса, датируемое VII-V веками до нашей эры. Крепость была построена даками – древним фракийским племенем, населявшим эти земли. </li>' +
                '<li><b>Пещерный монастырь:</b> Вырубленный в скалах монастырь был основан в XV веке. Его кельи, церкви и подземные ходы до сих пор поражают своей масштабностью и мастерством исполнения.</li>' +
                '<li><b>Церковь и Монастырь Рождества Богородицы:</b> Основанный в XV-XVI веках, монастырь Рождества Богородицы тесно связан с древним скальным скитом "Пещера". После периода затишья в XVIII-XIX веках, монастырь был возрожден в начале XX века. Советская власть превратила его и скальный скит в музей под открытым небом, вывезла ценности и даже сняла колокола. Возрождение обители началось в середине 1990-х годов.</li>' +
                '<li><b>Сооружения, характерные для городов Золотой Орды:</b> Караван-сараи, общественные бани, мечеть, мавзолей-мазары, найденные археологами. До наших времён сохранились остатки бани, имевшей помещения для ванн, отдыха, массажа, оборудованной отоплением и вентиляцией.</li>' +
                '</ul>',
            item13Article4: 'Сегодня Старый Орхей – это популярное туристическое место. Здесь проводятся различные культурные мероприятия, фестивали и конференции. В селе Бутучены, которое входит в комплекс Старый Оргеев, расположены музей и площадка, на которой проводится уникальный фестиваль Опера в Бутученах. Так же вас ждет уникальная Этно-Вилла, которая еще глубже погрузит в атмосферу этого уникального места.<br>' +
                '<br>Что же касается истории и археологии, и в настоящее время ученые продолжают исследовать этот сказочно богатый исторический объект, открывая все новые тайны прошлого.<br>'+
                '<br><br><br><b>Почему стоит посетить Старый Орхей?</b> <br>' +
                '<ul>' +
                '<li>Старый Орхей – это живой музей, где можно прикоснуться к истории Молдовы.</li>' +
                '<li>Живописные пейзажи долины рек Реут и Когильник создают неповторимую атмосферу.</li>' +
                '<li>Здесь вы сможете найти уединение и спокойствие.</li>' +
                '<li>Пещерный монастырь, гето-дакская крепость и другие сооружения поражают своей древностью и красотой.</li>' +
                '</ul>' +
                'Старый Орхей – это место, которое обязательно нужно посетить, чтобы проникнуться историей и культурой Молдовы.',

            libraryTitleItem14: 'Свято-Троицкий монастырь в Руди',
            libraryTitleDescription14: 'История, духовность и величие камня',
            item14DescriptionText: 'В живописном ущелье реки Днестр, что в 200 километрах к северу от Кишинева, расположилось село Рудь. Здесь, среди холмов и лесов, словно драгоценный камень, сияет Свято-Троицкий монастырь – духовное сердце этих мест, основанный в далеком 1777 году.',
            item14Article1:
                'Этот монастырь – один из старейших в Молдове, он притягивает к себе паломников и туристов, ищущих уединения и духовного успокоения. Его архитектура, выполненная в старо-молдавском стиле, поражает своей простотой и изяществом. Камень, из которого сложены стены, хранит в себе тепло веков и энергию многих поколений монахов.',
            item14Article2:
                'Особенно выделяется Троицкая церковь – сердце монастырского комплекса. Ее стены, украшенные уникальными фресками и иконами, рассказывают историю веры и преданности. Здесь, под сводами храма, царит особая атмосфера благодати и покоя.<br>' +
                '<br>Монастырь пережил многое на своем веку. Были времена расцвета и упадка, когда его закрывали и использовали для различных нужд. В советское время здесь располагались детский дом и санаторий. Но, несмотря на все испытания, обитель возродилась, словно феникс из пепла. И сегодня, как и много лет назад, сюда вновь приходят люди, чтобы помолиться, найти утешение и обрести душевное равновесие.',
            item14Article3:
                'Монахини, которые сейчас живут в монастыре, с любовью и заботой ухаживают за его территорией. Здесь всегда чисто и ухожено, цветы радуют глаз своей красотой, а тишина и покой способствуют молитве и размышлениям.<br>' +
                '<br>Посещение Свято-Троицкого монастыря – это не просто экскурсия, это паломничество к истокам веры и духовности. Здесь можно прикоснуться к истории, почувствовать связь времен и обрести надежду на лучшее.',
            item14Article4:
                'Если вы ищете место, где можно отдохнуть от городской суеты, насладиться красотой природы и обрести душевное равновесие, то Свято-Троицкий монастырь в Руди – это именно то, что вам нужно.<br>' +
                '<br>Если вы планируете поездку в Рудь и не хотите столкнуться со множеством туристов, лучше ехать в будний день - в выходные, и, тем более, в дни религиозных праздников, монастырь собирает множество паломников. Также стоит помнить о ландшафте, окружающем монастырь - если захотите подняться к роднику, прогуляться по лесу или дойти до Турковой тарелки, надо подобрать удобную обувь и одежду, в которой будет комфортно и безопасно передвигаться по лесу с перепадами высот.',
            item14Article5: 'Стоит ли посетить Рудь? Однозначно да. В этом месте каждый найдет что-то для себя. Любители истории смогут прикоснуться к древним артефактам, ценители природы насладятся живописными пейзажами, а паломники обретут духовное успокоение в стенах древнего монастыря.',

            libraryTitleItem15: 'Дуга Струве',
            libraryTitleDescription15: 'Как молдавский куб хранит тайну Земли',
            item15DescriptionText: 'На севере Молдовы, недалеко от села Рудь, спрятанный от посторонних глаз, лежит каменный куб. Ничем не примечательный на первый взгляд, он, однако, является безмолвным свидетелем грандиозного научного проекта, имя которому - Дуга Струве.',
            item15Article1:
                'Двести лет назад, когда наука только начинала исследовать тайны нашей планеты, российский астроном Фридрих Георг Вильгельм Струве поставил себе амбициозную задачу - измерить дугу меридиана, чтобы раскрыть секрет формы и размеров Земли.<br>' +
                '<br>Тридцать пять лет, с 1816 по 1852 год, "геометры трех народов" - русские, норвежцы и шведы - трудились над созданием уникальной геодезической дуги, протянувшейся на две тысячи восемьсот двадцать километров от арктических просторов до черноморского побережья.',
            item15Article2:
                'Двести шестьдесят пять пунктов, каменных кубов, подобных тому, что покоится близ села Рудь, были заложены в землю на территории десяти современных государств. Каждый из них был частью грандиозной головоломки, разгадка которой должна была открыть человечеству тайну мироздания.<br>' +
                '<br>Дуга Струве стала не просто выдающимся достижением науки, она превратилась в символ международного сотрудничества и неутолимого стремления к познанию. Её результаты легли в основу создания карт и научных исследований на долгие годы.<br>' +
                '<br>В XXI веке, когда на смену каменным кубам пришли спутниковые технологии, Дуга Струве не утратила своего значения. Она по-прежнему считается одним из важнейших событий в истории мировой астрономии, геодезии и географии.',
            item15Article3:
                'В 2005 году Комитет по всемирному наследию ЮНЕСКО признал Дугу Струве объектом культурного наследия "выдающейся универсальной ценности". Это единственный геодезический объект и единственный чисто интеллектуальный памятник, включенный в список Всемирного наследия.<br>' +
                '<br>Каменный куб возле села Рудь - это не просто точка на карте, это символ научного подвига, символ стремления человека к познанию мира. Он напоминает нам о том, что наука не знает границ, а стремление к истине объединяет людей разных национальностей и культур.',

            libraryTitleItem16: 'Комплекс «Каменный век»',
            libraryTitleDescription16: 'Туристический комплекс «Каменный век» винодельни Brănești - путешествие в доисторические времена',
            item16DescriptionText: 'Каменные стены и полы, рисунки мамонтов на стенах, тонны камня над головой, деревянная мебель,шкуры животных и живой огонь… Это не описание жилища первобытной семьи, а расположенный в самом сердце Республики Молдова, туристический комплекс «Эпоха камня» при винодельне Brănești.',
            item16Article1:
                'Комплекс сочетает винодельческие традиции Молдовы с загадочной атмосферой подземных пещер. Этот впечатляющий комплекс высечен в известняке, на глубине более 60 метров, и занимает разветвленную сеть подземных галерей протяженностью 70 км.<br>' +
                '<br>Здесь экскурсанты могут увидеть запыленные бутылки вина, хранящиеся в вырубленных в прямо в скале круглых нишах, а наскальные росписи на стенах погружают в каменный век.',
            item16Article2:
                '«Каменный век» – это не просто винодельня, а уникальное туристическое направление, где гости могут:<br>' +
                '<ul>' +
                '<li>Дегустировать вина прямо в подземных галереях, наслаждаясь традиционными молдавскими блюдами.</li>' +
                '<li>Изучать впечатляющие декорации в доисторическом стиле, превращающие галереи в настоящий подземный музей.</li>' +
                '<li>Отдохнуть в необычном отеле, где уютные номера оформлены в стиле традиционных молдавских жилищ.</li>' +
                '<li>Посещать фестивали и мероприятия, которые проходят прямо внутри комплекса и сочетают музыку, гастрономию и винную культуру.</li>' +
                '</ul>',
            item16Article3:
                'Если вы ценитель вина, любитель истории или просто путешественник в поисках новых впечатлений, комплекс «Эпоха камня» приглашает вас в мир, где природа и винодельческие традиции гармонично сочетаются.<br>' +
                '<br>Приезжайте открыть для себя настоящий вкус Молдовы и окунуться в атмосферу места, не имеющего аналогов в стране!',

            libraryTitleItem17: 'Château Vartely',
            item17DescriptionText: 'Это одна из самых известных виноделен Молдовы, где винодельческие традиции гармонично сочетаются с современными технологиями.',
            item17Article1:
                'Расположенная неподалеку от Орхея, она привлекает посетителей высококачественными винами, гостеприимством и уникальным туристическим комплексом. Château Vartely использует сорта винограда, выращенные в знаменитых винодельческих регионах Молдовы – Кодрах и южной зоне страны. Здесь производят как классические европейские вина, так и исконные молдавские сорта, например, Feteasca Albă, Feteasca Neagră и Rară Neagră, которые завоевывают сердца дегустаторов по всему миру.<br>' +
                '<br>Впрочем, Château Vartely – это не только производство вина, но и настоящий культурный центр. В музее вина на стенах можно увидеть листы коры пробкового дуба, и длинные пробковые трубки - то, что впоследствии становится теми самыми винными пробками, которые мы все и привыкли видеть.',
            item17Article2:
                'Здесь представлены вина из различных стран мира,а еще посетителям демонстрируют образцы различных типов грунта, на которых выращивается виноград, помогая понять влияние терруара на вкус вина. Коллекция винодельни насчитывает большое количество сортов и бутылок вина. В производственных цехах, можно увидеть высокие цистерны, в которых происходит брожение вина.',
            item17Article3:
                'Одной из изюминок посещения Château Vartely станет световое шоу по технологии видеомэппинга. Яркие анимированные изображения появляются на хранящихся в подвале бочках с вином. Даже если вы не ярый поклонник вина, световая фантасмагория не оставит вас равнодушными.<br>' +
                '<br>Château Vartely предлагает своим гостям не только знакомство с производством, но и полноценный отдых. Так, гости винодельни могут увидеть весь процесс производства, продегустировать вина и отдохнуть в виллах, оформленных в стиле лучших винодельческих регионов мира.',
            item17Article4:
                'Château Vartely – это не просто винодельня, а культурный центр, объединяющий историю, винное мастерство и молдавское гостеприимство. Вина, произведенные здесь, экспортируются более чем в 30 стран и являются визитной карточкой Молдовы на международной арене.',

            libraryTitleItem18: 'Музей села',
            libraryTitleDescription18: 'Путешествие в молдавскую глубинку',
            item18DescriptionText: 'Представьте себе путешествие во времени, в самое сердце молдавской деревни. Именно это вас ждет в Музее села в Кишиневе. Это не просто музей, а настоящий живой уголок истории, где можно прикоснуться к прошлому и почувствовать, как жили наши предки.',
            item18Article1:
                'Прогуливаясь по музею, вы окажетесь в молдавском селе XVII века. Вот традиционный молдавский дом с резными дверьми, каменным фундаментом и крепкой лестницей. А вот и предметы быта: глиняная посуда, ткацкие станки, старинные прялки и сельскохозяйственные орудия. А вот и чистая питьевая вода и колодец-журавль.<br>' +
                '<br>Особую атмосферу подчеркивает тяжелый медный колокол, слегка раскачивающийся на ветру.<br>' +
                '<br>Каждый экспонат это частичка души молдавского народа, его трудолюбия и традиций.',
            item18Article2:
                'По мощеной камнем дорожке вы дойдете до главной жемчужины музея - деревянной церкви Успения Пресвятой Богородицы, привезенной из села Хиришень. Эта церковь, построенная в 1642 году, один из старейших памятников архитектуры в Молдове. Ее высота 27 метров. Эта церковь является одним из самых высоких деревянных храмов в Молдове, поражая своей красотой и изяществом.<br>' +
                '<br>Музей села это не просто место для экскурсий, это место, где можно отдохнуть душой и насладиться тишиной. Здесь царит атмосфера спокойствия и умиротворения.',
            item18Article3:
                '<h4>Почему стоит посетить Музей села?</h4><br>' +
                '<ul>' +
                '<li>Погрузиться в атмосферу настоящей молдавской деревни.</li>' +
                '<li>Увидеть уникальные экспонаты, рассказывающие об истории и культуре Молдовы.</li>' +
                '<li>Увидеть вживую старинную деревянную церковь.</li>' +
                '<li>Сделать красивые фотографии на память.</li>' +
                '<li>Просто отдохнуть и насладиться тишиной.</li>' +
                '</ul>' +
                'Музей села расположен недалеко от Городских ворот столицы Молдовы. Это идеальное место для тех, кто хочет узнать больше о прошлом Молдовы и ее народа.',

            notFound: 'НЕ НАЙДЕНО :(',
        },
        ro: {
            menuItemHome: 'Acasă',
            menuItemLibrary: 'Bibliotecă',
            menuItemForum: 'Forum',
            aboutUs: 'Despre Noi',
            aboutUsText1:
                '„Unitate în Diversitatea Culturală” - un proiect destinat conservării locurilor de patrimoniu cultural din Moldova, aflate la intersecția culturilor celor trei țări vecine: Ucraina, Moldova și România. Misiunea noastră este de a crea o bibliotecă electronică a locurilor de patrimoniu cultural pentru a le populariza, evitând astfel distrugerea și uitarea unora dintre ele.',
            aboutUsText2:
                'În plus, în cadrul proiectului se organizează tabere tematice sezoniere, al căror punct culminant este recrearea după canoanele sărbătorilor sezoniere, cu reproducerea tradițiilor și obiceiurilor la intersecția celor trei culturi.',
            sponsors: 'Inspirator ideatic',
            sponsorsDescription: 'Acest proiect există datorită ideilor noastre, pe care am reușit să le realizăm cu sprijinul sponsorilor noștri.',
            footerPartners: 'Parteneri',
            footerSupport: 'Cu sprijinul fundațiilor',
            forumAboutUs: 'Despre Noi',
            forumAboutUsText1: 'Proiectul „Unitate în Diversitatea Culturală” vizează conservarea locurilor de patrimoniu cultural ale diferitelor grupuri etnice din Moldova prin popularizarea acestora pe internet, evitând astfel distrugerea și uitarea lor.',
            forumAboutUsText2: 'Misiunea echipei noastre este de a vizita obiectivele de patrimoniu cultural, de a face fotografii, videoclipuri și de a vă povesti despre ele. Suntem convinși că această bibliotecă electronică va deveni pentru dumneavoastră un ghid minunat prin Moldova și diversitatea sa culturală.',
            forumFormTitle: 'Forum',
            forumFormText1: 'Țara noastră este foarte bogată în locuri de patrimoniu cultural, iar scopul nostru este să povestim despre aceste locuri, sporind interesul pentru a evita distrugerea lor. Acest forum a fost creat pentru ca oricine dorește să poată propune un obiectiv (sau să voteze pentru unul preferat) de patrimoniu cultural pe care ar trebui să-l vizităm și să-l descriem.',
            forumFormText2: 'Aici puteți trimite un mesaj pe adresa noastră de email și alte rețele sociale sau vă puteți alătura forumului pe Telegram.',
            formLabelName: 'Introduceți numele și prenumele',
            formLabelEmail: 'Introduceți e-mailul',
            formLabelPhone: 'Introduceți numărul de telefon',
            formLabelMessage: 'Introduceți mesajul dumneavoastră',
            formTelegramButton: 'Forum pe Telegram',
            formSendButton: 'Trimite',
            categoryTitleItem1: 'Toate',
            categoryTitleItem2: 'Lăcașuri sfinte',
            categoryTitleItem3: 'Festivaluri și târguri',
            categoryTitleItem4: 'Muzee și teatre',
            categoryTitleItem5: 'Clădiri',
            categoryTitleItem6: 'Cetați',

            libraryTitleItem1: '"Ivana Kupala Camp"',
            libraryTitleDescription: 'Fuziunea culturii poporului la granița a trei influențe etnice',
            item1DescriptionText: 'La începutul lunii iulie, pe malul unui lac pitoresc în regiunea de graniță dintre Ucraina, Moldova și România, în orașul Drochia, a avut loc un eveniment de trei zile, "Ivana Kupala Camp", dedicat sărbătorii tradiționale a Nopții de Sânziene. Această sărbătoare are rădăcini adânci în culturile tuturor celor trei țări și este un simbol al unității și bogăției tradițiilor lor culturale.',
            itemArticle1: '<h4>Ziua 1 și 2: Familiarizarea cu cultura și tradițiile</h4> ' +
                '<br>Prima și a doua zi a evenimentului au fost dedicate familiarizării participanților cu particularitățile culturale și tradițiile sărbătorii Nopții de Sânziene în Ucraina, Moldova și România. <br>' +
                '<br><b>Ucraina:</b> În Ucraina, Noaptea de Sânziene (Noaptea de Kupala) este sărbătorită ca ziua solstițiului de vară, simbolizând purificarea și renașterea. Elementele tradiționale includ sărituri peste foc, împletirea coronițelor din flori de câmp și lansarea lor pe râu pentru a ghici viitorul soț.<br>' +
                '<br><b>Moldova:</b> În Moldova, această sărbătoare este cunoscută sub numele de "Sânziene". De asemenea, este legată de solstițiul de vară și include ritualuri de purificare cu foc și apă. O caracteristică specială a sărbătorii moldovenești este dansul "Hora", care se dansează în jurul focului.<br>' +
                '<br><b>România:</b> În România, Sânzienele sunt o sărbătoare a ritualurilor mistice și magice. Coronițele din flori, împletite de Sânziene, au puteri magice și protejează de spiritele rele. Seara se aprind focuri, în jurul cărora se dansează hore.',
            itemArticle2: '<b>Tradiții comune:</b><br>' +
                '<br><ul>' +
                '<li>Împletirea coronițelor din flori de câmp</li>' +
                '<li>Aprinderea focurilor și sărituri peste ele</li>' +
                '<li>Dansuri și cântece în jurul focului</li>' +
                '</ul><br>' +
                '<b>Tradiții speciale ale fiecărei țări:</b>' +
                '<br><ul>' +
                '<li>Ucraina: Lansarea coronițelor pe apă pentru ghicit</li>' +
                '<li>Moldova: Dansul "Hora"</li>' +
                '<li>România: Ritualuri magice cu coronițe</li>' +
                '</ul><br>' +
                'În primele două zile, participanții au putut lua parte la ateliere de împletire a coronițelor din flori de câmp și de creare a talismanelor, pictând pandantive cu rune antice. Acest lucru a permis fiecărui participant să se cufunde în tradițiile și cultura vecinilor lor.',
            itemArticle3: '<h4>Ziua 3: Sărbătoarea Nopții de Sânziene</h4>' +
                '<br>' +
                '<p>A treia zi a fost punctul culminant al taberei, când pe malul lacului a avut loc sărbătoarea propriu-zisă a Nopții de Sânziene. Participanții au pregătit locul pentru sărbătoare, împletind coronițe, pictând fețele cu vopsele speciale (pentru copii) și creând trei zone foto pentru a imortaliza momentele memorabile.</p>' +
                '<p>La eveniment au fost invitați ansambluri populare care au interpretat cântece în limbile ucraineană, moldovenească și română, precum și au organizat spectacole de dansuri cu elemente populare.</p>',
            itemArticle4: '<p>A fost organizată o expoziție de artizanat de către meșteșugari populari, unde oaspeții au putut admira și achiziționa articole unice, create cu dragoste și măiestrie.</p>' +
                '<p>Pentru asigurarea siguranței participanților, au fost desemnați observatori, iar autoritățile orașului au alocat două echipaje de poliție. Siguranța pe apă a fost asigurată de echipa locală de salvamari.</p>' +
                '<p>Mulți participanți și-au împărtășit impresiile despre "Ivana Kupala Camp". Ei au remarcat că evenimentul a fost extrem de captivant și informativ. <i>"Împreună cu @uicd.md, timp de 3 zile, ne-am regăsit într-o cunoaștere adâncă a tradițiilor, miturilor și obiceiurilor de Ivana Kupala,"</i> – împărtășește Teodora. Alexandr a adăugat: <i>"Vreau să exprim mulțumiri enorme organizatorilor pentru sărbătoarea de neuitat a Ivana Kupala în orașul Drochia. Datorită muncii și grijii din partea organizatorilor, toți locuitorii orașului au putut să se bucure de o atmosferă magnifică și de tradiții. Această zi va rămâne în inimile noastre ca o amintire luminoasă și fericită."</i> Participanții au mai remarcat că evenimentul a fost o ocazie excelentă pentru noi prietenii și pentru întărirea legăturilor culturale dintre țări.</p>',
            itemArticle5: '<p>"Ivana Kupala Camp" nu a fost doar o sărbătoare, ci un adevărat schimb cultural între cele trei țări. Participanții au avut ocazia să se cufunde în bogăția tradițiilor, să facă noi prieteni și să întărească legăturile culturale. Această tabără a arătat cât de important este să păstrăm și să dezvoltăm patrimoniul cultural, unind oamenii prin tradiții și obiceiuri.</p>' +
                '<p>Mulțumim tuturor participanților și oaspeților pentru crearea unei atmosfere de neuitat și vă așteptăm la următoarele evenimente!</p>',
            itemArticle6: 'Thanks to the support of the European Heritage Hub pilot Project co-funded by the European Union and supported by the ALIPH Foundation.',

            libraryTitleItem2: 'Cetatea Soroca',
            libraryTitleDescription2: 'Perla istoriei moldovenești',
            item2DescriptionText: 'Cetatea Soroca este unul dintre cele mai cunoscute monumente istorice din Moldova. Cetatea, situată pe malul drept al Nistrului, a fost ridicată la ordinul marelui domnitor moldovean Ștefan cel Mare cu peste cinci sute de ani în urmă.',
            item2Article1:
                'Construită în ultimul sfert al secolului al XV-lea (data exactă a finalizării construcției este necunoscută), a servit drept un bastion de încredere în apărarea granițelor nordice ale Principatului Moldovei împotriva invaziilor frecvente ale tătarilor crimeeni.<br>' +
                '<br>Forma cetății este neobișnuită pentru fortificațiile moldovenești – o cetate circulară, cu un diametru de aproximativ 37,5 metri, cu cinci turnuri. Chiar și turnurile surprind vizitatorii – nu-ți dai seama imediat că 4 dintre cele 5 turnuri sunt circulare, iar unul (cel de la poartă) este de formă dreptunghiulară.<br>' +
                '<br>O astfel de construcție era inovatoare pentru timpul său și asigura cetății o capacitate de apărare excelentă, făcând-o aproape impenetrabilă, așa cum o demonstrează legendele legate de cetatea Soroca. ',
            item2Article2: '<h4>Restaurare și renaștere:</h4>' +
                '<br>După secole de istorie, în 2015 au fost finalizate lucrări de restaurare de mare amploare. Datorită acestora, monumentul de istorie și arhitectură s-a prezentat vizitatorilor în toată splendoarea sa de odinioară. Restauratorii au refăcut cu grijă zidurile, turnurile, precum și spațiile interioare. Cu toate acestea, procesul de restaurare nu s-a oprit aici. Din aprilie 2021, cetatea a fost aproape închisă pentru vizitatori și și-a recâștigat complet statutul de obiectiv turistic abia în aprilie 2024, moment de care turiștii au profitat imediat, umplând cetatea.',
            item2Article3: '<h4>Sabia legendară a lui Ștefan cel Mare</h4>' +
                '<br>' +
                '<p>În cetate a fost creat un muzeu dedicat istoriei Moldovei și, în special, epocii domniei lui Ștefan cel Mare. Una dintre principalele expoziții ale muzeului este copia celebrei săbii a domnitorului moldovean. Acest exponat le permite vizitatorilor să se cufunde în atmosfera medievală și să își imagineze războinicul viteaz care își apăra pământul.</p>' +
                '<p>Originalul sabiei este în prezent păstrat în Turcia, iar România vecină poartă negocieri pentru a readuce relicva la locul ei de origine istorică.</p>',
            item2Article4: 'Astăzi, cetatea Soroca nu este doar un monument istoric, ci și o destinație turistică populară. Vizitarea acesteia este o oportunitate unică de a atinge patrimoniul istoric bogat al Moldovei, de a afla mai multe despre viața strămoșilor noștri și de a te bucura de peisajele minunate ale Moldovei.',

            libraryTitleItem3: 'Mănăstirea Cosăuți',
            item3DescriptionText: 'Mănăstirea Cosăuți este un loc uimitor unde istoria și contemporaneitatea se împletesc într-un tot armonios.' +
                'Situată în valea pitorească a râului Nistru, la aproximativ 5 kilometri de satul Cosăuți, această mănăstire atrage pelerini și turiști din întreaga țară.',
            item3Article1: '<h4>Istoria mănăstirii</h4>' +
                '<p>Primele mențiuni despre mănăstire datează din 1729. Atunci, pe locul care ulterior a devenit cunoscut sub numele de „la Pristol”, a apărut o mică mănăstire de lemn, dedicată Nașterii Maicii Domnului. Fondatorii săi au fost călugării Pavel și Gavriil. Mănăstirea a existat aproximativ 160 de ani, dar în 1833 a fost închisă.</p>' +
                '<p>În secolul XX, din cauza cutremurelor și alunecărilor de teren, din vechea mănăstire nu a mai rămas aproape nimic. Doar o mică capelă a supraviețuit, unde locuitorii și pelerinii au continuat să se roage.</p>',
            item3Article2: '<h4>Renașterea vieții spirituale</h4>' +
                '<br>' +
                '<p>La sfârșitul secolului XX, în 1994, la inițiativa preotului local Ieronim Palie, a început renașterea mănăstirii. Pe locul vechii mănăstiri a fost construită o nouă biserică, dedicată Adormirii Maicii Domnului. Proiectul bisericii a fost realizat cu binecuvântarea mitropolitului Moldovei și Bucovinei, Daniel.</p>' +
                '<p>Construcția mănăstirii s-a desfășurat în condiții dificile, dar datorită devotamentului părintelui Ieronim și sprijinului enoriașilor, biserica a fost sfințită în 1999. Treptat, în jurul bisericii a crescut un complex monahal, incluzând chilii, trapeza și alte construcții auxiliare.</p>',
            item3Article3: '<h4>Mănăstirea astăzi</h4>' +
                '<br>' +
                '<p>Astăzi, mănăstirea Cosăuți nu este doar un loc de rugăciune, ci și un obiectiv turistic popular. Pelerini și turiști din toată țara vin aici pentru a se conecta cu rădăcinile credinței ortodoxe, pentru a se bucura de liniștea și frumusețea naturii.</p>' +
                '<p>La mănăstire se pot vizita slujbele (slujbele se țin în limbile română și rusă) și se pot face tururi de pelerinaj (inclusiv la alte locuri sfinte din Moldova).</p>' +
                '<p>De asemenea, zona mănăstirii este amenajată și ideală pentru odihnă liniștită.</p>',
            item3Article4:
                '<p>O altă atracție a mănăstirii este izvorul de apă minerală – la câteva minute de mers pe jos de mănăstire, lângă zonele de picnic, se află un izvor cu apă vindecătoare.</p>',
            item3Article5:
                'Mănăstirea Cosăuți este un exemplu viu despre cum credința și munca pot schimba lumea. Este un loc unde fiecare poate găsi mângâiere spirituală și poate simți legătura cu veșnicia. Mănăstirea este deschisă pentru vizitare zilnic.',

            libraryTitleItem4: 'Dialul Țiganilor',
            item4DescriptionText: 'Soroca este cunoscută nu doar pentru cetatea sa veche, ci și pentru un cartier unic cunoscut sub numele de Dialul Țiganilor. Situat pe o înălțime, acest cartier a devenit adevărata carte de vizită a orașului datorită caselor sale neobișnuite.',
            item4Article1:
                'Istoria apariției romilor în Soroca este învăluită în legende. Potrivit uneia dintre ele, pământurile de pe deal au fost dăruite romilor de către domnitorul moldovean Ștefan al III-lea în semn de recunoștință pentru ajutorul lor în înarmarea armatei. Într-un fel sau altul, romii locuiesc în Soroca de foarte mult timp, respectându-și tradițiile și protejându-și cultura.<br>' +
                '<br>Arhitectura caselor de pe Dialul Țiganilor este impresionantă. Este un adevărat caleidoscop arhitectural, unde se întâlnesc elemente de clasicism, baroc, modernism și chiar stiluri orientale. Fiecare casă este o operă de artă, decorată cu stucaturi, coloane, turnulețe și diverse elemente decorative. Aici poți întâlni figuri de creaturi mitologice, simboluri religioase, dar și copii ale unor construcții arhitecturale celebre, cum ar fi spirele Amiralității sau cupola unei moschei. ' +
                '<br>Cu toate acestea, multe case de pe Dialul Țiganilor au rămas neterminate. Totuși, chiar și neterminate, aceste clădiri conferă orașului un farmec aparte și atrag numeroși turiști.',
            item4Article2:
                'Dialul Țiganilor este locul unde trecutul și prezentul se împletesc, creând o atmosferă unică. Este un loc pe care trebuie neapărat să-l vizitezi pentru a vedea cu ochii tăi cum arată un adevărat miracol arhitectural. Totuși, cei care decid să facă un tur al acestui loc nu trebuie să uite de tradițiile, obiceiurile și cultura romilor, care pot fi semnificativ diferite de cele pe care turiștii le-au văzut în alte colțuri ale Moldovei. ',

            libraryTitleItem5: 'Râpa lui Bechir',
            item5DescriptionText: 'Râpa lui Bechir sau Peștera lui Bechir este un monument istoric și natural unic. Săpată în stâncă, așa-numita chilie a călugărului este considerată unul dintre cele mai vechi monumente religioase din regiune și datează din secolele VI-VIII.',
            item5Article1:
                'Монастырский пещерный комплекс расположен на высоте 12-14 метров на отвесной известняковой скале. Пещера представляет собой небольшое помещение с алтарем, ориентированным на север. Внутри пещеры есть высеченные лежанка, стол, ниши для иконы и лампады, и даже есть погреб.<br>' +
                '<br>Localnicii asociază adesea numele peșterii cu ultimul haiduc moldovenesc, Bechir, care se ascundea de persecuții. Conform unei alte legende, în peșteră trăia un călugăr care ieșea în fiecare duminică să predice oamenilor. Totuși, aceste legende rămân doar legende – potrivit cercetărilor realizate de Vasile Trofăilă, originar din raionul Soroca, Bechir era un negustor turc care făcea comerț pe acest teritoriu și încerca să își extindă influența.' +
                '<br>Cine a fost Bechir istoric nu știm cu certitudine, dar monumentul care îi poartă numele nu își pierde din atractivitatea pentru turiști, deși este dificil să intri în peșteră. ',
            item5Article2:
                'Peștera lui Bechir nu este doar un monument religios, ci și unul geologic. Este situată într-un canion pitoresc format prin eroziunea rocilor de calcar. Puteți urca la ea trecând prin pădure, în apropierea urcării spre Lumânarea Recunoștinței.' +
                '<br>Poteca pe care turiștii trebuie să o urmeze este pitorească – în stânga curge un pârâu, în care se văd bolovani uriași, căzuți de pe deal cu mult timp în urmă, copacii șuieră, păsările cântă și simți că ai intrat într-o pădure fermecată.',
            item5Article3:
                'Urcarea la peștera lui Bechir începe lângă doi vecini monumentali – un plop uriaș, cu un trunchi gros cât câțiva oameni la un loc, și un bolovan imens, acoperit cu semnături de turiști.' +
                '<br>De la acești „prieteni” memorabili, trebuie să urcați pe o potecă destul de abruptă câteva zeci de metri până la peșteră. Așadar, dacă decideți să vedeți cu ochii voștri Râpa lui Bechir și să urcați la peșteră, ar fi bine să aveți încălțăminte adecvată pentru a nu vă distrage de la plimbarea prin pădure și canion.',
            item5Article4:
                'Deși peștera are o mare valoare istorică și culturală, mult timp nu a fost sub protecția statului. Totuși, datorită eforturilor comunității și autorităților locale, peștera lui Bechir a fost inclusă oficial în lista monumentelor protejate din Moldova.' +
                '<br>În prezent, se desfășoară lucrări de conservare și restaurare a acestui obiectiv unic, iar oamenii de știință continuă să o studieze pentru a dezvălui toate tainele trecutului său.' +
                '<br>Peștera lui Bechir este un loc unic, unde istoria se împletește cu natura, oferindu-le turiștilor impresii de neuitat și posibilitatea de a atinge vechimea.',

            libraryTitleItem6: 'Parcul Țaul',
            libraryTitleDescription6: 'Paradisul pierdut al Moldovei',
            item6DescriptionText: 'În nordul Moldovei, în satul Țaul din raionul Dondușeni, se ascunde o adevărată comoară – Parcul Țaul. Acest colț unic de natură, întins pe 46,2 hectare, este unul dintre cele mai mari și mai frumoase parcuri din Moldova. Creat la începutul secolului XX după proiectul arhitectului peisagist renumit Ipolit Vladislavski-Padalco, parcul reprezintă o îmbinare armonioasă între frumusețea naturală și creativitatea umană.',
            item6Article1: 'Inițiatorul acestui paradis verde a fost Andrei Pommer, un cunoscut bancher și filantrop. Dorind să creeze un loc retras pentru odihnă și contemplare, el a achiziționat aceste terenuri și a investit sufletul și resursele sale în amenajarea lor. Rezultatul eforturilor sale este un parc magnific care continuă să uimească prin frumusețea și diversitatea sa.',
            item6Article2: 'Parcul Țaul este cu adevărat o grădină botanică în aer liber. Aici sunt adunate sute de specii de plante din întreaga lume: de la stejari impunători la liane exotice. Fiecare alee, fiecare colț al parcului este o operă de artă peisagistică. Atmosfera este completată de lacurile create artificial, unde înoată lebede grațioase.',
            item6Article3: 'Inima parcului o reprezintă conacul Pommer – un conac elegant, construit la începutul secolului XX. Această clădire splendidă, realizată în stil clasic, impresionează prin eleganța și amploarea sa. Din păcate, anii și lipsa unei îngrijiri corespunzătoare au făcut ca astăzi conacul să fie într-o stare deplorabilă. Cu toate acestea, chiar și în ruină, păstrează splendoarea sa și amintește de bogăția și luxul de odinioară.<br>' +
                '<br>Istoria conacului Pommer și a Parcului Țaul este plină de evenimente interesante și legende. Se spune că în aceste ziduri au fost găzduite personalități celebre ale vremii, iar în parc se plimbau perechi de îndrăgostiți. Astăzi, Parcul Țaul nu este doar un monument istoric, ci un loc în care te poți cufunda în atmosfera trecutului și te poți bucura de frumusețea naturii.',
            item6Article4: 'Cei care vizitează Parcul Țaul și Conacul Pommer vor descoperi o natură unică și un patrimoniu istoric. De asemenea, iubitorii de natură vor fi încântați de odihna în acest loc unic – Parcul Țaul este locul ideal pentru plimbări, picnicuri și evadare din agitația orașului.<br>' +
                '<br>Parcul Țaul este una dintre bijuteriile Moldovei care necesită grija noastră. Fiecare dintre noi poate contribui la păstrarea acestui colț unic de natură. Să ne îngrijim împreună de Parcul Țaul și Conacul Pommer pentru a le păstra pentru generațiile viitoare.',

            libraryTitleItem7: 'Biserica de Lemn Sfântul Nicolae',
            libraryTitleDescription7: 'Din Brăicău',
            item7DescriptionText: 'Ascunsă într-un colț liniștit al satului Brăicău din raionul Dondușeni, Biserica de lemn Sfântul Nicolae nu este doar o construcție religioasă, ci un adevărat tezaur al arhitecturii moldovenești. Construită în 1829, aceasta este un monument de importanță națională și reprezintă un exemplu unic de meșteșug din lemn.',
            item7Article1: 'Această biserică veche combină armonios tradițiile populare și motivele bizantine și este o adevărată mândrie pentru locuitorii locali.<br>' +
                '<br>Biserica este înscrisă în Registrul Monumentelor Protejate de Stat. Din păcate, în ciuda valorii sale istorice, acest monument arhitectural se află într-o stare de degradare. Structurile de lemn au fost deteriorate semnificativ de timp și necesită reparații urgente. Cu toate acestea, locuitorii din zonă nu își pierd speranța și continuă să aibă grijă de moștenirea lor spirituală.',
            item7Article2: 'O vizită la biserica de lemn din Brăicău nu va lăsa indiferent pe cei pasionați de istorie.<br>' +
                '<br>Dacă doriți să vă scufundați în atmosfera secolului al XIX-lea, să atingeți istoria și să simțiți spiritul acelei vremi, vizitați neapărat satul Brăicău.' +
                '<br>Vizitând Biserica Sfântul Nicolae, nu doar că veți descoperi un monument arhitectural unic, ci veți contribui și la conservarea acestuia. Fiecare vizitator este un pas către salvarea acestei comori neprețuite a Moldovei.',

            libraryTitleItem8: 'Cetatea Bender',
            libraryTitleDescription8: 'Straja de pe Nistru',
            item8DescriptionText: 'Cetatea Bender este unul dintre cele mai importante monumente istorice ale Moldovei, care întruchipează istoria bogată și zbuciumată a acestei regiuni. Situată pe malul drept al râului Nistru, cetatea a îndurat numeroase asedii, bătălii și reconstrucții.',
            item8Article1:
                'Informațiile despre primele fortificații de pe locul actualei cetăți diferă. Conform celei mai răspândite, deși nedovedite versiuni, prima cetate de aici a apărut încă în secolul al XIII-lea. Genovezii, care erau negustori activi în acea perioadă, au construit aici o mică fortificație pentru a controla rutele comerciale de-a lungul Nistrului. Ulterior, cetatea a fost reconstruită, întărită și extinsă de domnitorii moldoveni și otomani.',
            item8Article2:
                'Totuși, adevărata istorie a Cetății Bender începe în secolul al XVI-lea, când aceste pământuri au intrat sub stăpânirea Imperiului Otoman. Conform unei versiuni științifice bine documentate, în anul 1538, turcii au început construcția unei cetăți puternice, care avea să devină unul dintre punctele lor strategice din regiune. Proiectul cetății a fost realizat de celebrul arhitect turc Sinan. Citadela a fost construită ținând cont de cele mai noi realizări ale ingineriei militare din acea vreme și includea un sistem complex de fortificații cu turnuri, ziduri, șanțuri și pasaje subterane.' +
                '<br><br>De-a lungul istoriei sale îndelungate, Cetatea Bender a trecut de multe ori dintr-o stăpânire în alta. A îndurat numeroase asedii și bătălii, a servit drept refugiu pentru răsculați și loc de exil pentru personalități istorice cunoscute.',
            item8Article3:
                'Cetatea Bender este un monument remarcabil al artei fortificațiilor din secolul al XVI-lea. Arhitectura sa combină elemente ale stilului de fortificație oriental și ale celui vest-european. Zidurile puternice, turnurile și porțile impunătoare creează impresia de neînvins.' +
                '<br><br>Cetatea Bender este adesea comparată cu Cetatea Soroca. În parte, această comparație este justificată, deoarece ambele cetăți au fost construite într-o perioadă istorică similară și aveau funcții asemănătoare. Totuși, Cetatea Soroca, mai compactă și situată pe o colină înaltă, era orientată spre apărarea împotriva raidurilor nomade. În schimb, Cetatea Bender, mai mare și mai complexă ca structură, era destinată controlului unei importante rute comerciale și protecției împotriva operațiunilor militare de amploare.',
            item8Article4:
                '<b>Caracteristicile Cetății Bender:</b>' +
                '<br><ul>' +
                '<li><b>Bastioane</b>: Bastioanele puternice, care ies din linia zidurilor, asigurau foc în flanc împotriva inamicului.</li>' +
                '<li><b>Șanțuri</b>: Șanțurile adânci împiedicau apropierea atacatorilor.</li>' +
                '<li><b>Pasaje subterane</b>: Folosite pentru comunicări secrete, depozitarea proviziilor și evacuare.</li>' +
                '<li><b>Porți</b>: Porțile masive, protejate de turnuri, erau singura intrare în cetate.</li>' +
                '<li><b>Baterii de artilerie</b>: Pe bastioane erau amplasate numeroase tunuri pentru a asigura un bombardament puternic asupra inamicului.</li>' +
                '</ul><br>',
            item8Article5:
                'Cu Cetatea Bender sunt legate numeroase legende și povești. Una dintre cele mai cunoscute vorbește despre pasaje subterane care ar lega cetatea de alte orașe și mănăstiri îndepărtate. O altă legendă afirmă că în zidurile cetății ar fi ascunse comori.',
            item8Article6:
                'Un loc special în istoria cetății îl ocupă baronul Münchhausen. Conform poveștilor, el ar fi realizat una dintre cele mai celebre isprăvi ale sale la Bender – zborul pe un obuz. Deși aceste relatări sunt fanteziste, baronul a participat într-adevăr la războiul ruso-turc din 1735–1739 și, posibil, a vizitat Cetatea Bender. În amintirea acestui fapt, pe teritoriul cetății a fost instalat un monument sub forma unui obuz.' +
                '<br><br>Astăzi, Cetatea Bender este un obiectiv turistic popular. Anual, ea este vizitată de mii de turiști care doresc să atingă istoria și să se cufunde în atmosfera Evului Mediu. Pe teritoriul cetății au loc diverse evenimente culturale, festivaluri și reconstituiri istorice.',
            item8Article7:
                '<b>Ce face Cetatea Bender unică?</b>' +
                '<br><ul>' +
                '<li>Este o mărturie vie a trecutului bogat al regiunii, care combină tradițiile arhitecturale orientale și vest-europene.</li>' +
                '<li>Muzeul de pe teritoriul cetății prezintă exponate care povestesc istoria cetății și a regiunii. Unele dintre acestea, cum ar fi instrumentele de tortură și descrierile modului lor de utilizare, stârnesc teamă și astăzi.</li>' +
                '<li>Atmosfera de Ev Mediu și mister vă învăluie încă de la primii pași pe zidurile vechi.</li>' +
                '</ul>' +
                '<br>Cetatea Bender este adevărata Strajă de pe Nistru, care încă nu își va dezvălui prea curând secretele și misterele urmașilor.',

            libraryTitleItem9: 'Muzeul Sticlei',
            libraryTitleDescription9: 'știați că există așa ceva în Moldova?',
            item9DescriptionText: 'Muzeul Sticlei nu este doar o colecție de recipiente din sticlă, ci o adevărată operă de artă creată de mâna omului. Acest muzeu reprezintă o colecție unică de sticle din întreaga lume, adunată de-a lungul multor ani. Muzeul este situat în satul Tîrnova, lângă orașul Bender.',
            item9Article1: 'Ideea de a crea muzeul a apărut la un colecționar care a petrecut întreaga viață adunând diverse sticle. De-a lungul anilor, colecția a crescut atât de mult încât a fost necesară o clădire separată pentru expunerea sa. Astfel, în anii 2000, a apărut o clădire neobișnuită în formă de sticlă gigantică.<br>' +
                '<br>Clădirea muzeului impresionează prin dimensiunile sale. Înălțimea sa este de 28 de metri și este construită din piatră. În interior, vizitatorii sunt întâmpinați de o combinație de piatră și lemn, care amintește de subsolul unei cetăți medievale. Colecția este găzduită în interiorul clădirii, pe mai multe etaje, fiecare etaj fiind dedicat unei teme specifice. Aici puteți vedea sticle din diferite epoci, țări și materiale.',
            item9Article2: 'Expoziția muzeului impresionează prin diversitatea sa. Sunt expuse sticle de cele mai ciudate forme și dimensiuni: de la flacoane miniaturale până la recipiente mari. Există sticle decorate cu picturi, gravuri, incrustații. Un loc special în colecție îl ocupă sticlele asociate cu evenimente istorice și personalități celebre.<br>' +
                '<br>Pe lângă sticle, muzeul găzduiește diverse obiecte legate de istoria producției de sticlă și băuturi alcoolice. Aici puteți vedea instrumente vechi de fabricare a sticlei, etichete de băuturi, afișe publicitare, dar și pahare, cupe și alte recipiente.',
            item9Article3: 'Poate cel mai interesant este că sticlele expuse nu sunt goale. Așezate pe suporturi speciale și standuri, ele reprezintă o adevărată comoară istorică.',
            item9Article4: 'Muzeul Sticlei este un loc uimitor care nu lasă pe nimeni indiferent. Pe lângă faptul că o astfel de colecție de sticle nu mai există nicăieri în lume, însăși clădirea muzeului este o operă de artă, iar fiecare sticlă are propria sa poveste. De asemenea, pe teritoriul muzeului există o zonă de relaxare unde puteți savura o gustare și privi peisajele minunate.<br>' +
                '<br>În acest muzeu unic puteți nu doar să admirați colecția impresionantă, ci și să aflați multe lucruri interesante despre istoria sticlei și a băuturilor alcoolice.',

            libraryTitleItem10: 'Mănăstirea Călărașovca',
            libraryTitleDescription10: 'Mănăstirea Adormirea Maicii Domnului: istorie, natură și spiritualitate',
            item10DescriptionText: 'Situată în pitoreasca vale a râului Nistru, mănăstirea atrage pelerini și turiști din întreaga lume prin istoria sa veche, arhitectura și atmosfera unică. Mănăstirea se află la 220 km de Chișinău, iar drumul până acolo traversează locuri spectaculoase, ceea ce face călătoria și mai plăcută.',
            item10Article1: 'Data exactă a fondării mănăstirii este necunoscută. Conform legendei, într-o peșteră situată vizavi de actuala mănăstire a trăit un călugăr schimnic, care a fondat schitul monahal. Se presupune că la sfârșitul secolului XVI — începutul secolului XVII a fost construită aici prima biserică de lemn.',
            item10Article2: 'În anul 1780, pe teritoriul mănăstirii au fost construite o biserică de piatră și o clopotniță, pentru a căror construcție a fost nevoie doar de un an. Boierului local, Marco-Donici, care a finanțat edificarea acestora, i-a fost acordat titlul de administrator și stareț pe viață al mănăstirii. <br>' +
                '<br>Mănăstirea a traversat perioade de înflorire și declin, legate de evenimentele istorice din Moldova. În perioada sovietică, mănăstirea a fost închisă, icoanele au fost confiscate, iar clădirile au fost folosite pentru diverse instituții. În 1991, mănăstirea a fost redeschisă și, de atunci, este restaurată în mod activ.',
            item10Article3: 'Mănăstirea Călărașovca este situată într-un colț de natură spectaculos, înconjurat de stânci și păduri. <br>' +
                '<br>Pe teritoriul mănăstirii se află:' +
                '<ul>' +
                '<li>Biserica Adormirea Maicii Domnului, construită în secolul XVIII.</li>' +
                '<li>Biserica Sfântului Mitrofan: o construcție ulterioară, cu forme delicate și picturi vii.</li>' +
                '<li>Chiliile monahale.</li>' +
                '<li>Chilia săpată în stâncă sau peștera pustnicului.</li>' +
                '<li>Trapeza.</li>' +
                '<li>Casa Arhierească.</li>' +
                '<li>Lacul.</li>' +
                '<li>Biblioteca mănăstirii.</li>' +
                '<li>Clopotnița cu clopotele care îi cheamă pe credincioși la slujbă.</li>' +
                '</ul>' +
                '<br>Mănăstirea este înconjurată de stânci care formează chei pitorești. De pe vârfurile stâncilor se deschid priveliști uimitoare asupra văii Nistrului. În împrejurimile mănăstirii se află numeroase izvoare cu apă cristalină, înconjurate, la rândul lor, de legende.',
            item10Article4: 'Legenda spune că cele trei izvoare din apropierea mănăstirii au apărut acolo unde un înger, cu lumânări aprinse, le arăta călugărilor calea. Astăzi, către aceste izvoare sfinte duce o potecă îngrijită prin pădurea rezervației „Călărașovca”. Călătoria pe această potecă nu este doar o ocazie de a-ți potoli setea cu apa considerată tămăduitoare, dar și de a te bucura de peisajele pitorești: pădurea deasă, colina înaltă și cheile adânci.<br>' +
                '<br>Astăzi, Mănăstirea Călărașovca este unul dintre cele mai importante centre spirituale din Moldova. Aici se desfășoară slujbe religioase, pelerinaje și diverse evenimente culturale. Călugării desfășoară o activitate socială activă, ajutând persoanele nevoiașe.<br>'+
                '<br>Planificând timpul liber, includeți neapărat în itinerarul dumneavoastră o vizită la Mănăstirea Călărașovca.',

            libraryTitleItem11: 'Orfeu și Ștefan cel Mare',
            libraryTitleDescription11: 'Legendele mănăstirii Țipova',
            item11DescriptionText: 'Situată pe stânci abrupte, mănăstirea Țipova oferă vizitatorilor o priveliște impresionantă asupra văii Nistrului. Complexul este unul dintre cele mai impresionante mănăstiri săpate în stâncă, nu doar din Moldova, ci și din întreaga Europă, iar istoria sa începe cu mult înainte de formarea Principatului Moldovei. Conform legendelor, primele chilii au fost săpate în stânci încă în secolele X-XII, pentru a proteja călugării de invaziile nomazilor veniți din stepele ucrainene.',
            item11Article1:'Meșterii care au creat această minune au folosit relieful natural, ceea ce a permis transformarea mănăstirii într-o cetate de necucerit. Chiar și astăzi, accesul la chilii nu este deloc ușor – vizitatorii trebuie să coboare pe trepte săpate în piatră, de diferite înălțimi. Cei care nu se opresc la jumătatea drumului și decid să exploreze mai mult decât peisajele Nistrului pot încerca să treacă printr-o crăpătură de patru metri în stâncă, care le oferea călugărilor protecție suplimentară. <br>' +
                '<br>Structura pe mai multe niveluri a mănăstirii acoperă un teritoriu vast. Și astăzi, la Țipova pot fi descoperite numeroase chilii.',
            item11Article2: 'Sfântul lăcaș este situat în stânci cu înălțimi de până la 100 de metri și include trei complexe monahale:  <br>' +
                '<ul>' +
                '<li>Primul complex constă din mai multe chilii și o biserică dedicată Înălțării Sfintei Cruci, datând din secolele XI-XII. </li>' +
                '<li>Biserica Sfântului Nicolae a fost centrul celui de-al doilea complex monahal, fondat în secolul al XIV-lea.</li>' +
                '<li>Cele mai multe chilii se află în jurul bisericii Adormirii Maicii Domnului, datând din secolele XVI-XVIII.</li>' +
                '</ul>' +
                '<br>La începutul secolului al XIX-lea, acest complex continua să funcționeze ca schit, iar în 1842 a fost desființat. În 1828, pe colina deasupra complexelor stâncoase, a fost construită o biserică a schitului, care a devenit din nou mănăstire în 1919. Până atunci, schitul se afla sub administrarea mănăstirii Saharna.<br>' +
                '<br>În perioada sovietică, toți călugării au fost alungați, iar clădirile de pe colină au trecut în administrarea colhozului. Biserica a fost transformată într-un depozit pentru tutun. Jafurile erau frecvente, iar chiliile din stâncă au fost distruse. În 1988, chiliile au fost restaurate și transformate în muzeu.',
            item11Article3: 'Mănăstirea Țipova impresionează prin complexitatea sa. Spațiile sunt conectate prin scări și galerii interioare, formând un adevărat labirint. <br>' +
                '<br>Mănăstirea este înconjurată de multe legende. Conform uneia dintre ele, aici s-a cununat domnitorul moldovean Ștefan cel Mare cu Maria Voichița. O altă legendă spune că aici a murit și a fost îngropat, într-o nișă a mănăstirii, poetul grec antic Orfeu. ',
            item11Article4: 'Împrejurimile mănăstirii fac parte din rezervația naturală Țipova, un adevărat paradis pentru iubitorii de natură. Peisajul local impresionează prin frumusețea sa: apele liniștite ale Nistrului, stâncile abrupte cu sute de peșteri și grote, cascade și trepte naturale.<br>' +
                '<br>Dacă doriți să vă cufundați în atmosfera trecutului, să atingeți rădăcinile creștinismului din Moldova și să vă bucurați de peisaje pitorești, mănăstirea Țipova este un loc care merită vizitat. Totuși, ca și în cazul altor obiective turistice din Moldova, este bine să vă pregătiți înainte de călătorie – să alegeți îmbrăcăminte și încălțăminte adecvată pentru o excursie pe teren accidentat.<br>'+
                '<br>La Țipova, istoria se împletește cu legendele, iar natura uimește prin frumusețea sa. Vizitați și convingeți-vă singuri! Cine știe, poate veți descoperi și voi taina lui Orfeu...',

            libraryTitleItem12: 'Mănăstirea Saharna',
            libraryTitleDescription12: 'Bastionul spiritualității în inima Moldovei',
            item12DescriptionText: 'Mănăstirea Saharna nu este doar o construcție religioasă, ci un complex istoric și cultural unic, situat într-un defileu pitoresc al râului Nistru, în satul Saharna. Această mănăstire, care îmbină biserici săpate în stâncă cu clădiri moderne, este unul dintre cele mai importante centre de pelerinaj din țară.',
            item12Article1:'Data exactă a fondării mănăstirii este necunoscută, însă primele mențiuni despre ea datează încă din secolul al XV-lea. Se crede că primii călugări se ascundeau în schitul săpat în stâncă de atacurile inamicilor. Ei au săpat chilii și biserici în stânci, creând un lăcaș monahal unic.<br>' +
                '<br>Mănăstirea a cunoscut atât perioade de înflorire, cât și de declin. În secolele XVII-XVIII, mănăstirea a atins apogeul. Au fost construite un mare lăcaș din piatră și numeroase clădiri gospodărești. La începutul secolului al XX-lea, aceasta era un centru spiritual al Basarabiei, dar în 1964 a fost închisă și transformată într-un spital psihiatric. Renașterea mănăstirii a început în 1991. Astăzi, aici locuiesc aproximativ 20 de călugări.',
            item12Article2: 'Complexul mănăstirii Saharna reprezintă o combinație unică de elemente naturale și construite de om. Principala atracție este mănăstirea săpată în stâncă. Aici s-au păstrat biserici, chilii monahale și tuneluri subterane.<br>' +
                '<br>Pe lângă mănăstirea din stâncă, în cadrul complexului se află:' +
                '<ul>' +
                '<li>Biserica de piatră,</li>' +
                '<li>O capelă,</li>' +
                '<li>Clădiri de locuit,</li>' +
                '<li>Construcții gospodărești.</li>' +
                '</ul>' +
                '<br>Mănăstirea Saharna este o mănăstire activă pentru bărbați. Călugării duc o viață austeră, dedicându-se rugăciunii și muncii. În mănăstire au loc slujbe religioase regulate și tururi de pelerinaj.<br>',
            item12Article3: 'Una dintre principalele relicve ale mănăstirii sunt moaștele cuviosului Macarie. Mii de pelerini vin anual să se roage și să ceară ajutor.<br>' +
                '<br>Pe teritoriul complexului domnește liniștea și pacea – stâncile, pădurea și mănăstirea par să te invite să te desprinzi de agitația lumii și să te dedici introspecției. Totodată, vizita mănăstirii va fi pe plac și iubitorilor de turism – pentru a ajunge la anumite locații, trebuie să parcurgi trasee pe jos și să depășești câteva obstacole (ceea ce este de așteptat pentru o mănăstire săpată în stâncă). Răsplata va fi pe măsură – o cascadă pitorească și peisaje spectaculoase care îți taie respirația.' +
                '<br>Un bonus plăcut este un păun alb frumos, dar puțin capricios, care locuiește pe teritoriul mănăstirii.<br>' +
                '<br>Mănăstirea Saharna este locul ideal pentru a evada din agitația orașului și pentru a găsi armonia interioară.',

            libraryTitleItem13: 'Orheiul Vechi',
            libraryTitleDescription13: 'Călătorie în timp',
            item13DescriptionText: 'Orheiul Vechi este o întruchipare vie a istoriei Moldovei, un loc unde fiecare stâncă păstrează propriile taine, iar fiecare piatră este martoră a evenimentelor din secolele trecute. Situat în pitoreasca vale a râului Răut, acest complex natural și cultural oferă o călătorie în timp, de la geți-daci până în Evul Mediu.',
            item13Article1:'Una dintre principalele atracții ale Orheiului Vechi este mănăstirea săpată în stâncă. Acest schit, cu biserica sa frumoasă și chiliile sculptate în piatră, este un simbol al spiritualității și tradiției religioase.<br>' +
                '<br>Drumul către monumentele arheologice și religioase este destul de lung, dar nu foarte dificil. Totuși, ceea ce impresionează imediat la intrarea în vale este panorama deschisă. Valea înconjurată de dealuri, râul în formă de potcoavă... atât într-o zi însorită, cât și într-una mohorâtă, această priveliște rămâne întipărită în memorie pentru totdeauna.' +
                'Dacă decideți să faceți o excursie de mai multe kilometri prin Orheiul Vechi, pregătiți încălțăminte confortabilă, suficient spațiu în telefoane sau aparate foto și mult entuziasm – veți pătrunde într-o lume uimitoare a unui sit inclus în Patrimoniul Mondial UNESCO.',
            item13Article2: 'Primele așezări de pe teritoriul Orheiului Vechi au apărut înainte de Hristos – cele mai vechi descoperiri arheologice datează din primul mileniu î.Hr. Totuși, orașul a atins apogeul în perioada existenței statului medieval moldovenesc. Aici se aflau palatul domnesc, numeroase ateliere meșteșugărești, băi publice și locuințe ale orășenilor.<br>' +
                '<br>Despre Orheiul Vechi s-au născut multe legende. Una dintre cele mai cunoscute povestește că în aceste peșteri erau ascunse comorile legendarului domnitor Ștefan cel Mare. O altă legendă spune că aici ar fi fost întemnițat eroul mitologic Orfeu. Desigur, aceste istorii nu au o confirmare istorică, dar adaugă locului o atmosferă specială de mister și romantism.',
            item13Article3: 'Ansamblul arhitectural al Orheiului Vechi reprezintă o combinație unică de elemente naturale și create de om. Principalele atracții sunt:<br>' +
                '<ul>' +
                '<li><b>Fortăreața geto-dacică:</b> Cea mai veche construcție din complex, datată în secolele VII–V î.Hr. Fortăreața a fost construită de daci, un vechi trib trac care locuia aceste meleaguri.</li>' +
                '<li><b>Mănăstirea în stâncă:</b> Săpată în stânci, mănăstirea a fost fondată în secolul al XV-lea. Chiliile, biserica și tunelurile subterane impresionează și astăzi prin dimensiuni și măiestrie.</li>' +
                '<li><b>Biserica și mănăstirea Nașterii Maicii Domnului:</b> Fondată în secolele XV–XVI, această mănăstire este strâns legată de vechiul schit "Peștera". După o perioadă de declin în secolele XVIII–XIX, mănăstirea a fost restaurată la începutul secolului XX. În perioada sovietică, aceasta și schitul au fost transformate în muzeu în aer liber, iar valorile au fost evacuate, inclusiv clopotele. Restaurarea mănăstirii a început în anii \'90.</li>' +
                '<li><b>Construcții specifice orașelor Hoardei de Aur:</b> Karavansarai, băi publice, moschei, mausolee – toate descoperite de arheologi. Din acea epocă s-au păstrat ruinele unei băi dotate cu săli pentru băi, odihnă, masaj și încălzire.</li>' +
                '</ul>',
            item13Article4: 'Astăzi, Orheiul Vechi este o destinație turistică populară. Aici au loc diverse evenimente culturale, festivaluri și conferințe. În satul Butuceni, care face parte din complexul Orheiului Vechi, se află un muzeu și o scenă pe care se desfășoară festivalul unic "Opera în Butuceni". De asemenea, aici vă așteaptă o Vilă Etno care vă va transporta și mai adânc în atmosfera acestui loc unic.<br>' +
                '<br>Cât despre istorie și arheologie, cercetătorii continuă să studieze acest obiectiv istoric fascinant, descoperind mereu noi secrete ale trecutului.<br>'+
                '<br><br><br><b>De ce să vizitați Orheiul Vechi?</b> <br>' +
                '<ul>' +
                '<li>Orheiul Vechi este un muzeu viu unde puteți simți istoria Moldovei.</li>' +
                '<li>Peisajele pitorești ale văilor râurilor Răut și Cogâlnic creează o atmosferă inconfundabilă.</li>' +
                '<li>Aici veți găsi liniște și intimitate.</li>' +
                '<li>Mănăstirea în stâncă, fortăreața geto-dacică și alte construcții impresionează prin vechimea și frumusețea lor.</li>' +
                '</ul>' +
                'Orheiul Vechi este un loc pe care trebuie neapărat să-l vizitați pentru a descoperi istoria și cultura Moldovei.',

            libraryTitleItem14: 'Mănăstirea Sfânta Treime din Rudi',
            libraryTitleDescription14: 'Istorie, spiritualitate și măreția pietrei',
            item14DescriptionText: 'În pitoreasca vale a râului Nistru, la 200 de kilometri la nord de Chișinău, se află satul Rudi. Aici, printre coline și păduri, strălucește ca o piatră prețioasă Mănăstirea Sfânta Treime – inima spirituală a acestor locuri, fondată în îndepărtatul an 1777.',
            item14Article1:
                'Această mănăstire, una dintre cele mai vechi din Moldova, atrage pelerini și turiști în căutarea liniștii și împăcării sufletești. Arhitectura sa, realizată în stilul moldovenesc vechi, impresionează prin simplitatea și eleganța sa. Piatra din care sunt construite zidurile păstrează căldura secolelor și energia generațiilor de călugări care au viețuit aici.',
            item14Article2:
                'În mod special, se evidențiază Biserica Sfintei Treimi – inima complexului monahal. Pereții săi, împodobiți cu fresce și icoane unice, spun povestea credinței și devotamentului. Aici, sub bolțile bisericii, domnește o atmosferă aparte de har și pace.<br>' +
                '<br>Mănăstirea a trecut prin multe încercări de-a lungul timpului. Au fost perioade de înflorire și declin, când a fost închisă și folosită în scopuri diferite. În perioada sovietică, aici au funcționat o casă de copii și un sanatoriu. Însă, în ciuda tuturor încercărilor, mănăstirea a renăscut asemenea pasării Phoenix din cenușă. Și astăzi, la fel ca altădată, oamenii vin aici să se roage, să găsească alinare și să-și recapete echilibrul sufletesc.',
            item14Article3:
                'Măicuțele care trăiesc acum în mănăstire îngrijesc cu dragoste și grijă teritoriul acesteia. Aici este mereu curat, florile bucură privirea cu frumusețea lor, iar liniștea și pacea îndeamnă la rugăciune și reflecție.<br>' +
                '<br>Vizita la Mănăstirea Sfânta Treime din Rudi nu este doar o excursie, ci un adevărat pelerinaj către rădăcinile credinței și spiritualității. Aici poți atinge istoria, simți legătura dintre timpuri și găsi speranța într-un viitor mai bun.',
            item14Article4:
                'Dacă doriți să vă bucurați de liniștea naturii, să vă regăsiți pacea sufletească și să scăpați de agitația urbană, Mănăstirea Sfânta Treime din Rudi este locul potrivit.<br>' +
                '<br>Dacă planificați o excursie la Rudi și doriți să evitați aglomerația, este mai bine să mergeți într-o zi lucrătoare – în weekenduri și, mai ales, în zilele de sărbători religioase, mănăstirea este vizitată de numeroși pelerini. De asemenea, nu uitați de specificul reliefului – dacă vreți să urcați la izvor, să faceți o plimbare prin pădure sau să ajungeți la „Farfuria turcului”, alegeți haine și încălțăminte potrivită pentru traseele deluroase.',
            item14Article5: 'Merită să vizitați Rudi? Fără îndoială, da. Aici, fiecare va găsi ceva pentru sine: pasionații de istorie pot descoperi artefacte străvechi, iubitorii de natură se pot bucura de peisaje pitorești, iar pelerinii își pot găsi liniștea sufletească între zidurile mănăstirii.',

            libraryTitleItem15: 'Arcul Geodezic Struve',
            libraryTitleDescription15: 'Cum un cub moldovenesc păstrează secretul Pământului',
            item15DescriptionText: 'În nordul Moldovei, nu departe de satul Rudi, ascuns de privirile curioșilor, se află un cub de piatră. La prima vedere, pare nesemnificativ, însă acesta este un martor tăcut al unui proiect științific grandios – Arcul Geodezic Struve.',
            item15Article1:
                'Acum două sute de ani, când știința abia începea să exploreze misterele planetei noastre, astronomul rus Friedrich Georg Wilhelm Struve și-a propus o sarcină ambițioasă: să măsoare un arc de meridian pentru a dezvălui forma și dimensiunile Pământului.<br>' +
                '<br>Timp de treizeci și cinci de ani, între 1816 și 1852, „geodezii a trei popoare” – ruși, norvegieni și suedezi – au lucrat la crearea unui arc geodezic unic, care s-a întins pe o distanță de două mii opt sute douăzeci de kilometri, din regiunile arctice până la țărmul Mării Negre.',
            item15Article2:
                'Două sute șaizeci și cinci de puncte, marcate cu cuburi de piatră, asemenea celui de lângă Rudi, au fost instalate pe teritoriul a zece state moderne. Fiecare dintre acestea a fost o piesă dintr-un puzzle științific uriaș, menit să descifreze secretele Pământului.<br>' +
                '<br>Arcul Geodezic Struve nu este doar o realizare științifică excepțională, ci și un simbol al cooperării internaționale și al dorinței neobosite de cunoaștere. Rezultatele acestei lucrări au stat la baza creării hărților și cercetărilor geografice pentru multe decenii.<br>' +
                '<br>În secolul XXI, chiar dacă tehnologiile satelitare au înlocuit cuburile de piatră, Arcul Struve rămâne la fel de important. Este considerat și astăzi unul dintre cele mai semnificative evenimente din istoria astronomiei, geodeziei și geografiei mondiale.',
            item15Article3:
                'În 2005, Comitetul Patrimoniului Mondial UNESCO a inclus Arcul Geodezic Struve în lista patrimoniului cultural cu „valoare universală excepțională”. Este singurul obiectiv geodezic și unicul monument intelectual pur recunoscut de UNESCO.<br>' +
                '<br>Cubul de piatră de lângă satul Rudi nu este doar un punct pe hartă, ci un simbol al realizărilor științifice și al dorinței omenirii de a cunoaște lumea. El ne amintește că știința nu cunoaște granițe, iar aspirația către adevăr unește oameni din națiuni și culturi diferite.',

            libraryTitleItem16: 'Complexul turistic „Epoca de Piatră”',
            libraryTitleDescription16: 'Complexul turistic „Epoca de Piatră” al vinăriei Brănești – o călătorie în vremurile preistorice',
            item16DescriptionText: 'Pereți și podele din piatră, desene ale mamuților pe pereți, tone de piatră deasupra capului, mobilier din lemn, piei de animale și foc viu... Aceasta nu este descrierea unei locuințe primitive, ci un complex turistic unic – „Epoca de Piatră”, situat în inima Republicii Moldova, la vinăria Brănești.',
            item16Article1:
                'Complexul îmbină tradițiile vinicole ale Moldovei cu atmosfera misterioasă a peșterilor subterane. Este sculptat în calcar, la o adâncime de peste 60 de metri, și se întinde pe o rețea de galerii subterane cu o lungime totală de 70 km.<br>' +
                '<br>Aici, vizitatorii pot vedea sticle prăfuite de vin, păstrate în nișe rotunde săpate direct în stâncă, iar picturile rupestre de pe pereți creează o adevărată atmosferă preistorică.',
            item16Article2:
                '„Epoca de Piatră” nu este doar o vinărie, ci o destinație turistică unică, unde oaspeții pot:<br>' +
                '<ul>' +
                '<li>Degusta vinuri direct în galeriile subterane, bucurându-se de preparate tradiționale moldovenești.</li>' +
                '<li>Explora decorurile impresionante în stil preistoric, care transformă galeriile într-un adevărat muzeu subteran.</li>' +
                '<li>Se caza într-un hotel neobișnuit, unde camerele sunt amenajate în stilul locuințelor tradiționale moldovenești.</li>' +
                '<li>Participa la festivaluri și evenimente, organizate chiar în interiorul complexului, combinând muzica, gastronomia și cultura vinului. </li>' +
                '</ul>',
            item16Article3:
                'Dacă sunteți un iubitor de vin, un pasionat de istorie sau un călător în căutare de noi experiențe, complexul „Epoca de Piatră” vă invită într-o lume unde natura și tradițiile vinicole se îmbină armonios.<br>' +
                '<br>Veniți să descoperiți adevăratul gust al Moldovei și să vă cufundați în atmosfera unui loc unic în țară!',

            libraryTitleItem17: 'Château Vartely',
            item17DescriptionText: 'Una dintre cele mai renumite vinării din Moldova, unde tradițiile vinificației se îmbină armonios cu tehnologiile moderne.',
            item17Article1:
                'Situată în apropiere de Orhei, atrage vizitatori prin vinurile sale de înaltă calitate, ospitalitate și un complex turistic unic. Château Vartely folosește soiuri de struguri cultivate în regiunile viticole celebre ale Moldovei – Codru și zona de sud a țării. Aici se produc atât vinuri clasice europene, cât și soiuri autentice moldovenești, precum Feteasca Albă, Feteasca Neagră și Rară Neagră, care cuceresc inimile degustătorilor din întreaga lume.<br>' +
                '<br>Cu toate acestea, Château Vartely nu este doar o vinărie, ci și un adevărat centru cultural. În muzeul vinului, pe pereți, se pot vedea foi de scoarță de stejar de plută și tuburi lungi de plută – elemente care ulterior devin dopurile de vin cu care suntem cu toții obișnuiți.',
            item17Article2:
                'Aici sunt prezentate vinuri din diferite țări ale lumii, iar vizitatorilor li se arată mostre de diferite tipuri de sol, pe care sunt cultivați strugurii, pentru a înțelege influența terroir-ului asupra gustului vinului. Colecția vinăriei numără un număr impresionant de soiuri și sticle de vin. În secțiile de producție, pot fi văzute cisterne înalte, în care are loc fermentarea vinului.',
            item17Article3:
                'Unul dintre punctele de atracție ale vizitei la Château Vartely este spectacolul de lumini realizat prin tehnologia video mapping. Imagini animate spectaculoase apar pe butoaiele de vin depozitate în pivniță. Chiar dacă nu sunteți un mare iubitor de vin, acest spectacol vizual nu vă va lăsa indiferent.<br>' +
                '<br>Château Vartely oferă oaspeților săi nu doar o incursiune în lumea vinificației, ci și o experiență de relaxare completă. Vizitatorii pot observa întregul proces de producție, degusta vinuri și se pot caza în vile decorate în stilul celor mai renumite regiuni viticole din lume.',
            item17Article4:
                'Château Vartely nu este doar o vinărie, ci un centru cultural care îmbină istoria, arta vinului și ospitalitatea moldovenească. Vinurile produse aici sunt exportate în peste 30 de țări și reprezintă cartea de vizită a Moldovei pe scena internațională.',

            libraryTitleItem18: 'Muzeul Satului',
            libraryTitleDescription18: 'O călătorie în inima satului moldovenesc',
            item18DescriptionText: 'Imaginați-vă o călătorie în timp, în inima satului moldovenesc. Exact asta vă așteaptă la Muzeul Satului din Chișinău. Nu este doar un muzeu, ci un colț viu de istorie, unde puteți atinge trecutul și simți cum trăiau strămoșii noștri.',
            item18Article1:
                'Plimbându-vă prin muzeu, veți ajunge într-un sat moldovenesc din secolul al XVII-lea. Iată o casă tradițională moldovenească, cu uși sculptate, fundație din piatră și scări solide. Aici găsiți obiecte de uz casnic: vase de lut, războaie de țesut, furci vechi pentru tors și unelte agricole. Și, bineînțeles, fântâna cu cumpănă și apa limpede de băut.<br>' +
                '<br>Atmosfera autentică este accentuată de un clopot greu din cupru, care se leagănă ușor în bătaia vântului.<br>' +
                '<br>Fiecare exponat este o parte a sufletului poporului moldovenesc, a hărniciei și tradițiilor sale.',
            item18Article2:
                'Pe o alee pietruită veți ajunge la bijuteria muzeului – Biserica de lemn a Adormirii Maicii Domnului, adusă din satul Hirișeni. Această biserică, construită în anul 1642, este unul dintre cele mai vechi monumente arhitecturale din Moldova. Având o înălțime de 27 de metri, este una dintre cele mai înalte biserici de lemn din țară, impresionând prin frumusețea și eleganța sa.<br>' +
                '<br>Muzeul Satului nu este doar un loc pentru excursii, ci și un loc unde sufletul se poate odihni, într-o atmosferă de liniște și armonie.',
            item18Article3:
                '<h4>De ce merită să vizitați Muzeul Satului?</h4><br>' +
                '<ul>' +
                '<li>Pentru a vă cufunda în atmosfera unui sat moldovenesc autentic.</li>' +
                '<li>Pentru a vedea exponate unice ce ilustrează istoria și cultura Moldovei.</li>' +
                '<li>Pentru a admira o biserică veche din lemn.</li>' +
                '<li>Pentru a face fotografii frumoase de neuitat.</li>' +
                '<li>Pur și simplu, pentru a vă relaxa și bucura de liniște.</li>' +
                '</ul>' +
                'Muzeul Satului este situat aproape de Porțile Orașului, la intrarea în capitala Moldovei. Este locul ideal pentru cei care vor să afle mai multe despre trecutul Moldovei și al poporului său.',

            notFound: 'NU GĂSIT :(',
        },
        ua: {
            menuItemHome: 'Головна',
            menuItemLibrary: 'Бібліотека',
            menuItemForum: 'Форум',
            aboutUs: 'Про нас',
            aboutUsText1:
                '"Єдність у Культурному Різноманітті" - проект, призначений для збереження місць культурної спадщини Молдови на перетині культур трьох країн-сусідів Україна-Молдова-Румунія. Наше завдання - створити електронну бібліотеку місць культурної спадщини для їх популяризації, щоб уникнути руйнування і забуття деяких з них.',
            aboutUsText2:
                'Крім цього, в рамках проекту проводяться сезонні тематичні табори, завершенням яких є відтворення за канонами святкування сезонних свят з відтворенням традицій та звичаїв на перетині трьох культур.',
            sponsors: 'Ідейні натхненники',
            sponsorsDescription: 'Цей проект існує завдяки нашим ідеям, які ми змогли втілити за підтримки наших спонсорів.',
            footerPartners: 'Співробітники',
            footerSupport: 'При підтримці фондів',
            forumAboutUs: 'Про Нас',
            forumAboutUsText1: 'Проект "Єдність у Культурному Різноманітті" спрямований на збереження місць культурної спадщини різних етнічних груп на території Молдови через їх популяризацію в мережі Інтернет, щоб уникнути руйнування і забуття.',
            forumAboutUsText2: 'Завдання нашої команди - виїжджати на об\'єкти культурної спадщини, робити фото, відео, розповідати про них Вам. Ми впевнені, що ця електронна бібліотека стане для Вас чудовим путівником по Молдові та її культурному різноманіттю.',
            forumFormTitle: 'Форум',
            forumFormText1: 'Наша країна дуже багата місцями культурної спадщини, а наша мета - розповісти про ці місця, підвищити інтерес, щоб уникнути їх руйнування. Цей форум створений для того, щоб кожен охочий міг запропонувати об\'єкт (або проголосувати за улюблений) культурної спадщини, який нам варто відвідати та описати.',
            forumFormText2: 'Тут Ви можете надіслати нам листа на пошту та інші соцмережі або приєднатися до форуму в Telegram.',
            formLabelName: 'Введіть ім\'я та прізвище',
            formLabelEmail: 'Введіть e-mail',
            formLabelPhone: 'Введіть номер телефону',
            formLabelMessage: 'Введіть ваше повідомлення',
            formTelegramButton: 'Форум у Телеграм',
            formSendButton: 'Відправити',
            libraryTitleItem1: '"Ivana Kupala Camp"',
            libraryTitleDescription: 'Злиття культури народу на межі трьох етнічних впливів',
            item1DescriptionText: 'На початку липня на березі мальовничого озера в прикордонному регіоні Україна-Молдова-Румунія, в місті Дрокія відбувся триденний захід "Ivana Kupala Camp", присвячений традиційному святкуванню Івана Купала. Це свято має глибоке коріння в культурах усіх трьох країн і є символом єдності та багатства їх культурних традицій.',
            itemArticle1: '<h4>День 1 і 2: Знайомство з культурою і традиціями</h4> ' +
                '<br>Перший і другий дні заходу були присвячені знайомству учасників з культурними особливостями і традиціями святкування Івана Купала в Україні, Молдові та Румунії. <br>' +
                '<br><b>Україна:</b> В Україні Івана Купала (Купальська ніч) святкується як день літнього сонцестояння, символізуючи очищення та відродження. Традиційні елементи включають стрибки через багаття, плетіння вінків з польових квітів та запуск їх по річці для ворожіння на судженого.<br>' +
                '<br><b>Молдова:</b> В Молдові це свято відоме як "Сінзініеле". Воно також пов\'язане з літнім сонцестоянням і включає ритуали очищення вогнем та водою. Особливістю молдавського святкування є танець "Хора", який виконується навколо багаття.<br>' +
                '<br><b>Румунія:</b> В Румунії Сінзініеле – це свято містичних та магічних ритуалів. Вінки з квітів, сплетені на Сінзініеле, мають магічну силу та захищають від злих духів. Ввечері запалюються багаття, навколо яких водяться хороводи.',
            itemArticle2: '<b>Спільні традиції:</b><br>' +
                '<br><ul>' +
                '<li>Плетіння вінків з польових квітів</li>' +
                '<li>Розпалювання багать та стрибки через них</li>' +
                '<li>Танці та співи навколо багаття</li>' +
                '</ul><br>' +
                '<b>Tradiții speciale ale fiecărei țări:</b>' +
                '<br><ul>' +
                '<li>Україна: Запуск вінків по воді для ворожіння</li>' +
                '<li>Молдова: Танець "Хора"</li>' +
                '<li>Румунія: Магічні ритуали з вінками</li>' +
                '</ul><br>' +
                'Під час перших двох днів учасники могли взяти участь у майстер-класах з плетіння вінків з польових квітів та створення оберегів, розписуючи кулони древніми рунами. Це дозволило кожному учаснику зануритися в традиції та культуру своїх сусідів.',
            itemArticle3: '<h4>День 3: Святкування Івана Купала</h4>' +
                '<br>' +
                '<p>Третій день був кульмінацією табору, коли на березі озера розгорнулося саме святкування Івана Купала. Учасники готували майданчик для свята, плели вінки, розмальовували обличчя спеціальними фарбами (для дітей) і створювали три фотозони для запам\'ятовування пам\'ятних моментів.</p>' +
                '<p>На захід були запрошені народні ансамблі, які виконували пісні українською, молдавською та румунською мовами, а також влаштовували танцювальні вистави з елементами народних танців.</p>',
            itemArticle4: '<p>Була організована виставка рукоділля від народних майстрів, де гості могли побачити та придбати унікальні вироби, створені з любов\'ю та майстерністю.</p>' +
                '<p>Для забезпечення безпеки учасників були призначені спостерігачі, а міська влада виділила два екіпажі правоохоронних органів. Безпеку на воді забезпечувала місцева команда рятувальників.</p>' +
                '<p>Багато учасників поділилися своїми враженнями про "Ivana Kupala Camp". Вони відзначили, що захід був надзвичайно захоплюючим і пізнавальним. <i>"Разом з @uicd.md, протягом 3 днів, ми занурювалися в глибоке пізнання традицій, міфів і звичаїв Івана Купала,"</i> – ділиться Теодора. Олександр додав: <i>"Хочу висловити величезну подяку організаторам за незабутнє свято Івана Купала в місті Дрокія. Завдяки праці та турботі з боку організаторів, всі жителі міста змогли насолодитися чудовою атмосферою і традиціями. Цей день залишиться в наших серцях як яскравий і радісний спогад."</i>  Учасники також відзначили, що захід став чудовою нагодою для нових знайомств і зміцнення культурних зв\'язків між країнами.</p>',
            itemArticle5: '<p>"Ivana Kupala Camp" став не просто святом, а справжнім культурним обміном між трьома країнами. Учасники змогли зануритися в багатство традицій, завести нові знайомства та зміцнити культурні зв\'язки. Цей табір показав, наскільки важливо зберігати та розвивати культурну спадщину, об\'єднуючи людей через традиції та звичаї.</p>' +
                '<p>Ми дякуємо всім учасникам та гостям за створення незабутньої атмосфери та чекаємо на вас на наступних заходах!</p>',
            itemArticle6: 'Thanks to the support of the European Heritage Hub pilot Project co-funded by the European Union and supported by the ALIPH Foundation.',
            categoryTitleItem1: 'Всi',
            categoryTitleItem2: 'Святині',
            categoryTitleItem3: 'Фестивалі та ярмарки',
            categoryTitleItem4: 'Музеї та театри',
            categoryTitleItem5: 'Будівлі',
            categoryTitleItem6: 'Фортеці',

            libraryTitleItem2: 'Сороцька фортеця',
            libraryTitleDescription2: 'Перлина молдавської історії',
            item2DescriptionText: 'Сороцька фортеця – один з найвідоміших історичних пам\'яток Молдови. Цитадель, розташована на правому березі Дністра, була зведена за наказом великого молдавського господаря Штефана Великого понад п’ятсот років тому.',
            item2Article1:
                'Побудована в останній чверті XV століття (точна дата завершення будівництва невідома), вона слугувала надійним оплотом у захисті північних кордонів Молдавського князівства від частих набігів кримських татар.<br>' +
                '<br>Форма цитаделі незвичайна для молдавських фортець – кругла фортеця, діаметром близько 37,5 метрів, з п’ятьма вежами. Самі вежі також дивують відвідувачів – не відразу розумієш, що 4 з п’яти веж круглі, а одна (надбрамна) – прямокутної форми.<br>' +
                '<br>Така конструкція була новаторською для свого часу і забезпечувала фортеці відмінну обороноздатність, роблячи її майже неприступною, про що свідчать і легенди, пов’язані з Сороцькою фортецею.',
            item2Article2: '<h4>Реставрація і відродження:</h4>' +
                '<br>Після століть, пережитих фортецею, у 2015 році були завершені масштабні реставраційні роботи. Завдяки їм, пам\'ятник історії та архітектури постане перед відвідувачами у всій своїй колишній величі. Реставратори дбайливо відновили стіни, вежі, а також внутрішні приміщення. Однак процес реставрації на цьому не зупинився. З квітня 2021 року фортеця була майже закрита для відвідувачів і повністю повернула свої позиції туристичного об\'єкта лише у квітні 2024 року. Чим одразу скористалися туристи, заполонивши цитадель.',
            item2Article3: '<h4>Легендарний меч Штефана чел Маре</h4>' +
                '<br>' +
                '<p>У фортеці був створений музей, присвячений історії Молдови та, зокрема, епосі правління Штефана Великого. Однією з головних експозицій музею є копія знаменитого меча молдавського господаря. Цей експонат дозволяє відвідувачам зануритися в атмосферу середньовіччя і уявити собі доблесного воїна, який захищав свою землю.</p>' +
                '<p>Оригінал меча зараз зберігається в Туреччині, а сусідня Румунія веде переговори про повернення реліквії на її історичну батьківщину.</p>',
            item2Article4: 'Сьогодні Сороцька фортеця – це не просто історична пам’ятка, а й популярне туристичне місце. Її відвідування – це унікальна можливість доторкнутися до багатої історичної спадщини Молдови, дізнатися більше про життя наших предків та насолодитися чудовими краєвидами Молдови.',

            libraryTitleItem3: 'Монастир Косеуць',
            item3DescriptionText: 'Монастир Косеуць – це дивовижне місце, де історія та сучасність зливаються в гармонійне ціле.' +
                'Розташований у мальовничій долині річки Дністер, приблизно за 5 кілометрів від села Косеуць, цей монастир приваблює паломників і туристів з усієї країни.',
            item3Article1: '<h4>Історія монастиря</h4>' +
                '<p>Перші згадки про монастир датуються 1729 роком. Саме тоді на місці, яке згодом стало відоме як «ла Прістол», з\'явився невеликий дерев\'яний монастир, присвячений Різдву Богородиці. Його засновниками стали монахи Павло та Гаврило. Монастир проіснував близько 160 років, але в 1833 році був закритий.</p>' +
                '<p>У ХХ столітті на місці старого монастиря майже нічого не залишилося через землетруси та зсуви ґрунту. Збереглася лише невелика каплиця, де місцеві жителі та паломники продовжували молитися.</p>',
            item3Article2: '<h4>Відродження духовного життя</h4>' +
                '<br>' +
                '<p>В конце XX века, в 1994 году, по инициативе местного священника Иеронима Палия началось возрождение монастыря. На месте старого монастыря был построен новый храм, посвященный Успению Божьей Матери. Проект храма был разработан по благословению митрополита Молдовы и Буковины Даниила.</p>' +
                '<p>Будівництво монастиря велося в складних умовах, але завдяки самовідданості отця Ієроніма та підтримці прихожан, уже в 1999 році храм було освячено. Поступово навколо храму виріс монастирський комплекс, що включає келії, трапезну та інші господарські споруди.</p>',
            item3Article3: '<h4>Монастир сьогодні</h4>' +
                '<br>' +
                '<p>Сьогодні монастир Косеуць – це не лише місце для молитви, а й популярний туристичний об\'єкт. Паломники та туристи з усієї країни приїжджають сюди, щоб доторкнутися до витоків православної віри, насолодитися тишею та красою природи.</p>' +
                '<p>У монастирі можна відвідати молитви (богослужіння проводяться румунською та російською мовами) та взяти участь у паломницьких турах (у тому числі й іншими святими місцями Молдови).</p>' +
                '<p>Також територія монастиря впорядкована та ідеально підходить для спокійного відпочинку.</p>',
            item3Article4:
                '<p>Ще однією особливістю монастиря є джерело мінеральної води – за кілька хвилин ходьби від монастиря, поруч із місцями для пікніка, знаходиться джерело з цілющою водою.</p>',
            item3Article5:
                'Монастир Косеуць – це живий приклад того, як віра та праця можуть змінити світ. Це місце, де кожен може знайти духовну втіху і відчути зв’язок із вічністю. Монастир відкритий для відвідувачів щодня.',

            libraryTitleItem4: 'Циганська гора',
            item4DescriptionText: 'Сороки відомі не тільки своєю давньою фортецею, але й унікальним районом, який називається Циганська гора. Розташований на височині, цей квартал став справжньою візитною карткою міста завдяки своїм незвичайним будинкам.',
            item4Article1:
                'Історія появи ромів у Сороках оповита легендами. За однією з них, землі на пагорбі були подаровані циганам молдавським господарем Штефаном III на знак подяки за допомогу в озброєнні армії. Так чи інакше, роми живуть у Сороках дуже давно, зберігаючи свої традиції та охороняючи культуру.<br>' +
                '<br>Архітектура будинків на Циганській горі вражає уяву. Це справжній архітектурний калейдоскоп, де поруч знаходяться елементи класицизму, бароко, модерну та навіть східних стилів. Кожен будинок – це витвір мистецтва, прикрашений ліпниною, колонами, вежами та різними декоративними елементами. Тут можна зустріти фігури міфічних істот, релігійні символи, а також копії відомих архітектурних споруд, таких як шпиль Адміралтейства або купол мечеті.' +
                '<br>Однак багато будинків на Циганській горі залишилися недобудованими. Проте навіть незавершені, ці будівлі надають місту особливого шарму та приваблюють багатьох туристів.',
            item4Article2:
                'Циганська гора – це місце, де минуле та сьогодення переплітаються, створюючи неповторну атмосферу. Це місце, яке обов\'язково варто відвідати, щоб на власні очі побачити, як виглядає справжнє диво архітектури. Однак тим, хто вирішить здійснити екскурсію в це місце, не варто забувати про традиції, звичаї та культуру народу ромів, які можуть суттєво відрізнятися від того, що туристи бачили в інших куточках Молдови. ',

            libraryTitleItem5: 'Бекіров Яр',
            item5DescriptionText: 'Бекіров Яр або Бекірова Печера є унікальним історичним і природним пам\'ятником. Висічена в скелі, так звана келія монаха вважається одним із найстаріших релігійних пам\'ятників у регіоні і датується VI-VIII століттями.',
            item5Article1:
                'Монастирський печерний комплекс розташований на висоті 12-14 метрів на стрімкій вапняковій скелі. Печера являє собою невелике приміщення з вівтарем, орієнтованим на північ. Всередині печери є висічені лежанка, стіл, ніші для ікони і лампади, а також погріб.<br>' +
                '<br>Місцеві жителі часто асоціюють назву печери з ім’ям останнього молдавського гайдука Бекіра, який переховувався від переслідувань. Згідно з іншою легендою, у печері жив монах, який кожної неділі виходив до людей і читав проповіді. Проте ці легенди залишаються лише легендами – згідно з дослідженнями Василія Трофеїла, уродженця Сорокського району, Бекір був турецьким купцем, який колись торгував на цій території і намагався розширити свій вплив.' +
                '<br>Хто ж був історичний Бекір, ми точно не знаємо, але пам’ятник, що носить його ім’я, не втрачає своєї привабливості для туристів, навіть незважаючи на складність потрапити до печери.',
            item5Article2:
                'Бекірова печера є не тільки релігійним, але й геологічним пам’ятником. Вона розташована в мальовничому каньйоні, утвореному в результаті ерозії вапнякових порід. Піднятися до неї можна через ліс, неподалік від підйому до Свічі Подяки.' +
                '<br>Стежка, по якій доведеться пройти туристам, мальовнича – зліва тече річка, в якій видніються величезні валуни, що колись упали з вершини пагорба, довкола шумлять дерева, співають птахи і здається, що потрапив у справжній чарівний ліс.',
            item5Article3:
                'Підйом до Бекірової печери починається біля двох монументальних сусідів – величезного тополя зі стовбуром в кілька обхватів і гігантського валуна, вкритого підписами туристів.' +
                '<br>Саме від цих пам\'ятних «друзів» потрібно піднятися по досить крутій стежці на кілька десятків метрів угору до самої печери. Тому, якщо ви вирішите побачити Бекіров Яр і піднятися до печери, краще подбати про зручне взуття, щоб ніщо не відволікало від прогулянки по лісу і ущелині.',
            item5Article4:
                'Незважаючи на свою історичну та культурну цінність, печера довгий час не перебувала під охороною держави. Однак завдяки зусиллям громадськості та місцевої влади, Бекірова печера була офіційно включена до списку охоронюваних пам\'яток Молдови.' +
                '<br>Наразі проводяться роботи з її збереження та реставрації, а вчені продовжують досліджувати печеру, щоб розкрити всі таємниці її минулого.' +
                '<br>Бекірова печера – це унікальне місце, де історія переплітається з природою, пропонуючи туристам незабутні враження і можливість доторкнутися до давнини.',

            libraryTitleItem6: 'Парк Цауль',
            libraryTitleDescription6: 'Загублений рай Молдови',
            item6DescriptionText: 'На півночі Молдови, в селі Цауль Дондюшанського району, приховане справжнє скарб – парк Цауль. Цей унікальний куточок природи, який займає 46,2 гектара, є одним з найбільших та найкрасивіших парків у Молдові. Створений на початку ХХ століття за проєктом відомого ландшафтного архітектора Іполита Владиславського-Падалка, парк є гармонійним поєднанням природної краси та людської творчості.',
            item6Article1: 'Ініціатором створення цього зеленого раю став Андрій Поммер, відомий банкір та меценат. Бажаючи створити відокремлене місце для відпочинку та споглядання, він придбав ці землі та вклав у їх благоустрій душу й кошти. Результатом його зусиль став величний парк, який і сьогодні захоплює своєю красою та різноманітністю.',
            item6Article2: 'Парк Цауль – це справжній ботанічний сад під відкритим небом. Тут зібрані сотні видів рослин з усього світу: від могутніх дубів до екзотичних ліан. Кожна алея, кожен куточок парку – це витвір ландшафтного мистецтва. Особливу атмосферу створюють штучно створені водойми, де плавають граційні лебеді.',
            item6Article3: 'Серцем парку є садиба Поммера – вишукана садиба, побудована на початку ХХ століття. Ця велична будівля, виконана в класичному стилі, вражає своєю елегантністю та масштабністю. На жаль, роки та відсутність належного догляду призвели до того, що садиба сьогодні перебуває в жалюгідному стані. Однак, навіть у руїнах вона зберігає свою велич і нагадує про колишню розкіш та багатство.<br>' +
                '<br>Історія садиби Поммера і парку Цауль сповнена цікавих подій та легенд. Кажуть, що в цих стінах бували відомі особи того часу, а в парку прогулювалися закохані пари. Сьогодні парк Цауль – це не просто історичний пам\'ятник, це місце, де можна зануритися в атмосферу минулого та насолодитися красою природи.',
            item6Article4: 'Тих, хто відвідає Цаульський парк і Садибу Поммера, чекає зустріч із унікальною природою та історичною спадщиною. Також шанувальників природи порадує відпочинок у цьому унікальному місці – Цаульський парк є ідеальним місцем для прогулянок, пікніків та втечі від міської метушні.<br>' +
                '<br>Парк Цауль – це одна з перлин Молдови, яка потребує нашої турботи. Кожен із нас може зробити свій внесок у збереження цього унікального куточка природи. Давайте разом подбаємо про те, щоб парк Цауль і садиба Поммера збереглися для майбутніх поколінь.',

            libraryTitleItem7: 'Дерев\'яна церква',
            libraryTitleDescription7: 'Святого Миколая в Брейкеу',
            item7DescriptionText: 'Загублена в тихому куточку села Брейкеу Дондюшанського району, дерев\'яна церква Святого Миколая – це не просто релігійна споруда, а справжній скарб молдавської архітектури. Збудована у 1829 році, вона є пам’яткою національного значення і представляє унікальний зразок дерев\'яного зодчества.',
            item7Article1: 'Ця старовинна церква гармонійно поєднує народні традиції та візантійські мотиви і є справжньою гордістю місцевих жителів.<br>' +
                '<br>Церква внесена до Реєстру пам\'яток, що охороняються державою. На жаль, незважаючи на свою історичну цінність, пам\'ятка архітектури перебуває у занедбаному стані. Дерев\'яні конструкції значно пошкоджені часом і потребують термінового ремонту. Однак місцеві жителі не втрачають надії та продовжують піклуватися про свою духовну спадщину.',
            item7Article2: 'Відвідання дерев\'яної церкви в Брейкеу не залишить байдужими любителів історії.<br>' +
                '<br>Якщо ви бажаєте зануритися в атмосферу XIX століття, доторкнутися до історії та відчути дух того часу, обов\'язково відвідайте село Брейкеу.' +
                '<br>Відвідавши церкву Святого Миколая, ви не тільки познайомитеся з унікальною архітектурною пам\'яткою, але й зробите свій внесок у її збереження. Адже кожен відвідувач – це крок до порятунку цього безцінного скарбу Молдови.',

            libraryTitleItem8: 'Бендерська фортеця',
            libraryTitleDescription8: 'Страж на Дністрі',
            item8DescriptionText: 'Бендерська фортеця – одна з найважливіших історичних пам’яток Молдови, яка уособлює багату й бурхливу історію цього регіону. Розташована на правому березі річки Дністер, вона пережила багато облог, битв і реконструкцій.',
            item8Article1:
                'Інформація про перші укріплення на місці нинішньої фортеці різниться. За найпоширенішою, хоч і не доведеною версією, перша цитадель на цьому місці з’явилася ще в XIII столітті. Генуезці, які були активними торговцями того часу, побудували тут невелике укріплення для контролю торгових шляхів уздовж Дністра. Пізніше фортецю перебудовували, укріплювали та розширювали молдавські господарі й османи.',
            item8Article2:
                'Однак справжня історія Бендерської фортеці починається з XVI століття, коли ці землі опинилися під владою Османської імперії. За науково обґрунтованою версією, у 1538 році турки почали будівництво потужної фортеці, яка мала стати однією з опорних точок їхньої влади в регіоні. Проєкт фортеці був розроблений знаменитим турецьким архітектором Сінаном. Цитадель була зведена з урахуванням найновіших досягнень військової інженерії того часу й включала складну систему укріплень із вежами, стінами, ровами та підземними ходами.' +
                '<br><br>За свою довгу історію Бендерська фортеця багато разів переходила з рук у руки. Вона пережила численні облоги й битви, слугувала притулком для повстанців і місцем заслання для відомих історичних особистостей.',
            item8Article3:
                'Бендерська фортеця є видатною пам’яткою фортифікаційного мистецтва XVI століття. Її архітектура поєднує елементи східної та західноєвропейської фортифікації. Могутні стіни, вежі та ворота створюють враження неприступності.' +
                '<br><br>Бендерську фортецю часто порівнюють із Сорокською фортецею. Частково це порівняння є вірним, адже обидві цитаделі були побудовані в схожий історичний період і мали схожі функції. Проте Сорокська фортеця, компактніша й розташована на високому пагорбі, була орієнтована на оборону від набігів кочівників. Натомість Бендерська фортеця, більша й складніша за своєю структурою, була призначена для контролю важливого торгового шляху й захисту від масштабних військових дій.',
            item8Article4:
                '<b>Особливості Бендерської фортеці:</b>' +
                '<br><ul>' +
                '<li><b>Бастіони</b>: Потужні бастіони, що виступають за лінію стін, забезпечували фланговий вогонь по ворогу.</li>' +
                '<li><b>Рови</b>: Глибокі рови перешкоджали наближенню нападників.</li>' +
                '<li><b>Підземні ходи</b>: Використовувалися для таємних повідомлень, зберігання припасів і евакуації.</li>' +
                '<li><b>Ворота</b>: Масивні ворота, захищені вежами, були єдиним входом до фортеці.</li>' +
                '<li><b>Артилерійські батареї</b>: На бастіонах було розміщено численні гармати для потужного обстрілу ворога.</li>' +
                '</ul><br>',
            item8Article5:
                'З Бендерською фортецею пов’язані численні легенди й перекази. Одна з найвідоміших розповідає про підземні ходи, які нібито з’єднують фортецю з іншими містами й віддаленими монастирями. Інша легенда стверджує, що в стінах фортеці сховані скарби.',
            item8Article6:
                'Особливе місце в історії фортеці займає барон Мюнхгаузен. За переказами, саме в Бендерах він здійснив один зі своїх найвідоміших подвигів — політ на ядрі. Хоча ці розповіді є вигадкою, барон дійсно брав участь у російсько-турецькій війні 1735–1739 років і, можливо, відвідував Бендерську фортецю. На згадку про це на території фортеці встановлено пам’ятник у вигляді гарматного ядра.' +
                '<br><br>Сьогодні Бендерська фортеця є популярним туристичним об’єктом. Щороку її відвідують тисячі туристів, які прагнуть доторкнутися до історії й зануритися в атмосферу середньовіччя. На території фортеці відбуваються різноманітні культурні заходи, фестивалі й історичні реконструкції.',
            item8Article7:
                '<b>Чим же унікальна Бендерська фортеця?</b>' +
                '<br><ul>' +
                '<li>Це живе свідчення багатого минулого регіону, що поєднує східні та західноєвропейські архітектурні традиції.</li>' +
                '<li>У музеї на території фортеці представлені експонати, які розповідають про історію фортеці та регіону. Деякі з них, як-от знаряддя для катувань і описи їх дії, викликають жах і сьогодні.</li>' +
                '<li>Атмосфера середньовіччя й таємничості огорне вас уже з перших кроків по старовинних стінах.</li>' +
                '</ul>' +
                '<br>Бендерська фортеця — це справжній Страж на Дністрі, який ще не скоро відкриє нащадкам свої таємниці й секрети.',

            libraryTitleItem9: 'Музей Пляшки',
            libraryTitleDescription9: 'чи знали ви, що таке є в Молдові?',
            item9DescriptionText: 'Музей Пляшки – це не просто колекція скляних посудин, це справжній витвір мистецтва, створений руками людини. Цей музей є унікальною колекцією пляшок з усього світу, яку збирали багато років. Музей розташований у селі Тернівка, неподалік від міста Бендери.',
            item9Article1: 'Ідея створення музею виникла у колекціонера, який усе своє життя збирав різноманітні пляшки. З роками колекція настільки зросла, що знадобилося окреме приміщення для її експозиції. Так, у 2000-х роках з’явилася незвичайна будівля у формі гігантської пляшки.<br>' +
                '<br>Будівля музею вражає своїми розмірами. Її висота становить 28 метрів, і вона побудована з каменю. Усередині відвідувачів зустрічає поєднання каменю та дерева, що викликає асоціації з підвалом стародавньої фортеці. Колекція розміщена всередині будівлі на кількох поверхах, і кожен поверх присвячений певній тематиці. Тут можна побачити пляшки різних епох, країн, виготовлені з різних матеріалів.',
            item9Article2: 'Експозиція музею вражає своєю різноманітністю. Тут представлені пляшки найхимерніших форм і розмірів: від мініатюрних флаконів до величезних судин. Є пляшки, прикрашені розписом, гравіюванням, інкрустацією. Особливе місце в колекції займають пляшки, пов’язані з історичними подіями та видатними особистостями.<br>' +
                '<br>Окрім пляшок, у музеї представлені різні предмети, пов’язані з історією виробництва скла та алкогольних напоїв. Тут можна побачити старовинні інструменти для виготовлення скла, етикетки від різних напоїв, рекламні плакати, а також келихи, фужери та склянки.',
            item9Article3: 'Напевно, найцікавіше те, що пляшки, представлені в експозиції, не порожні. Виставлені на спеціальних підставках і стендах, вони становлять справжню історичну скарбницю.',
            item9Article4: 'Музей Пляшки – це дивовижне місце, яке нікого не залишить байдужим. Окрім того, що такої колекції пляшок немає ніде у світі, сама будівля музею є витвором мистецтва, а кожна пляшка має свою історію. Також на території музею є затишна зона відпочинку, де можна перекусити та насолодитися чудовими краєвидами.<br>' +
                '<br>У цьому дивовижному музеї можна не лише помилуватися унікальною колекцією, а й дізнатися багато цікавого про історію скла та алкогольних напоїв.',

            libraryTitleItem10: 'Каларашовський монастир',
            libraryTitleDescription10: 'Свято-Успенський монастир: історія, природа та духовність',
            item10DescriptionText: 'Розташований у мальовничій долині річки Дністер, монастир приваблює паломників і туристів з усього світу своєю давньою історією, архітектурою та неповторною атмосферою. Монастир знаходиться за 220 км від Кишинева, а дорога до нього проходить через чудові краєвиди, що робить подорож ще приємнішою.',
            item10Article1: 'Точна дата заснування монастиря невідома. За легендою, у печері навпроти сучасного монастиря жив монах-схимник, який і заснував чернечий скит. Вважається, що наприкінці XVI — на початку XVII століття тут було збудовано першу дерев\'яну церкву.',
            item10Article2: 'У 1780 році на території монастиря були побудовані кам\'яна церква та дзвіниця, на зведення яких пішов лише один рік. Місцевому боярину Марко-Донічу, на чиї кошти було зведено храм з дзвіницею, було надано довічний титул адміністратора та настоятеля монастиря. <br>' +
                '<br>Монастир пережив періоди розквіту та занепаду, пов\'язані з історичними подіями у Молдові. За радянських часів монастир було закрито, ікони вилучено, а в його приміщеннях розміщувалися різні установи. У 1991 році монастир було відновлено, і відтоді він активно відбудовується.',
            item10Article3: 'Каларашовський монастир розташований у мальовничому куточку природи, серед скель і лісів. <br>' +
                '<br>На території монастиря розташовані:' +
                '<ul>' +
                '<li>Свято-Успенська церква, побудована у XVIII столітті.</li>' +
                '<li>Церква Святого Митрофана: більш пізня споруда з витонченими формами та яскравим розписом.</li>' +
                '<li>Чернечі келії.</li>' +
                '<li>Скельна келія або печера відлюдника, де, за переказами, жив монах, який заснував обитель. Також, згідно з легендами, протягом 500 років саме сюди по черзі усамітнювалися ченці.</li>' +
                '<li>Трапезна.</li>' +
                '<li>Архиєрейський дім.</li>' +
                '<li>Озеро.</li>' +
                '<li>Монастирська бібліотека.</li>' +
                '<li>Дзвіниця з дзвонами, що скликають вірян на службу.</li>' +
                '</ul>' +
                '<br>Монастир оточений скелями, що утворюють мальовничі ущелини. З вершин скель відкриваються захоплюючі краєвиди на долину Дністра. Поруч з монастирем розташовані численні джерела з чистою водою, оповиті легендами.',
            item10Article4: 'Легенда говорить, що три джерела неподалік монастиря з\'явилися там, де ангел зі свічками вказував ченцям шлях. Сьогодні до цих священних джерел веде доглянута стежка через заповідний ліс "Каларашовка". Подорож цією стежкою — це не лише можливість втамувати спрагу водою, яку вважають цілющою, а й насолодитися чудовими пейзажами: густим лісом, високим пагорбом та глибокою ущелиною.<br>' +
                '<br>Сьогодні Каларашовський монастир є одним із найважливіших духовних центрів Молдови. Тут проводяться богослужіння, паломницькі поїздки, а також різноманітні культурні заходи. Ченці ведуть активну соціальну діяльність, допомагаючи тим, хто цього потребує.<br>'+
                '<br>Плануючи свій відпочинок, обов\'язково включіть до маршруту відвідування Каларашовського монастиря.',

            libraryTitleItem11: 'Орфей і Штефан Великий',
            libraryTitleDescription11: 'Легенди монастиря Ципова',
            item11DescriptionText: 'Розташований на стрімких скелях, монастир Ципова пропонує відвідувачам захоплюючий вид на долину Дністра. Комплекс є одним із найвражаючих скельних монастирів не лише в Молдові, але й у всій Європі, а його історія починається задовго до утворення Молдавського князівства. Згідно з легендами, перші келії були висічені у скелях ще у X-XII століттях, щоб захистити монахів від набігів кочівників, які приходили з українських степів.',
            item11Article1:'Майстри, які створили це диво, використали природний рельєф, що дозволило перетворити монастир на неприступну цитадель. Навіть у наші дні дістатися до келій нелегко – відвідувачів чекає непростий спуск вирубаними в камені сходами різної висоти. Ті ж, хто не зупиниться на півдорозі й вирішить не лише помилуватися краєвидами на Дністер, можуть спробувати пройти крізь чотириметрову щілину в скелі, яка служила монахам додатковим захистом. <br>' +
                '<br>Багаторівнева структура монастиря охоплює велику територію. І донині в Ципова можна знайти безліч келій.',
            item11Article2: 'Святиня розташована у скелях висотою до 100 м і включає три монастирські комплекси:  <br>' +
                '<ul>' +
                '<li>Перший комплекс складається з кількох келій та церкви, присвяченої Воздвиженню Святого Хреста, і датується XI-XII століттями. </li>' +
                '<li>Церква Святого Миколая була центром другого монастирського комплексу, заснованого у XIV столітті.</li>' +
                '<li>Найбільше келій знаходиться навколо церкви Успіння Пресвятої Богородиці, яка датується XVI-XVIII століттями.</li>' +
                '</ul>' +
                '<br>На початку XIX століття цей комплекс продовжував функціонувати як скит, а в 1842 році був ліквідований. У 1828 році на пагорбі над скельними комплексами була зведена церква скиту, яка знову стала монастирем у 1919 році. До цього часу скит перебував під управлінням монастиря Сахарна.<br>' +
                '<br>У радянський період усіх монахів розігнали, а будівлі на пагорбі передали в управління колгоспу. Церкву перетворили на склад для тютюну. Частими були грабежі, а келії у скелі були зруйновані. У 1988 році келії відновили й перетворили на музей.',
            item11Article3: 'Монастир Ципова вражає своєю складністю. Приміщення з’єднані між собою сходами та внутрішніми галереями, утворюючи справжній лабіринт. <br>' +
                '<br>З монастирем пов’язано безліч легенд. За однією з них, саме тут молдавський господар Штефан Великий вінчався з Марією Войкіцею. Інша легенда стверджує, що саме тут загинув і похований у одній із ніш монастиря давньогрецький поет Орфей. ',
            item11Article4: 'Околиці монастиря входять до складу природного заповідника Ципова, і це справжній рай для любителів природи. Місцевий пейзаж вражає своєю красою: спокійні води Дністра, стрімкі скелі із сотнями печер та гротів, водоспади й каскади.<br>' +
                '<br>Якщо ви хочете поринути в атмосферу минулого, доторкнутися до витоків християнства в Молдові й насолодитися мальовничими пейзажами, монастир Ципова – це місце, яке обов’язково варто відвідати. Однак, як і у випадку з деякими іншими визначними пам’ятками Молдови, до поїздки варто підготуватися – вибрати одяг та взуття, які підходять для походу гірською місцевістю.<br>'+
                '<br>У Ципова історія переплітається з легендами, а природа вражає своєю красою. Приїжджайте й переконайтеся в цьому самі! А раптом вам відкриється таємниця Орфея?',

            libraryTitleItem12: 'Монастир Сахарна',
            libraryTitleDescription12: 'Оплот духовності в серці Молдови',
            item12DescriptionText: 'Монастир Сахарна – це не просто релігійна споруда, а унікальний історико-культурний комплекс, розташований у мальовничій ущелині річки Дністер, у селі Сахарна. Цей монастир, що поєднує давні печерні церкви та сучасні будівлі, є одним із найважливіших паломницьких центрів країни.',
            item12Article1:'Точна дата заснування монастиря невідома, проте перші згадки про нього датуються ще XV століттям. Вважається, що перші монахи ховалися у скельному скиті від ворожих нападів. Вони вирубали в скелях келії та церкви, створивши унікальний печерний монастир. <br>' +
                '<br>Монастир пережив злети та падіння. У XVII-XVIII століттях він досягнув розквіту. Було збудовано великий кам\'яний храм, а також численні господарські споруди. На початку XX століття монастир був духовним центром Бессарабії, але в 1964 році його закрили та перепрофілювали в психіатричну лікарню. Відродження обителі розпочалося у 1991 році. Сьогодні тут проживає близько 20 монахів.',
            item12Article2: 'Комплекс монастиря Сахарна являє собою унікальне поєднання природних і рукотворних елементів. Головною пам’яткою є печерний монастир, вирубаний у скелях. Тут збереглися церкви, келії монахів та підземні ходи.<br>' +
                '<br>Окрім печерного монастиря, на території комплексу розташовані:' +
                '<ul>' +
                '<li>Кам\'яний храм,</li>' +
                '<li>Каплиця,</li>' +
                '<li>Житлові корпуси,</li>' +
                '<li>Господарські будівлі.</li>' +
                '</ul>' +
                '<br>Монастир Сахарна є діючим чоловічим монастирем. Монахи ведуть суворий спосіб життя, присвячуючи себе молитві та праці. У монастирі регулярно проводяться богослужіння, а також паломницькі тури.<br>',
            item12Article3: 'Однією з головних святинь монастиря є мощі преподобного Макарія. До них щороку приїжджають тисячі паломників, щоб помолитися та попросити про допомогу.<br>' +
                '<br>На території комплексу тихо й спокійно – скелі, ліс і обитель ніби запрошують відволіктися від мирської метушні та заглибитися у власну душу. Водночас відвідування монастиря порадує і любителів туризму – щоб дістатися деяких локацій, потрібно не лише пройтися пішки, але й подолати перешкоди (що, втім, цілком очікувано для скельного монастиря). Винагорода за зусилля буде гідною – мальовничий водоспад і захоплюючі краєвиди, від яких перехоплює подих.' +
                '<br>Приємний бонус – прекрасний, хоча й не дуже поступливий білий павич, який живе на території монастиря.<br>' +
                '<br>Монастир Сахарна – це місце, де можна відпочити від міської метушні та знайти внутрішню гармонію.',

            libraryTitleItem13: 'Старий Орхей',
            libraryTitleDescription13: 'Подорож у часі',
            item13DescriptionText: 'Старий Орхей — це живе втілення історії Молдови, місце, де кожна скеля зберігає свої таємниці, а кожен камінь є свідком подій минулих століть. Розташований у мальовничій долині річки Реут, цей заповідник запрошує вирушити у подорож у часі — від гето-даків до середньовіччя.',
            item13Article1:'Однією з головних пам\'яток Старого Орхея є монастир — скельна обитель. Цей скит із прекрасною церквою та келіями, вирубаними у скелі, є символом духовності та духовної традиції.<br>' +
                '<br>Шлях до археологічних і релігійних пам\'яток досить довгий, але не дуже складний. Проте те, що вражає одразу при в\'їзді в долину, — це краєвид, що відкривається. Долина, оточена горами, річка у формі підкови... як у сонячний день, так і в похмуру погоду цей вид назавжди закарбовується у пам’яті.' +
                'Якщо ви вирішите на багатокілометрову екскурсію Старим Орхеєм, підготуйте зручне взуття, багато місця у телефонах чи фотоапаратах і море емоцій — вас чекає занурення у дивовижний світ заповідника, внесеного до Списку всесвітньої спадщини ЮНЕСКО.',
            item13Article2: 'Перші поселення на території Старого Орхея з’явилися ще до нашої ери — найдавніші археологічні знахідки датуються першим тисячоліттям до Різдва Христового. Проте найбільший розквіт місто пережило в період існування Стародавньомолдавської держави. Тут розташовувався палац воєводи, численні ремісничі майстерні, лазня, житла городян.<br>' +
                '<br>Про Старий Орхей складено чимало легенд. Одна з найвідоміших стверджує, що в цих печерах ховалися скарби легендарного молдавського господаря Штефана Великого. Інша легенда розповідає, що саме тут був ув\'язнений міфічний герой Орфей. Звичайно, ці історії не мають історичного підтвердження, але вони додають місцеві особливої атмосфери таємничості й романтики.',
            item13Article3: 'Архітектурний ансамбль Старого Орхея являє собою унікальне поєднання природних і рукотворних елементів. Головними пам\'ятками є:<br>' +
                '<ul>' +
                '<li><b>Гето-дакійська фортеця:</b> Найдавніша споруда комплексу, датована VII–V століттями до нашої ери. Фортецю побудували даки — стародавнє фракійське плем’я, що населяло ці землі.</li>' +
                '<li><b>Печерний монастир:</b> Вирубаний у скелях монастир був заснований у XV столітті. Його келії, церкви та підземні ходи досі вражають своїми масштабами та майстерністю виконання.</li>' +
                '<li><b>Церква та монастир Різдва Богородиці:</b> Заснований у XV–XVI століттях, монастир Різдва Богородиці тісно пов’язаний зі стародавнім скельним скитом "Печера". Після періоду занепаду у XVIII–XIX століттях монастир було відроджено на початку XX століття. За радянської влади його разом зі скельним скитом перетворили на музей під відкритим небом, вивезли цінності й навіть зняли дзвони. Відродження обителі почалося в середині 1990-х років.</li>' +
                '<li><b>Споруди, характерні для міст Золотої Орди:</b> Караван-сараї, громадські лазні, мечеть, мавзолеї-мазари, знайдені археологами. До наших днів збереглися залишки лазні з приміщеннями для ванн, відпочинку, масажу, обладнаними опаленням і вентиляцією.</li>' +
                '</ul>',
            item13Article4: 'Сьогодні Старий Орхей — популярне туристичне місце. Тут проводяться різноманітні культурні заходи, фестивалі та конференції. У селі Бутучень, що входить до комплексу Старого Орхея, розташовані музей і майданчик, на якому проводиться унікальний фестиваль "Опера в Бутученях". Також тут є унікальна Етно-Вілла, що ще більше занурює у атмосферу цього унікального місця.<br>' +
                '<br>Що стосується історії та археології, то й нині науковці продовжують досліджувати цей казково багатий історичний об’єкт, відкриваючи нові таємниці минулого.<br>'+
                '<br><br><br><b>Чому варто відвідати Старий Орхей?</b> <br>' +
                '<ul>' +
                '<li>Старий Орхей — це живий музей, де можна доторкнутися до історії Молдови.</li>' +
                '<li>Мальовничі краєвиди долини річок Реут і Когільник створюють неповторну атмосферу.</li>' +
                '<li>Тут ви зможете знайти усамітнення й спокій.</li>' +
                '<li>Печерний монастир, гето-дакійська фортеця та інші споруди вражають своєю давниною та красою.</li>' +
                '</ul>' +
                'Старий Орхей — це місце, яке обов’язково потрібно відвідати, щоб перейнятися історією й культурою Молдови.',

            libraryTitleItem14: 'Свято-Троїцький монастир у Руді',
            libraryTitleDescription14: 'Iсторія, духовність і велич каменю',
            item14DescriptionText: 'У мальовничій ущелині річки Дністер, за 200 кілометрів на північ від Кишинева, розташоване село Рудь. Тут, серед пагорбів і лісів, наче дорогоцінний камінь, сяє Свято-Троїцький монастир – духовне серце цих місць, засноване далекого 1777 року.',
            item14Article1:
                'Цей монастир – один із найстаріших у Молдові, він приваблює паломників і туристів, які шукають усамітнення та душевного спокою. Його архітектура, виконана у старомолдавському стилі, вражає своєю простотою та витонченістю. Камінь, з якого складені стіни, зберігає тепло віків та енергію багатьох поколінь ченців.',
            item14Article2:
                'Особливо виділяється Троїцька церква – серце монастирського комплексу. Її стіни, прикрашені унікальними фресками та іконами, розповідають історію віри та відданості. Тут, під склепіннями храму, панує особлива атмосфера благодаті та спокою.<br>' +
                '<br>Монастир пережив багато випробувань за час свого існування. Були періоди розквіту й занепаду, його закривали та використовували для різних потреб. У радянський час тут були дитячий будинок і санаторій. Але, незважаючи на всі труднощі, обитель відродилася, як фенікс із попелу. І сьогодні, як і колись, сюди знову приходять люди, щоб помолитися, знайти розраду та відновити душевну рівновагу.',
            item14Article3:
                'Черниці, які зараз мешкають у монастирі, з любов’ю та турботою доглядають за територією. Тут завжди чисто, квіти милують око своєю красою, а тиша і спокій сприяють молитві та роздумам.<br>' +
                '<br>Відвідування Свято-Троїцького монастиря у Руді – це не просто екскурсія, а справжнє паломництво до витоків віри та духовності. Тут можна доторкнутися до історії, відчути зв’язок часів і знайти надію на краще майбутнє.',
            item14Article4:
                'Якщо ви шукаєте місце, де можна відпочити від міської метушні, насолодитися красою природи та знайти душевний спокій, то Свято-Троїцький монастир у Руді – саме те, що вам потрібно.<br>' +
                '<br>Якщо ви плануєте поїздку в Рудь і не хочете зустріти натовпи туристів, краще приїжджати у будній день – у вихідні та, тим більше, у релігійні свята монастир відвідує велика кількість паломників. Також варто пам’ятати про рельєф місцевості: якщо ви захочете піднятися до джерела, прогулятися лісом або дійти до Турецької тарілки, слід підібрати зручний одяг і взуття для лісових стежок із перепадами висоти.',
            item14Article5: 'Чи варто відвідати Рудь? Безперечно, так. У цьому місці кожен знайде щось для себе: любителі історії можуть доторкнутися до старовинних артефактів, прихильники природи – насолодитися мальовничими пейзажами, а паломники – знайти душевний спокій у стінах древнього монастиря.',

            libraryTitleItem15: 'Дуга Струве',
            libraryTitleDescription15: 'Як молдавський куб зберігає таємницю Землі',
            item15DescriptionText: 'На півночі Молдови, неподалік села Рудь, прихований від сторонніх очей, лежить кам\'яний куб. На перший погляд непримітний, він є безмовним свідком грандіозного наукового проєкту – Дуги Струве.',
            item15Article1:
                'Двісті років тому, коли наука лише починала досліджувати таємниці нашої планети, російський астроном Фрідріх Георг Вільгельм Струве поставив собі амбітну мету – виміряти дугу меридіана, щоб розкрити секрет форми й розмірів Землі.<br>' +
                '<br>Тридцять п’ять років, з 1816 по 1852 рік, "геометри трьох народів" – росіяни, норвежці та шведи – працювали над створенням унікальної геодезичної дуги, яка простяглася на дві тисячі вісімсот двадцять кілометрів від арктичних просторів до чорноморського узбережжя.',
            item15Article2:
                'Двісті шістдесят п’ять пунктів, позначених кам’яними кубами, подібними до того, що лежить біля села Рудь, були закладені на території десяти сучасних держав. Кожен із них був частиною великої наукової головоломки, розгадка якої мала відкрити людству таємницю будови планети.<br>' +
                '<br>Дуга Струве стала не лише видатним науковим досягненням, а й символом міжнародної співпраці та невгамовного прагнення до пізнання. Її результати лягли в основу створення карт і наукових досліджень на багато років уперед.<br>' +
                '<br>У XXI столітті, коли кам’яні куби замінили супутникові технології, Дуга Струве не втратила своєї значущості. Вона досі вважається однією з найважливіших подій в історії світової астрономії, геодезії та географії.',
            item15Article3:
                'У 2005 році Комітет Всесвітньої спадщини ЮНЕСКО визнав Дугу Струве об’єктом культурної спадщини "видатної універсальної цінності". Це єдиний геодезичний об’єкт і єдиний суто інтелектуальний пам’ятник, включений до списку Світової спадщини.<br>' +
                '<br>Кам’яний куб біля села Рудь – це не просто точка на карті, а символ наукового подвигу й прагнення людини до пізнання світу. Він нагадує нам, що наука не має кордонів, а пошук істини об’єднує людей різних національностей і культур.',

            libraryTitleItem16: 'Комплекс «Кам\'яний вік»',
            libraryTitleDescription16: 'Туристичний комплекс «Кам\'яний вік» виноробні Brănești – подорож у доісторичні часи',
            item16DescriptionText: 'Кам\'яні стіни та підлога, зображення мамонтів на стінах, тонни каменю над головою, дерев\'яні меблі, шкури тварин і живий вогонь… Це не опис житла первісної родини, а унікальний туристичний комплекс «Кам\'яний вік» при виноробні Brănești, розташований у самому серці Республіки Молдова.',
            item16Article1:
                'Комплекс поєднує в собі виноробні традиції Молдови з загадковою атмосферою підземних печер. Він висічений у вапняку на глибині понад 60 метрів і охоплює розгалужену мережу підземних галерей протяжністю 70 км.<br>' +
                '<br>Тут відвідувачі можуть побачити запилені пляшки вина, які зберігаються в круглих нішах, висічених прямо в скелі, а наскельні малюнки на стінах занурюють у справжню доісторичну епоху.',
            item16Article2:
                '«Кам\'яний вік» – це не просто виноробня, а унікальний туристичний напрямок, де гості можуть:<br>' +
                '<ul>' +
                '<li>Дегустувати вина прямо в підземних галереях, насолоджуючись традиційними молдавськими стравами.</li>' +
                '<li>Досліджувати вражаючі декорації в доісторичному стилі, які перетворюють галереї на справжній підземний музей.</li>' +
                '<li>Відпочивати в незвичайному готелі, де затишні номери оформлені у стилі традиційних молдавських жител.</li>' +
                '<li>Відвідувати фестивалі та заходи, які проходять прямо в комплексі, поєднуючи музику, гастрономію та культуру вина.</li>' +
                '</ul>',
            item16Article3:
                'Якщо ви поціновувач вина, любитель історії або просто мандрівник у пошуках нових вражень, комплекс «Кам\'яний вік» запрошує вас у світ, де природа і виноробні традиції гармонійно поєднуються.<br>' +
                '<br>Приїжджайте, щоб відкрити справжній смак Молдови та зануритися в атмосферу місця, якого більше ніде немає в країні!',

            libraryTitleItem17: 'Château Vartely',
            item17DescriptionText: 'Одна з найвідоміших виноробень Молдови, де виноробні традиції гармонійно поєднуються із сучасними технологіями.',
            item17Article1:
                'Розташована неподалік від Орхея, вона приваблює відвідувачів високоякісними винами, гостинністю та унікальним туристичним комплексом. Château Vartely використовує сорти винограду, вирощені у відомих виноробних регіонах Молдови – Кодрах та південній зоні країни. Тут виробляють як класичні європейські вина, так і автентичні молдавські сорти, наприклад, Feteasca Albă, Feteasca Neagră та Rară Neagră, які завойовують серця дегустаторів у всьому світі.<br>' +
                '<br>Однак Château Vartely – це не лише виноробня, а й справжній культурний центр. У музеї вина на стінах можна побачити листи кори коркового дуба та довгі коркові трубки – саме те, що згодом перетворюється на ті самі винні пробки, до яких ми всі звикли.',
            item17Article2:
                'Тут представлені вина з різних країн світу, а також відвідувачам демонструють зразки різних типів ґрунту, на яких вирощується виноград, допомагаючи зрозуміти вплив теруару на смак вина. Колекція виноробні налічує велику кількість сортів і пляшок вина. У виробничих цехах можна побачити високі цистерни, у яких проходить бродіння вина.',
            item17Article3:
                'Однією з родзинок відвідування Château Vartely стане світлове шоу за технологією відеомапінгу. Яскраві анімовані зображення з’являються на діжках з вином, що зберігаються в підвалі. Навіть якщо ви не палкий шанувальник вина, світлова фантасмагорія не залишить вас байдужими.<br>' +
                '<br>Château Vartely пропонує своїм гостям не лише знайомство з виноробством, а й повноцінний відпочинок. Гості виноробні можуть побачити весь процес виробництва, продегустувати вина та відпочити у віллах, оформлених у стилі найкращих виноробних регіонів світу.',
            item17Article4:
                'Château Vartely – це не просто виноробня, а культурний центр, що об’єднує історію, винне мистецтво та молдавську гостинність. Вина, вироблені тут, експортуються більш ніж у 30 країн і є візитною карткою Молдови на міжнародній арені.',

            libraryTitleItem18: 'Музей села',
            libraryTitleDescription18: 'Подорож у глибини молдавського села',
            item18DescriptionText: 'Уявіть собі подорож у часі, в саме серце молдавського села. Саме це чекає на вас у Музеї села в Кишиневі. Це не просто музей, а справжній живий куточок історії, де можна доторкнутися до минулого і відчути, як жили наші предки.',
            item18Article1:
                'Прогулюючись музеєм, ви опинитеся в молдавському селі XVII століття. Ось традиційний молдавський будинок з різьбленими дверима, кам’яним фундаментом і міцними сходами. А ось і предмети побуту: глиняний посуд, ткацькі верстати, старовинні прядки та сільськогосподарські знаряддя. А поруч – чиста питна вода та журавель-колодязь.<br>' +
                '<br>Особливу атмосферу підкреслює важкий мідний дзвін, що злегка розхитується на вітрі.<br>' +
                '<br>Кожен експонат – це частинка душі молдавського народу, його працьовитості та традицій.',
            item18Article2:
                'По мощеній каменем доріжці ви дійдете до головної перлини музею – дерев\'яної церкви Успіння Пресвятої Богородиці, привезеної з села Хирішень. Ця церква, збудована у 1642 році, є одним із найстаріших архітектурних пам’ятників Молдови. Її висота – 27 метрів, і вона є однією з найвищих дерев\'яних церков у Молдові, вражаючи своєю красою та витонченістю.<br>' +
                '<br>Музей села – це не просто місце для екскурсій, а місце, де можна відпочити душею і насолодитися тишею. Тут панує атмосфера спокою і гармонії.',
            item18Article3:
                '<h4>Чому варто відвідати Музей села?</h4><br>' +
                '<ul>' +
                '<li>Поринути в атмосферу справжнього молдавського села.</li>' +
                '<li>Побачити унікальні експонати, що розповідають про історію та культуру Молдови.</li>' +
                '<li>Помилуватися старовинною дерев\'яною церквою.</li>' +
                '<li>Зробити гарні фотографії на згадку.</li>' +
                '<li>Просто відпочити і насолодитися тишею.</li>' +
                '</ul>' +
                'Музей села розташований неподалік від «Міських воріт» столиці Молдови. Це ідеальне місце для тих, хто хоче дізнатися більше про минуле Молдови та її народ.',

            notFound: 'НЕ ЗНАЙДЕНО :(',
        },
    };

    private currentLanguageSubject = new BehaviorSubject<string>(this.getSavedLanguage() || 'ru');
    currentLanguage$ = this.currentLanguageSubject.asObservable();

    constructor() {}

    private getSavedLanguage(): string | null {
        return localStorage.getItem('selectedLanguage');
    }

    private saveLanguage(lang: string): void {
        localStorage.setItem('selectedLanguage', lang);
    }

    getCurrentLanguage(): string {
        return this.currentLanguageSubject.getValue();
    }

    switchLanguage(lang: string): void {
        this.saveLanguage(lang);
        this.currentLanguageSubject.next(lang);
    }

    translate(key: string): string {
        const currentLanguage = this.getCurrentLanguage();
        return this.translations[currentLanguage][key] || key;
    }
}

interface Translation {
    [key: string]: string;
}

interface Translations {
    [lang: string]: Translation;
}
