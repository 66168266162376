import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { RouterLink } from "@angular/router";

@Component({
    selector: 'app-navbar',
    standalone: true,
    imports: [CommonModule, RouterLink, NgOptimizedImage],
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    mobileNavDisplay: string = 'none';
    dropdownDisplay: string = 'none';
    menuItemHome?: string;
    menuItemLibrary?: string;
    menuItemForum?: string;
    currentLanguage: string;
    activeMenuItem?: string;
    private languageSubscription?: Subscription;
    private routerSubscription?: Subscription;

    constructor(private translationService: TranslationService, private router: Router) {
        this.currentLanguage = this.translationService.getCurrentLanguage();
        this.setMenuItems();
    }

    ngOnInit() {
        this.languageSubscription = this.translationService.currentLanguage$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang;
                this.setMenuItems();
            }
        );

        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.updateActiveMenuItem(this.router.url);
            }
        });
        this.updateActiveMenuItem(this.router.url);
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    switchLanguage(lang: string): void {
        this.translationService.switchLanguage(lang);
        this.closeDropdown();
    }

    toggleMobileNav() {
        this.mobileNavDisplay = this.mobileNavDisplay === 'block' ? 'none' : 'block';
    }

    closeMobileNav() {
        this.mobileNavDisplay = 'none';
    }

    toggleDropdown(event: Event) {
        event.stopPropagation();
        this.dropdownDisplay = this.dropdownDisplay === 'block' ? 'none' : 'block';
    }

    closeDropdown() {
        this.dropdownDisplay = 'none';
    }

    setActiveMenuItem(menuItem: string) {
        this.activeMenuItem = menuItem;
        this.closeMobileNav();
    }

    private setMenuItems() {
        this.menuItemHome = this.translationService.translate('menuItemHome');
        this.menuItemLibrary = this.translationService.translate('menuItemLibrary');
        this.menuItemForum = this.translationService.translate('menuItemForum');
    }

    private updateActiveMenuItem(url: string) {
        if (url.includes('/library') || url.includes('/place')) {
            this.activeMenuItem = 'library';
        } else if (url.includes('/forum')) {
            this.activeMenuItem = 'forum';
        } else {
            this.activeMenuItem = 'home';
        }
    }
}
