import { Component } from '@angular/core';
import { CategoriesPanelComponent } from "../../components/library-box/categories-panel/categories-panel.component";
import {LibraryBoxComponent} from "../../components/library-box/library-box.component";

@Component({
    selector: 'app-library-page',
    standalone: true,
    imports: [
        CategoriesPanelComponent,
        LibraryBoxComponent
    ],
    templateUrl: './library-page.component.html',
    styleUrl: './library-page.component.scss',
})
export class LibraryPageComponent {
    selectedCategory: string = 'c1';

    onCategorySelected(categoryId: string) {
        this.selectedCategory = categoryId;
    }
}
