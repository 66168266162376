<div id="myCarousel" class="carousel slide" data-ride="carousel" style="max-height: 900px; max-width: 100%; overflow: hidden; margin: 0 auto;">
    <ol class="carousel-indicators">
        <li *ngFor="let image of images; let i = index" [class.active]="i === 0" [attr.data-slide-to]="i" [attr.data-target]="'#myCarousel'"></li>
    </ol>
    <div class="carousel-inner">
        <div *ngFor="let image of images; let i = index" [class.active]="i === 0" class="item">
            <img [src]="image" style="width: 100%; height: auto; max-height: 900px; object-fit: contain;" (click)="openModal(image)">
        </div>
    </div>
    <a class="left carousel-control" href="#myCarousel" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right"></span>
        <span class="sr-only">Next</span>
    </a>
</div>

<div *ngIf="isModalOpen" class="modal">
    <span class="close" (click)="closeModal()">&times;</span>
    <div class="modal-image-container"
         (wheel)="zoomImage($event)"
         (mousedown)="startDragging($event)"
         (mouseup)="stopDragging()"
         (mousemove)="onDrag($event)"
         (mouseleave)="stopDragging()">
        <img class="modal-content zoomable" [src]="selectedImage"
             [style.transform]="'scale(' + zoomLevel + ') translate(' + translateX + 'px, ' + translateY + 'px)'"
             [style.cursor]="isDragging ? 'grabbing' : 'grab'">
    </div>
</div>
