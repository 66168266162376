import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { LibraryPageComponent } from './pages/library-page/library-page.component';
import { ForumPageComponent } from './pages/forum-page/forum-page.component';
import { PlaceDetailsComponent } from "./pages/place-details/place-details.component";

export const routes: Routes = [
    { path: '', component: MainPageComponent },
    { path: 'library', component: LibraryPageComponent },
    { path: 'forum', component: ForumPageComponent },
    { path: 'place/:id', component: PlaceDetailsComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
