import {Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {CommonModule, NgForOf, NgOptimizedImage} from "@angular/common";
import {Router} from "@angular/router";
import {TranslationService} from "../../services/translation.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-library-box',
    standalone: true,
    imports: [
        NgForOf,
        NgOptimizedImage,
        CommonModule
    ],
    templateUrl: './library-box.component.html',
    styleUrls: ['./library-box.component.scss'],
})
export class LibraryBoxComponent implements OnInit, OnChanges, OnDestroy {
    private languageSubscription?: Subscription;
    libraryTitleItem1?: string;
    libraryTitleItem2?: string;
    libraryTitleItem3?: string;
    libraryTitleItem4?: string;
    libraryTitleItem5?: string;
    libraryTitleItem6?: string;
    libraryTitleItem7?: string;
    libraryTitleItem8?: string;
    libraryTitleItem9?: string;
    libraryTitleItem10?: string;
    libraryTitleItem11?: string;
    libraryTitleItem12?: string;
    libraryTitleItem13?: string;
    libraryTitleItem14?: string;
    libraryTitleItem15?: string;
    libraryTitleItem16?: string;
    libraryTitleItem17?: string;
    libraryTitleItem18?: string;
    @Input() selectedCategory: string = 'all';

    products: Product[] = [];

    filteredProducts: Product[] = [];
    paginatedProducts: Product[] = [];
    itemsPerPage = 12;
    currentPage = 1;
    totalPages = 1;
    pageNumbers: number[] = [];
    notFound?: string;

    constructor(
        private router: Router,
        private translationService: TranslationService,
        private cdr: ChangeDetectorRef
    ) {
        this.setTranslations();
    }

    ngOnInit(): void {
        this.setTranslations();
        this.filteredProducts = this.products;
        this.totalPages = Math.ceil(this.filteredProducts.length / this.itemsPerPage);
        this.generatePageNumbers();
        this.languageSubscription = this.translationService.currentLanguage$.subscribe(() => {
            this.setTranslations();
            // this.filteredProducts = this.products;
            // this.totalPages = Math.ceil(this.filteredProducts.length / this.itemsPerPage);
            this.generatePageNumbers();
            this.cdr.detectChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedCategory']) {
            this.filterProducts();
            this.updatePagination();
        }
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

    private setTranslations() {
        this.notFound = this.translationService.translate('notFound');
        this.libraryTitleItem1 = this.translationService.translate('libraryTitleItem1');
        this.libraryTitleItem2 = this.translationService.translate('libraryTitleItem2');
        this.libraryTitleItem3 = this.translationService.translate('libraryTitleItem3');
        this.libraryTitleItem4 = this.translationService.translate('libraryTitleItem4');
        this.libraryTitleItem5 = this.translationService.translate('libraryTitleItem5');
        this.libraryTitleItem6 = this.translationService.translate('libraryTitleItem6');
        this.libraryTitleItem7 = this.translationService.translate('libraryTitleItem7');
        this.libraryTitleItem8 = this.translationService.translate('libraryTitleItem8');
        this.libraryTitleItem9 = this.translationService.translate('libraryTitleItem9');
        this.libraryTitleItem10 = this.translationService.translate('libraryTitleItem10');
        this.libraryTitleItem11 = this.translationService.translate('libraryTitleItem11');
        this.libraryTitleItem12 = this.translationService.translate('libraryTitleItem12');
        this.libraryTitleItem13 = this.translationService.translate('libraryTitleItem13');
        this.libraryTitleItem14 = this.translationService.translate('libraryTitleItem14');
        this.libraryTitleItem15 = this.translationService.translate('libraryTitleItem15');
        this.libraryTitleItem16 = this.translationService.translate('libraryTitleItem16');
        this.libraryTitleItem17 = this.translationService.translate('libraryTitleItem17');
        this.libraryTitleItem18 = this.translationService.translate('libraryTitleItem18');

        this.products = [
            {id: 'village-museum', category_id: 'structures', image: 'assets/images/library/item_18/library_photo.png', name: this.libraryTitleItem18},
            {id: 'chateau-vartely', category_id: 'structures', image: 'assets/images/library/item_17/library_photo.png', name: this.libraryTitleItem17},
            {id: 'stone-age', category_id: 'structures', image: 'assets/images/library/item_16/library_photo.png', name: this.libraryTitleItem16},
            {id: 'struve-geodetic-arc', category_id: 'structures', image: 'assets/images/library/item_15/library_photo.png', name: this.libraryTitleItem15},
            {id: 'monastery-rudi', category_id: 'sacred-sites', image: 'assets/images/library/item_14/library_photo.png', name: this.libraryTitleItem14},
            {id: 'old-orhei', category_id: 'sacred-sites', image: 'assets/images/library/item_13/library_photo.png', name: this.libraryTitleItem13},
            {id: 'saharna-monastery', category_id: 'sacred-sites', image: 'assets/images/library/item_12/library_photo.png', name: this.libraryTitleItem12},
            {id: 'orpheus-stephen-the-great', category_id: 'sacred-sites', image: 'assets/images/library/item_11/library_photo.png', name: this.libraryTitleItem11},
            {id: 'calarasovca-monastery', category_id: 'sacred-sites', image: 'assets/images/library/item_10/library_photo.png', name: this.libraryTitleItem10},
            {id: 'bottle-museum', category_id: 'museums-theaters', image: 'assets/images/library/item_9/library_photo.jpg', name: this.libraryTitleItem9},
            {id: 'bender-fortress', category_id: 'fortresses', image: 'assets/images/library/item_8/library_photo.jpg', name: this.libraryTitleItem8},
            {id: 'church-in-braicau', category_id: 'sacred-sites', image: 'assets/images/library/item_7/library_photo.jpg', name: this.libraryTitleItem7},
            {id: 'taul-park', category_id: 'structures', image: 'assets/images/library/item_6/library_photo.jpg', name: this.libraryTitleItem6},
            {id: 'bechirs-gorge', category_id: 'sacred-sites', image: 'assets/images/library/item_5/library_photo.jpg', name: this.libraryTitleItem5},
            {id: 'dealul-tiganesc', category_id: 'structures', image: 'assets/images/library/item_4/library_photo.jpg', name: this.libraryTitleItem4},
            {id: 'cosauți-monastery', category_id: 'sacred-sites', image: 'assets/images/library/item_3/library_photo.jpg', name: this.libraryTitleItem3},
            {id: 'soroca-fortress', category_id: 'fortresses', image: 'assets/images/library/item_2/library_photo.jpg', name: this.libraryTitleItem2},
            {id: 'ivana-cupala-camp', category_id: 'festivals-fairs', image: 'assets/images/library/item_1/library_photo.JPG', name: this.libraryTitleItem1},
        ];
    }

    filterProducts() {
        if (this.selectedCategory === 'all') {
            this.filteredProducts = this.products;
        } else {
            this.filteredProducts = this.products.filter(product => product.category_id === this.selectedCategory);
        }
        this.totalPages = Math.ceil(this.filteredProducts.length / this.itemsPerPage);
        this.currentPage = 1;
        this.generatePageNumbers();
    }

    navigateToPlace(productId: string) {
        this.router.navigate(['/place', productId]);
    }

    generatePageNumbers() {
        this.pageNumbers = Array.from({length: this.totalPages}, (_, i) => i + 1);
        this.updatePagination();
    }

    updatePagination() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        this.paginatedProducts = this.filteredProducts.slice(start, end);
    }

    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.updatePagination();
        }
    }

    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.updatePagination();
        }
    }

    goToPage(pageNumber: number) {
        this.currentPage = pageNumber;
        this.updatePagination();
    }
}

interface Product {
    id: string;
    category_id: string;
    image: string;
    name?: string;
}
