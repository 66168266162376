import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {TranslationService} from "../../services/translation.service";

@Component({
    selector: 'app-forum-box',
    standalone: true,
    imports: [
        NgOptimizedImage,
        ReactiveFormsModule,
        CommonModule
    ],
    templateUrl: './forum-box.component.html',
    styleUrls: ['./forum-box.component.scss'],
})
export class ForumBoxComponent implements OnInit, OnDestroy {
    secretKey: string = "xovalzpk";
    emailForm: FormGroup;
    formLabelName?: string;
    formLabelEmail?: string;
    formLabelPhone?: string;
    formLabelMessage?: string;
    formSendButton?: string;
    formTelegramButton?: string;
    forumFormTitle?: string;
    forumFormText1?: string;
    forumFormText2?: string;
    private languageSubscription?: Subscription;

    constructor(
        private fb: FormBuilder, private httpClient: HttpClient,
        private translationService: TranslationService
    ) {
        this.setTranslations();
        this.emailForm = this.fb.group({
            name: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            phone: ["", [Validators.required, Validators.pattern('^\\+?[0-9]{10,12}$')]],
            message: ["", Validators.required],
        });
    }

    ngOnInit() {
        this.languageSubscription =
            this.translationService.currentLanguage$.subscribe(() => {
                this.setTranslations();
            });
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

    private setTranslations() {
        this.formLabelName = this.translationService.translate('formLabelName');
        this.formLabelEmail = this.translationService.translate('formLabelEmail');
        this.formLabelPhone = this.translationService.translate('formLabelPhone');
        this.formLabelMessage = this.translationService.translate('formLabelMessage');
        this.formTelegramButton = this.translationService.translate('formTelegramButton');
        this.formSendButton = this.translationService.translate('formSendButton');
        this.forumFormTitle = this.translationService.translate('forumFormTitle');
        this.forumFormText1 = this.translationService.translate('forumFormText1');
        this.forumFormText2 = this.translationService.translate('forumFormText2');
    }

    sendEmail(name: string, email: string, phone: string, message: string) {
        const url = `https://formspree.io/f/${this.secretKey}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        };

        const data = `name=${name}&email=${email}&phone=${phone}&message=${message}`;

        this.httpClient.post<any>(url, data, httpOptions).subscribe({
            next: data => {
                alert('Email sent successfully!');
            },
            error: error => {
                alert('Error while sending a message, write to our e-mail uicd.md@gmail.com');
            }
        });
    }

    onSubmit() {
        if (this.emailForm.valid) {
            const {name, email, phone, message} = this.emailForm.value;
            this.sendEmail(name, email, phone, message);
        }
    }
}
