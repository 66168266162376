import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {Subscription} from "rxjs";
import {TranslationService} from "../../services/translation.service";

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [
        NgOptimizedImage,
        CommonModule
    ],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit, OnDestroy {
    footerPartners?: string;
    footerSupport?: string;
    private languageSubscription?: Subscription;

    constructor(private translationService: TranslationService) {
        this.setTranslations();
    }

    ngOnInit() {
        this.languageSubscription =
            this.translationService.currentLanguage$.subscribe(() => {
                this.setTranslations();
            });
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

    private setTranslations() {
        this.footerPartners = this.translationService.translate('footerPartners');
        this.footerSupport = this.translationService.translate('footerSupport');
    }
}
