import {Component, Output, EventEmitter, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { CommonModule } from "@angular/common";
import {Router} from "@angular/router";
import {TranslationService} from "../../../services/translation.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-categories-panel',
    standalone: true,
    imports: [
        CommonModule,
    ],
    templateUrl: './categories-panel.component.html',
    styleUrls: ['./categories-panel.component.scss']
})
export class CategoriesPanelComponent implements OnInit, OnDestroy {
    categoryTitleItem1?: string;
    categoryTitleItem2?: string;
    categoryTitleItem3?: string;
    categoryTitleItem4?: string;
    categoryTitleItem5?: string;
    categoryTitleItem6?: string;

    categories: Category[] = [];
    private languageSubscription?: Subscription;
    @Output() categorySelected = new EventEmitter<string>();

    selectedCategoryId: string = 'all';

    constructor(
        private translationService: TranslationService,
    ) {
        this.setTranslations();
    }

    ngOnInit(): void {
        this.languageSubscription =
            this.translationService.currentLanguage$.subscribe(() => {
                this.setTranslations();
            });
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

    selectCategory(categoryId: string) {
        this.selectedCategoryId = categoryId;
        this.categorySelected.emit(categoryId);
    }

    private setTranslations() {
        this.categoryTitleItem1 = this.translationService.translate('categoryTitleItem1');
        this.categoryTitleItem2 = this.translationService.translate('categoryTitleItem2');
        this.categoryTitleItem3 = this.translationService.translate('categoryTitleItem3');
        this.categoryTitleItem4 = this.translationService.translate('categoryTitleItem4');
        this.categoryTitleItem5 = this.translationService.translate('categoryTitleItem5');
        this.categoryTitleItem6 = this.translationService.translate('categoryTitleItem6');

        this.categories = [
            { id: 'all', name: this.categoryTitleItem1, icon: 'assets/icons/category/category_item1.svg', width: 32, height: 32 },
            { id: 'sacred-sites', name: this.categoryTitleItem2, icon: 'assets/icons/category/category_item2.svg', width: 32, height: 32 },
            { id: 'festivals-fairs', name: this.categoryTitleItem3, icon: 'assets/icons/category/category_item3.svg', width: 32, height: 32 },
            { id: 'museums-theaters', name: this.categoryTitleItem4, icon: 'assets/icons/category/category_item4.svg', width: 32, height: 32 },
            { id: 'structures', name: this.categoryTitleItem5, icon: 'assets/icons/category/category_item5.svg', width: 32, height: 32 },
            { id: 'fortresses', name: this.categoryTitleItem6, icon: 'assets/icons/category/category_item6.svg', width: 32, height: 32 }
        ];
    }
}

interface Category {
    id: string;
    name?: string;
    icon: string;
    width?: number;
    height?: number;
}
