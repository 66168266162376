<div *ngIf="item">
    <div class="title-container" [ngStyle]="{'background-image': 'url(' + item.banner + ')'}">
        <h1 class="title" [innerHTML]="sanitizeHtml(item.name)"></h1>
    </div>

    <div class="description" *ngIf="item.mainDescription != null">
        <div class="section">
            <div class="content">
                <div class="text-content">
                    <br>
                    {{ item.mainDescription }}
                </div>
            </div>
        </div>
    </div>

    <div class="container360" *ngIf="item.content360 != null">
        <div id="panorama" class="photo360"></div>
    </div>

    <div class="text-container"
         *ngFor="let text of item.text_blocks; let i = index"
         [ngClass]="{'left-image': i % 2 === 0, 'right-image': i % 2 !== 0}">
        <div class="text-photo">
            <img [src]="item.img_blocks[i]">
        </div>
        <div class="text" [innerHTML]="sanitizeHtml(text)">
        </div>
    </div>

    <div style="text-align: center; padding: 40px; color: #8f580d">
        <i>Thanks to the support of the European Heritage Hub pilot Project co-funded by the European Union and supported by the ALIPH Foundation.</i>
    </div>

    <div class="container-carousel" *ngIf="item.carousel != null">
        <div class="carousel">
            <app-carousel [images]="item.carousel || []"></app-carousel>
        </div>
    </div>
</div>

<div *ngIf="!item" class="notFound">
    <p><i>{{ notFound }}</i></p>
</div>

