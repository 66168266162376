<div class="categories-panel">
    <section class="section" id="categories">
        <div class="section-titles">
            <ul class="category-list">
                <li class="category-list__item"
                    *ngFor="let category of categories"
                    [id]="category.id"
                    (click)="selectCategory(category.id)"
                    [class.selected]="category.id === selectedCategoryId">
                    <a>
                        <div class="category-icon">
                            <img [src]="category.icon" [alt]="category.name" [width]="category.width" [height]="category.height">
                        </div>
                        <div class="category-name">{{ category.name }}</div>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</div>
