<div class="container">
    <div class="yellow-background"></div>
    <div class="content-wrapper">
        <div class="left">
            <h1>{{ forumFormTitle }}</h1>
            <p>{{ forumFormText1 }} <br> {{ forumFormText2 }}</p>
            <div class="social">
                <div class="social-icons">
                    <a href="https://www.facebook.com/profile.php?id=61561206736436&mibextid=ZbWKwL" class="social-icon" target="_blank">
                        <img ngSrc="assets/icons/facebook.svg" alt="facebook-icon" height="15" width="9">
                    </a>
                    <a href="https://www.instagram.com/uicd.md?igsh=cnB1MjFqa2c1a3l0" class="social-icon" target="_blank">
                        <img ngSrc="assets/icons/inst.svg" alt="inst-icon" height="13" width="13">
                    </a>
                    <a href="https://www.tiktok.com/@uicd.md?_t=8oz5QwpsS9d" class="social-icon" target="_blank">
                        <img ngSrc="assets/icons/tiktok.svg" alt="twitter-icon" height="17" width="20">
                    </a>
                </div>
                <a href="https://t.me/uicdmd24" class="telegram-button" target="_blank">
                    <img ngSrc="assets/icons/telegram.svg" alt="telegram-icon" height="20" width="22">
                    <span>{{ formTelegramButton }}</span>
                </a>
            </div>
        </div>
        <div class="right">
            <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                <input formControlName="name" type="text" placeholder="{{ formLabelName }}" required>

                <input formControlName="email" type="email" placeholder="{{ formLabelEmail }}" required>

                <input formControlName="phone" type="tel" placeholder="{{ formLabelPhone }}" required>

                <textarea formControlName="message" placeholder="{{ formLabelMessage }}" required></textarea>

                <br>
                <button type="submit" [disabled]="emailForm.invalid">{{ formSendButton }}</button>
            </form>
        </div>
    </div>
</div>
