import {Component, OnDestroy, OnInit} from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { ForumBoxComponent } from '../../components/forum-box/forum-box.component';
import { Subscription } from "rxjs";
import { TranslationService } from "../../services/translation.service";

@Component({
    selector: 'app-forum-page',
    standalone: true,
    imports: [
        HeaderComponent,
        ForumBoxComponent,
    ],
    templateUrl: './forum-page.component.html',
    styleUrl: './forum-page.component.scss',
})
export class ForumPageComponent implements OnInit, OnDestroy {
    forumAboutUs?: string;
    forumAboutUsText1?:string;
    forumAboutUsText2?:string;
    private languageSubscription?: Subscription;

    constructor(private translationService: TranslationService) {
        this.setTranslations();
    }

    ngOnInit() {
        this.languageSubscription =
            this.translationService.currentLanguage$.subscribe(() => {
                this.setTranslations();
            });
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

    private setTranslations() {
        this.forumAboutUs = this.translationService.translate('forumAboutUs');
        this.forumAboutUsText1 = this.translationService.translate('forumAboutUsText1');
        this.forumAboutUsText2 = this.translationService.translate('forumAboutUsText2');
    }
}
