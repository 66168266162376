<app-header></app-header>
<div class="description">
    <div class="description">
        <div class="main-content">
            <div class="section">
                <div class="title">
                    <h2>{{ forumAboutUs }}</h2>
                </div>
                <div class="content">
                    <div class="image-content">
                        <i class="fa-solid fa-person-shelter"></i>
                    </div>
                    <div class="text-content">
                        <p>{{ forumAboutUsText1 }}</p>
                    </div>
                </div>
                <div class="content">
                    <div class="image-content">
                        <i class="fa-solid fa-camera-retro"></i>
                    </div>
                    <div class="text-content">
                        <p>{{ forumAboutUsText2 }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="form">
    <app-forum-box></app-forum-box>
</div>
