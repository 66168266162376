import {Component, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../components/header/header.component';
import { Subscription } from "rxjs";
import { TranslationService } from "../../services/translation.service";
import {SliderComponent} from "../../components/slider/slider.component";

@Component({
    selector: 'app-main-page',
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        SliderComponent
    ],
    templateUrl: './main-page.component.html',
    styleUrl: './main-page.component.scss',
})
export class MainPageComponent implements OnInit, OnDestroy {
    aboutUs?: string;
    aboutUsText1?: string;
    aboutUsText2?: string;
    sponsors?: string;
    sponsorsDescription?: string;
    private languageSubscription?: Subscription;

    constructor(private translationService: TranslationService) {
        this.setTranslations();
    }

    ngOnInit() {
        this.languageSubscription =
            this.translationService.currentLanguage$.subscribe(() => {
                this.setTranslations();
            });
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

    private setTranslations() {
        this.aboutUs = this.translationService.translate('aboutUs');
        this.aboutUsText1 = this.translationService.translate('aboutUsText1');
        this.aboutUsText2 = this.translationService.translate('aboutUsText2');
        this.sponsors = this.translationService.translate('sponsors');
        this.sponsorsDescription = this.translationService.translate('sponsorsDescription');
    }
}
