import { Component } from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import {LoadingPageComponent} from "./pages/loading-page/loading-page.component";
import {LoadingService} from "./services/loading.service";
import {filter} from "rxjs";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        CommonModule,
        NavbarComponent,
        FooterComponent,
        LoadingPageComponent
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    isLoading = false;
    private readonly loadingDelay = 1000; // 1 second

    constructor(private router: Router, private loadingService: LoadingService) {}

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.startLoading();
            setTimeout(() => this.stopLoading(), this.loadingDelay);
        });
    }

    private startLoading() {
        this.isLoading = true;
        this.loadingService.show();
    }

    private stopLoading() {
        this.isLoading = false;
        this.loadingService.hide();
    }
}
