import {Component, HostListener, Input} from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
    selector: 'app-carousel',
    standalone: true,
    imports: [ CommonModule ],
    templateUrl: './carousel.component.html',
    styleUrl: './carousel.component.scss',
})
export class CarouselComponent {
    @Input() images: string[] = [];

    isModalOpen: boolean = false;
    selectedImage: string | null = null;
    zoomLevel: number = 1;
    translateX: number = 0;
    translateY: number = 0;

    isDragging: boolean = false;
    startX: number = 0;
    startY: number = 0;
    lastX: number = 0;
    lastY: number = 0;

    openModal(image: string): void {
        this.selectedImage = image;
        this.isModalOpen = true;
        this.zoomLevel = 1;
        this.translateX = 0;
        this.translateY = 0;
        this.disableScroll();
    }

    closeModal(): void {
        this.isModalOpen = false;
        this.selectedImage = null;
        this.enableScroll();
    }

    // Зумирование с помощью колеса мыши
    zoomImage(event: WheelEvent): void {
        event.preventDefault();
        if (event.deltaY > 0) {
            this.zoomLevel = Math.max(this.zoomLevel - 0.1, 1);
        } else {
            this.zoomLevel = Math.min(this.zoomLevel + 0.1, 5);
        }
    }

    startDragging(event: MouseEvent): void {
        if (this.zoomLevel > 1) {
            this.isDragging = true;
            this.startX = event.clientX;
            this.startY = event.clientY;
            event.preventDefault();
        }
    }

    stopDragging(): void {
        this.isDragging = false;
        this.lastX = this.translateX;
        this.lastY = this.translateY;
    }

    onDrag(event: MouseEvent): void {
        if (this.isDragging) {
            const deltaX = event.clientX - this.startX;
            const deltaY = event.clientY - this.startY;
            this.translateX = this.lastX + deltaX;
            this.translateY = this.lastY + deltaY;
            event.preventDefault();
        }
    }

    disableScroll() {
        document.body.style.overflow = 'hidden';
    }

    enableScroll() {
        document.body.style.overflow = '';
    }

    @HostListener('wheel', ['$event'])
    onScroll(event: WheelEvent) {
        if (this.isModalOpen) {
            event.preventDefault();
        }
    }
}
