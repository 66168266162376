<section class="content">
    <div class="left-content">
        <div class="slider-container">
            <div class="slider-wrapper" #sliderWrapper>
                <div class="slider-item" *ngFor="let slide of slides">
                    <img [src]="slide.src" alt="Slide image" />
                </div>
            </div>
            <button class="prev" (click)="prevSlide()">&#10094;</button>
            <button class="next" (click)="nextSlide()">&#10095;</button>
        </div>
    </div>
</section>
