import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import { Subscription } from "rxjs";
import { TranslationService } from "../../services/translation.service";
import { CarouselComponent } from "../../components/carousel/carousel.component";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import 'pannellum/src/js/pannellum';
import 'pannellum/src/js/libpannellum';

declare const pannellum: any;
export default pannellum;

@Component({
    selector: 'app-place-details',
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        CarouselComponent
    ],
    templateUrl: './place-details.component.html',
    styleUrl: './place-details.component.scss'
})
export class PlaceDetailsComponent implements OnInit, OnDestroy {
    aboutUs?: string;
    aboutUsDescription?: string;
    itemId: string | null = null;
    item: Item | undefined;
    private languageSubscription?: Subscription;
    notFound?: string;

    libraryTitleItem1?: string;
    libraryTitleDescription?: string;
    item1DescriptionText?: string;
    itemArticle1?: string;
    itemArticle2?: string;
    itemArticle3?: string;
    itemArticle4?: string;
    itemArticle5?: string;
    itemArticle6?: string;

    libraryTitleItem2?: string;
    libraryTitleDescription2?: string;
    item2DescriptionText?: string;
    item2Article1?: string;
    item2Article2?: string;
    item2Article3?: string;
    item2Article4?: string;

    libraryTitleItem3?: string;
    libraryTitleDescription3?: string;
    item3DescriptionText?: string;
    item3Article1?: string;
    item3Article2?: string;
    item3Article3?: string;
    item3Article4?: string;
    item3Article5?: string;

    libraryTitleItem4?: string;
    item4DescriptionText?: string;
    item4Article1?: string;
    item4Article2?: string;

    libraryTitleItem5?: string;
    item5DescriptionText?: string;
    item5Article1?: string;
    item5Article2?: string;
    item5Article3?: string;
    item5Article4?: string;

    libraryTitleItem6?: string;
    libraryTitleDescription6?: string;
    item6DescriptionText?: string;
    item6Article1?: string;
    item6Article2?: string;
    item6Article3?: string;
    item6Article4?: string;

    libraryTitleItem7?: string;
    libraryTitleDescription7?: string;
    item7DescriptionText?: string;
    item7Article1?: string;
    item7Article2?: string;

    libraryTitleItem8?: string;
    libraryTitleDescription8?: string;
    item8DescriptionText?: string;
    item8Article1?: string;
    item8Article2?: string;
    item8Article3?: string;
    item8Article4?: string;
    item8Article5?: string;
    item8Article6?: string;
    item8Article7?: string;

    libraryTitleItem9?: string;
    libraryTitleDescription9?: string;
    item9DescriptionText?: string;
    item9Article1?: string;
    item9Article2?: string;
    item9Article3?: string;
    item9Article4?: string;

    libraryTitleItem10?: string;
    libraryTitleDescription10?: string;
    item10DescriptionText?: string;
    item10Article1?: string;
    item10Article2?: string;
    item10Article3?: string;
    item10Article4?: string;

    libraryTitleItem11?: string;
    libraryTitleDescription11?: string;
    item11DescriptionText?: string;
    item11Article1?: string;
    item11Article2?: string;
    item11Article3?: string;
    item11Article4?: string;

    libraryTitleItem12?: string;
    libraryTitleDescription12?: string;
    item12DescriptionText?: string;
    item12Article1?: string;
    item12Article2?: string;
    item12Article3?: string;

    libraryTitleItem13?: string;
    libraryTitleDescription13?: string;
    item13DescriptionText?: string;
    item13Article1?: string;
    item13Article2?: string;
    item13Article3?: string;
    item13Article4?: string;

    libraryTitleItem14?: string;
    libraryTitleDescription14?: string;
    item14DescriptionText?: string;
    item14Article1?: string;
    item14Article2?: string;
    item14Article3?: string;
    item14Article4?: string;
    item14Article5?: string;

    libraryTitleItem15?: string;
    libraryTitleDescription15?: string;
    item15DescriptionText?: string;
    item15Article1?: string;
    item15Article2?: string;
    item15Article3?: string;

    libraryTitleItem16?: string;
    libraryTitleDescription16?: string;
    item16DescriptionText?: string;
    item16Article1?: string;
    item16Article2?: string;
    item16Article3?: string;

    libraryTitleItem17?: string;
    item17DescriptionText?: string;
    item17Article1?: string;
    item17Article2?: string;
    item17Article3?: string;
    item17Article4?: string;

    libraryTitleItem18?: string;
    libraryTitleDescription18?: string;
    item18DescriptionText?: string;
    item18Article1?: string;
    item18Article2?: string;
    item18Article3?: string;

    products: Item[] = [];

    constructor(
        private translationService: TranslationService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef
    ) {
        this.setTranslations();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.itemId = params.get('id');
            this.item = this.products.find(p => p.id === this.itemId);
        });
        this.languageSubscription = this.translationService.currentLanguage$.subscribe(() => {
            this.setTranslations();
            this.updateItem();
            this.cdr.detectChanges();
        });
        this.setTranslations();
        this.updateItem();
        this.cdr.detectChanges();
        this.initializePanorama();
    }

    private initializePanorama() {
        if (this.item?.content360) {
            pannellum.viewer('panorama', {
                type: 'equirectangular',
                panorama: this.item.content360,
                autoLoad: true,
                compass: true
            });
        }
    }

    sanitizeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    private setTranslations() {
        this.aboutUs = this.translationService.translate('aboutUs');
        this.aboutUsDescription =
            this.translationService.translate('aboutUsDescription');
        this.libraryTitleItem1 = this.translationService.translate('libraryTitleItem1');
        this.libraryTitleDescription = this.translationService.translate('libraryTitleDescription');
        this.item1DescriptionText = this.translationService.translate('item1DescriptionText');
        this.itemArticle1 = this.translationService.translate('itemArticle1');
        this.itemArticle2 = this.translationService.translate('itemArticle2');
        this.itemArticle3 = this.translationService.translate('itemArticle3');
        this.itemArticle4 = this.translationService.translate('itemArticle4');
        this.itemArticle5 = this.translationService.translate('itemArticle5');
        this.itemArticle6 = this.translationService.translate('itemArticle6');

        this.libraryTitleItem2 = this.translationService.translate('libraryTitleItem2');
        this.libraryTitleDescription2 = this.translationService.translate('libraryTitleDescription2');
        this.item2DescriptionText = this.translationService.translate('item2DescriptionText');
        this.item2Article1 = this.translationService.translate('item2Article1');
        this.item2Article2 = this.translationService.translate('item2Article2');
        this.item2Article3 = this.translationService.translate('item2Article3');
        this.item2Article4 = this.translationService.translate('item2Article4');

        this.libraryTitleItem3 = this.translationService.translate('libraryTitleItem3');
        this.libraryTitleDescription3 = this.translationService.translate('libraryTitleDescription3');
        this.item3DescriptionText = this.translationService.translate('item3DescriptionText');
        this.item3Article1 = this.translationService.translate('item3Article1');
        this.item3Article2 = this.translationService.translate('item3Article2');
        this.item3Article3 = this.translationService.translate('item3Article3');
        this.item3Article4 = this.translationService.translate('item3Article4');
        this.item3Article5 = this.translationService.translate('item3Article5');

        this.libraryTitleItem4 = this.translationService.translate('libraryTitleItem4');
        this.item4DescriptionText = this.translationService.translate('item4DescriptionText');
        this.item4Article1 = this.translationService.translate('item4Article1');
        this.item4Article2 = this.translationService.translate('item4Article2');

        this.libraryTitleItem5 = this.translationService.translate('libraryTitleItem5');
        this.item5DescriptionText = this.translationService.translate('item5DescriptionText');
        this.item5Article1 = this.translationService.translate('item5Article1');
        this.item5Article2 = this.translationService.translate('item5Article2');
        this.item5Article3 = this.translationService.translate('item5Article3');
        this.item5Article4 = this.translationService.translate('item5Article4');

        this.libraryTitleItem6 = this.translationService.translate('libraryTitleItem6');
        this.libraryTitleDescription6 = this.translationService.translate('libraryTitleDescription6');
        this.item6DescriptionText = this.translationService.translate('item6DescriptionText');
        this.item6Article1 = this.translationService.translate('item6Article1');
        this.item6Article2 = this.translationService.translate('item6Article2');
        this.item6Article3 = this.translationService.translate('item6Article3');
        this.item6Article4 = this.translationService.translate('item6Article4');

        this.libraryTitleItem7 = this.translationService.translate('libraryTitleItem7');
        this.libraryTitleDescription7 = this.translationService.translate('libraryTitleDescription7');
        this.item7DescriptionText = this.translationService.translate('item7DescriptionText');
        this.item7Article1 = this.translationService.translate('item7Article1');
        this.item7Article2 = this.translationService.translate('item7Article2');

        this.libraryTitleItem8 = this.translationService.translate('libraryTitleItem8');
        this.libraryTitleDescription8 = this.translationService.translate('libraryTitleDescription8');
        this.item8DescriptionText = this.translationService.translate('item8DescriptionText');
        this.item8Article1 = this.translationService.translate('item8Article1');
        this.item8Article2 = this.translationService.translate('item8Article2');
        this.item8Article3 = this.translationService.translate('item8Article3');
        this.item8Article4 = this.translationService.translate('item8Article4');
        this.item8Article5 = this.translationService.translate('item8Article5');
        this.item8Article6 = this.translationService.translate('item8Article6');
        this.item8Article7 = this.translationService.translate('item8Article7');

        this.libraryTitleItem9 = this.translationService.translate('libraryTitleItem9');
        this.libraryTitleDescription9 = this.translationService.translate('libraryTitleDescription9');
        this.item9DescriptionText = this.translationService.translate('item9DescriptionText');
        this.item9Article1 = this.translationService.translate('item9Article1');
        this.item9Article2 = this.translationService.translate('item9Article2');
        this.item9Article3 = this.translationService.translate('item9Article3');
        this.item9Article4 = this.translationService.translate('item9Article4');

        this.libraryTitleItem10 = this.translationService.translate('libraryTitleItem10');
        this.libraryTitleDescription10 = this.translationService.translate('libraryTitleDescription10');
        this.item10DescriptionText = this.translationService.translate('item10DescriptionText');
        this.item10Article1 = this.translationService.translate('item10Article1');
        this.item10Article2 = this.translationService.translate('item10Article2');
        this.item10Article3 = this.translationService.translate('item10Article3');
        this.item10Article4 = this.translationService.translate('item10Article4');

        this.libraryTitleItem11 = this.translationService.translate('libraryTitleItem11');
        this.libraryTitleDescription11 = this.translationService.translate('libraryTitleDescription11');
        this.item11DescriptionText = this.translationService.translate('item11DescriptionText');
        this.item11Article1 = this.translationService.translate('item11Article1');
        this.item11Article2 = this.translationService.translate('item11Article2');
        this.item11Article3 = this.translationService.translate('item11Article3');
        this.item11Article4 = this.translationService.translate('item11Article4');

        this.libraryTitleItem12 = this.translationService.translate('libraryTitleItem12');
        this.libraryTitleDescription12 = this.translationService.translate('libraryTitleDescription12');
        this.item12DescriptionText = this.translationService.translate('item12DescriptionText');
        this.item12Article1 = this.translationService.translate('item12Article1');
        this.item12Article2 = this.translationService.translate('item12Article2');
        this.item12Article3 = this.translationService.translate('item12Article3');

        this.libraryTitleItem13 = this.translationService.translate('libraryTitleItem13');
        this.libraryTitleDescription13 = this.translationService.translate('libraryTitleDescription13');
        this.item13DescriptionText = this.translationService.translate('item13DescriptionText');
        this.item13Article1 = this.translationService.translate('item13Article1');
        this.item13Article2 = this.translationService.translate('item13Article2');
        this.item13Article3 = this.translationService.translate('item13Article3');
        this.item13Article4 = this.translationService.translate('item13Article4');

        this.libraryTitleItem14 = this.translationService.translate('libraryTitleItem14');
        this.libraryTitleDescription14 = this.translationService.translate('libraryTitleDescription14');
        this.item14DescriptionText = this.translationService.translate('item14DescriptionText');
        this.item14Article1 = this.translationService.translate('item14Article1');
        this.item14Article2 = this.translationService.translate('item14Article2');
        this.item14Article3 = this.translationService.translate('item14Article3');
        this.item14Article4 = this.translationService.translate('item14Article4');
        this.item14Article5 = this.translationService.translate('item14Article5');

        this.libraryTitleItem15 = this.translationService.translate('libraryTitleItem15');
        this.libraryTitleDescription15 = this.translationService.translate('libraryTitleDescription15');
        this.item15DescriptionText = this.translationService.translate('item15DescriptionText');
        this.item15Article1 = this.translationService.translate('item15Article1');
        this.item15Article2 = this.translationService.translate('item15Article2');
        this.item15Article3 = this.translationService.translate('item15Article3');

        this.libraryTitleItem16 = this.translationService.translate('libraryTitleItem16');
        this.libraryTitleDescription16 = this.translationService.translate('libraryTitleDescription16');
        this.item16DescriptionText = this.translationService.translate('item16DescriptionText');
        this.item16Article1 = this.translationService.translate('item16Article1');
        this.item16Article2 = this.translationService.translate('item16Article2');
        this.item16Article3 = this.translationService.translate('item16Article3');

        this.libraryTitleItem17 = this.translationService.translate('libraryTitleItem17');
        this.item17DescriptionText = this.translationService.translate('item17DescriptionText');
        this.item17Article1 = this.translationService.translate('item17Article1');
        this.item17Article2 = this.translationService.translate('item17Article2');
        this.item17Article3 = this.translationService.translate('item17Article3');
        this.item17Article4 = this.translationService.translate('item17Article4');

        this.libraryTitleItem18 = this.translationService.translate('libraryTitleItem18');
        this.libraryTitleDescription18 = this.translationService.translate('libraryTitleDescription18');
        this.item18DescriptionText = this.translationService.translate('item18DescriptionText');
        this.item18Article1 = this.translationService.translate('item18Article1');
        this.item18Article2 = this.translationService.translate('item18Article2');
        this.item18Article3 = this.translationService.translate('item18Article3');

        this.notFound = this.translationService.translate('notFound');

        this.products = [
            {
                id: 'ivana-cupala-camp',
                banner: 'assets/images/library/item_1/banner.JPG',
                name: `${this.libraryTitleItem1}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription}</span>`,
                mainDescription: this.item1DescriptionText,
                text_blocks: [
                    this.itemArticle1,
                    this.itemArticle2,
                    this.itemArticle3,
                    this.itemArticle4,
                    this.itemArticle5,
                ],
                img_blocks: [
                    'assets/images/library/item_1/article/9.jpg',
                    'assets/images/library/item_1/article/10.jpg',
                    'assets/images/library/item_1/article/4.JPG',
                    'assets/images/library/item_1/article/5.JPG',
                    'assets/images/library/item_1/article/3.JPG',
                ],
                content360: null,
                carousel: [
                    'assets/images/library/item_1/carousel/photo_1.jpg',
                    'assets/images/library/item_1/carousel/photo_2.jpg',
                    'assets/images/library/item_1/carousel/photo_3.jpg',
                    'assets/images/library/item_1/carousel/photo_4.JPG',
                    'assets/images/library/item_1/carousel/photo_5.JPG'
                ]
            },
            {
                id: 'soroca-fortress',
                banner: 'assets/images/library/item_2/banner.jpg',
                name: `${this.libraryTitleItem2}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription2}</span>`,
                mainDescription: this.item2DescriptionText,
                text_blocks: [
                    this.item2Article1,
                    this.item2Article2,
                    this.item2Article3,
                    this.item2Article4,
                ],
                img_blocks: [
                    'assets/images/library/item_2/article/1.jpg',
                    'assets/images/library/item_2/article/2.jpg',
                    'assets/images/library/item_2/article/3.jpg',
                ],
                content360: 'assets/images/library/item_2/mock360.jpg',
                carousel: [
                    'assets/images/library/item_2/carousel/photo_1.jpg',
                    'assets/images/library/item_2/carousel/photo_2.jpg',
                    'assets/images/library/item_2/carousel/photo_3.jpg',
                    'assets/images/library/item_2/carousel/photo_4.jpg',
                    'assets/images/library/item_2/carousel/photo_5.jpg',
                    'assets/images/library/item_2/carousel/photo_6.jpg',
                    'assets/images/library/item_2/carousel/photo_7.jpg',
                    'assets/images/library/item_2/carousel/photo_8.jpg',
                    'assets/images/library/item_2/carousel/photo_9.jpg'
                ]
            },
            {
                id: 'cosauți-monastery',
                banner: 'assets/images/library/item_3/banner.jpg',
                name: this.libraryTitleItem3,
                mainDescription: this.item3DescriptionText,
                text_blocks: [
                    this.item3Article1,
                    this.item3Article2,
                    this.item3Article3,
                    this.item3Article4,
                    this.item3Article5,
                ],
                img_blocks: [
                    'assets/images/library/item_3/article/1.jpg',
                    'assets/images/library/item_3/article/2.jpg',
                    'assets/images/library/item_3/article/3.jpg',
                    'assets/images/library/item_3/article/4.jpg',
                ],
                content360: 'assets/images/library/item_3/mock360.jpg',
                carousel: [
                    'assets/images/library/item_3/carousel/photo_1.jpg',
                    'assets/images/library/item_3/carousel/photo_2.jpg',
                    'assets/images/library/item_3/carousel/photo_3.jpg',
                    'assets/images/library/item_3/carousel/photo_4.jpg',
                    'assets/images/library/item_3/carousel/photo_5.jpg',
                    'assets/images/library/item_3/carousel/photo_6.jpg'
                ]
            },
            {
                id: 'dealul-tiganesc',
                banner: 'assets/images/library/item_4/banner.jpg',
                name: this.libraryTitleItem4,
                mainDescription: this.item4DescriptionText,
                text_blocks: [
                    this.item4Article1,
                    this.item4Article2
                ],
                img_blocks: [
                    'assets/images/library/item_4/article/1.jpg',
                    'assets/images/library/item_4/article/2.jpg',
                ],
                content360: 'assets/images/library/item_4/mock360.jpg',
                carousel: null
            },
            {
                id: 'bechirs-gorge',
                banner: 'assets/images/library/item_5/banner.jpg',
                name: this.libraryTitleItem5,
                mainDescription: this.item5DescriptionText,
                text_blocks: [
                    this.item5Article1,
                    this.item5Article2,
                    this.item5Article3,
                    this.item5Article4
                ],
                img_blocks: [
                    'assets/images/library/item_5/article/1.jpg',
                    'assets/images/library/item_5/article/2.jpg',
                    'assets/images/library/item_5/article/3.jpg',
                ],
                content360: 'assets/images/library/item_5/mock360.jpg',
                carousel: null
            },
            {
                id: 'taul-park',
                banner: 'assets/images/library/item_6/banner.jpg',
                name: `${this.libraryTitleItem6}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription6}</span>`,
                mainDescription: this.item6DescriptionText,
                text_blocks: [
                    this.item6Article1,
                    this.item6Article2,
                    this.item6Article3,
                    this.item6Article4,
                ],
                img_blocks: [
                    'assets/images/library/item_6/article/1.jpg',
                    'assets/images/library/item_6/article/2.jpg',
                    'assets/images/library/item_6/article/3.jpg',
                    'assets/images/library/item_6/article/4.jpg',
                ],
                content360: 'assets/images/library/item_6/mock360.jpg',
                carousel: [
                    'assets/images/library/item_6/carousel/photo_1.jpg',
                    'assets/images/library/item_6/carousel/photo_2.jpg',
                    'assets/images/library/item_6/carousel/photo_3.jpg',
                    'assets/images/library/item_6/carousel/photo_4.jpg',
                    'assets/images/library/item_6/carousel/photo_5.jpg',
                    'assets/images/library/item_6/carousel/photo_6.jpg',
                    'assets/images/library/item_6/carousel/photo_7.jpg',
                    'assets/images/library/item_6/carousel/photo_8.jpg',
                    'assets/images/library/item_6/carousel/photo_9.jpg',
                    'assets/images/library/item_6/carousel/photo_10.jpg'
                ]
            },
            {
                id: 'church-in-braicau',
                banner: 'assets/images/library/item_7/banner.jpg',
                name: `${this.libraryTitleItem7}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription7}</span>`,
                mainDescription: this.item7DescriptionText,
                text_blocks: [
                    this.item7Article1,
                    this.item7Article2,
                ],
                img_blocks: [
                    'assets/images/library/item_7/article/1.jpg',
                    'assets/images/library/item_7/article/2.jpg',
                ],
                content360: 'assets/images/library/item_7/mock360.jpg',
                carousel: [
                    'assets/images/library/item_7/carousel/photo_1.jpg',
                    'assets/images/library/item_7/carousel/photo_2.jpg',
                    'assets/images/library/item_7/carousel/photo_3.jpg',
                    'assets/images/library/item_7/carousel/photo_4.jpg',
                    'assets/images/library/item_7/carousel/photo_5.jpg',
                    'assets/images/library/item_7/carousel/photo_6.jpg',
                    'assets/images/library/item_7/carousel/photo_7.jpg'
                ]
            },
            {
                id: 'bender-fortress',
                banner: 'assets/images/library/item_8/banner.jpg',
                name: `${this.libraryTitleItem8}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription8}</span>`,
                mainDescription: this.item8DescriptionText,
                text_blocks: [
                    this.item8Article1,
                    this.item8Article2,
                    this.item8Article3,
                    this.item8Article4,
                    this.item8Article5,
                    this.item8Article6,
                    this.item8Article7,
                ],
                img_blocks: [
                    'assets/images/library/item_8/article/1.jpg',
                    'assets/images/library/item_8/article/2.jpg',
                    'assets/images/library/item_8/article/3.1.jpg',
                    'assets/images/library/item_8/article/3.2.jpg',
                    'assets/images/library/item_8/article/4.jpg',
                    'assets/images/library/item_8/article/5.jpg',
                    'assets/images/library/item_8/article/6.jpg',
                ],
                content360: 'assets/images/library/item_8/mock360.jpg',
                carousel: [
                    'assets/images/library/item_8/carousel/photo_1.jpg',
                    'assets/images/library/item_8/carousel/photo_2.jpg',
                    'assets/images/library/item_8/carousel/photo_3.jpg',
                    'assets/images/library/item_8/carousel/photo_4.jpg',
                    'assets/images/library/item_8/carousel/photo_5.jpg',
                    'assets/images/library/item_8/carousel/photo_6.jpg',
                    'assets/images/library/item_8/carousel/photo_7.jpg',
                    'assets/images/library/item_8/carousel/photo_8.jpg',
                    'assets/images/library/item_8/carousel/photo_9.jpg',
                    'assets/images/library/item_8/carousel/photo_10.jpg',
                    'assets/images/library/item_8/carousel/photo_11.jpg',
                    'assets/images/library/item_8/carousel/photo_12.jpg',
                    'assets/images/library/item_8/carousel/photo_13.jpg',
                    'assets/images/library/item_8/carousel/photo_14.jpg',
                    'assets/images/library/item_8/carousel/photo_15.jpg',
                    'assets/images/library/item_8/carousel/photo_16.jpg',
                    'assets/images/library/item_8/carousel/photo_17.jpg',
                    'assets/images/library/item_8/carousel/photo_18.jpg',
                    'assets/images/library/item_8/carousel/photo_19.jpg',
                    'assets/images/library/item_8/carousel/photo_20.jpg',
                    'assets/images/library/item_8/carousel/photo_21.jpg',
                    'assets/images/library/item_8/carousel/photo_22.jpg',
                    'assets/images/library/item_8/carousel/photo_23.jpg',
                    'assets/images/library/item_8/carousel/photo_24.jpg',
                    'assets/images/library/item_8/carousel/photo_25.jpg'
                ]
            },
            {
                id: 'bottle-museum',
                banner: 'assets/images/library/item_9/banner.jpg',
                name: `${this.libraryTitleItem9}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription9}</span>`,
                mainDescription: this.item9DescriptionText,
                text_blocks: [
                    this.item9Article1,
                    this.item9Article2,
                    this.item9Article3,
                    this.item9Article4,
                ],
                img_blocks: [
                    'assets/images/library/item_9/article/1.jpg',
                    'assets/images/library/item_9/article/2.jpg',
                    'assets/images/library/item_9/article/3.jpg',
                    'assets/images/library/item_9/article/4.jpg',
                ],
                content360: 'assets/images/library/item_9/mock360.jpg',
                carousel: [
                    'assets/images/library/item_9/carousel/photo_1.jpg',
                    'assets/images/library/item_9/carousel/photo_2.jpg',
                    'assets/images/library/item_9/carousel/photo_3.jpg',
                    'assets/images/library/item_9/carousel/photo_4.jpg',
                    'assets/images/library/item_9/carousel/photo_5.jpg',
                    'assets/images/library/item_9/carousel/photo_6.jpg',
                    'assets/images/library/item_9/carousel/photo_7.jpg',
                    'assets/images/library/item_9/carousel/photo_8.jpg',
                    'assets/images/library/item_9/carousel/photo_9.jpg',
                    'assets/images/library/item_9/carousel/photo_10.jpg',
                    'assets/images/library/item_9/carousel/photo_11.jpg',
                    'assets/images/library/item_9/carousel/photo_12.jpg',
                    'assets/images/library/item_9/carousel/photo_13.jpg',
                    'assets/images/library/item_9/carousel/photo_14.jpg',
                    'assets/images/library/item_9/carousel/photo_15.jpg',
                    'assets/images/library/item_9/carousel/photo_16.jpg',
                    'assets/images/library/item_9/carousel/photo_17.jpg',
                    'assets/images/library/item_9/carousel/photo_18.jpg',
                    'assets/images/library/item_9/carousel/photo_19.jpg',
                    'assets/images/library/item_9/carousel/photo_20.jpg',
                    'assets/images/library/item_9/carousel/photo_21.jpg',
                    'assets/images/library/item_9/carousel/photo_22.jpg',
                    'assets/images/library/item_9/carousel/photo_23.jpg',
                    'assets/images/library/item_9/carousel/photo_24.jpg',
                    'assets/images/library/item_9/carousel/photo_25.jpg',
                    'assets/images/library/item_9/carousel/photo_26.jpg',
                    'assets/images/library/item_9/carousel/photo_27.jpg'
                ]
            },
            {
                id: 'calarasovca-monastery',
                banner: 'assets/images/library/item_10/banner.jpg',
                name: `${this.libraryTitleItem10}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription10}</span>`,
                mainDescription: this.item10DescriptionText,
                text_blocks: [
                    this.item10Article1,
                    this.item10Article2,
                    this.item10Article3,
                    this.item10Article4,
                ],
                img_blocks: [
                    'assets/images/library/item_10/article/1.jpg',
                    'assets/images/library/item_10/article/2.jpg',
                    'assets/images/library/item_10/article/3.jpg',
                    'assets/images/library/item_10/article/4.jpg',
                ],
                content360: 'assets/images/library/item_10/mock360.jpg',
                carousel: [
                    'assets/images/library/item_10/carousel/photo_1.jpg',
                    'assets/images/library/item_10/carousel/photo_2.jpg',
                    'assets/images/library/item_10/carousel/photo_3.jpg',
                    'assets/images/library/item_10/carousel/photo_4.jpg',
                    'assets/images/library/item_10/carousel/photo_5.jpg',
                    'assets/images/library/item_10/carousel/photo_6.jpg',
                    'assets/images/library/item_10/carousel/photo_7.jpg',
                    'assets/images/library/item_10/carousel/photo_8.jpg',
                    'assets/images/library/item_10/carousel/photo_9.jpg',
                    'assets/images/library/item_10/carousel/photo_10.jpg',
                    'assets/images/library/item_10/carousel/photo_11.jpg',
                    'assets/images/library/item_10/carousel/photo_12.jpg',
                    'assets/images/library/item_10/carousel/photo_13.jpg',
                    'assets/images/library/item_10/carousel/photo_14.jpg',
                    'assets/images/library/item_10/carousel/photo_15.jpg',
                    'assets/images/library/item_10/carousel/photo_16.jpg',
                    'assets/images/library/item_10/carousel/photo_17.jpg'
                ]
            },
            {
                id: 'orpheus-stephen-the-great',
                banner: 'assets/images/library/item_11/banner.jpg',
                name: `${this.libraryTitleItem11}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription11}</span>`,
                mainDescription: this.item11DescriptionText,
                text_blocks: [
                    this.item11Article1,
                    this.item11Article2,
                    this.item11Article3,
                    this.item11Article4,
                ],
                img_blocks: [
                    'assets/images/library/item_11/article/1.jpg',
                    'assets/images/library/item_11/article/2.jpg',
                    'assets/images/library/item_11/article/3.jpg',
                    'assets/images/library/item_11/article/4.jpg',
                ],
                content360: 'assets/images/library/item_11/mock360.jpg',
                carousel: [
                    'assets/images/library/item_11/carousel/photo_1.jpg',
                    'assets/images/library/item_11/carousel/photo_2.jpg',
                    'assets/images/library/item_11/carousel/photo_3.jpg',
                    'assets/images/library/item_11/carousel/photo_4.jpg',
                    'assets/images/library/item_11/carousel/photo_5.jpg',
                    'assets/images/library/item_11/carousel/photo_6.jpg',
                    'assets/images/library/item_11/carousel/photo_7.jpg',
                    'assets/images/library/item_11/carousel/photo_8.jpg',
                    'assets/images/library/item_11/carousel/photo_9.jpg',
                    'assets/images/library/item_11/carousel/photo_10.jpg',
                    'assets/images/library/item_11/carousel/photo_11.jpg',
                    'assets/images/library/item_11/carousel/photo_12.jpg',
                ]
            },
            {
                id: 'saharna-monastery',
                banner: 'assets/images/library/item_12/banner.jpg',
                name: `${this.libraryTitleItem12}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription12}</span>`,
                mainDescription: this.item12DescriptionText,
                text_blocks: [
                    this.item12Article1,
                    this.item12Article2,
                    this.item12Article3,
                ],
                img_blocks: [
                    'assets/images/library/item_12/article/1.jpg',
                    'assets/images/library/item_12/article/2.jpg',
                    'assets/images/library/item_12/article/3.jpg',
                ],
                content360: 'assets/images/library/item_12/mock360.jpg',
                carousel: [
                    'assets/images/library/item_12/carousel/photo_1.jpg',
                    'assets/images/library/item_12/carousel/photo_2.jpg',
                    'assets/images/library/item_12/carousel/photo_3.jpg',
                    'assets/images/library/item_12/carousel/photo_4.jpg',
                    'assets/images/library/item_12/carousel/photo_5.jpg',
                    'assets/images/library/item_12/carousel/photo_6.jpg',
                    'assets/images/library/item_12/carousel/photo_7.jpg',
                    'assets/images/library/item_12/carousel/photo_8.jpg',
                    'assets/images/library/item_12/carousel/photo_9.jpg',
                    'assets/images/library/item_12/carousel/photo_10.jpg',
                    'assets/images/library/item_12/carousel/photo_11.jpg',
                    'assets/images/library/item_12/carousel/photo_12.jpg',
                ]
            },
            {
                id: 'old-orhei',
                banner: 'assets/images/library/item_13/banner.jpg',
                name: `${this.libraryTitleItem13}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription13}</span>`,
                mainDescription: this.item13DescriptionText,
                text_blocks: [
                    this.item13Article1,
                    this.item13Article2,
                    this.item13Article3,
                    this.item13Article4,
                ],
                img_blocks: [
                    'assets/images/library/item_13/article/1.jpg',
                    'assets/images/library/item_13/article/2.jpg',
                    'assets/images/library/item_13/article/3.jpg',
                    'assets/images/library/item_13/article/4.jpg',
                ],
                content360: 'assets/images/library/item_13/mock360.jpg',
                carousel: [
                    'assets/images/library/item_13/carousel/photo_1.jpg',
                    'assets/images/library/item_13/carousel/photo_2.jpg',
                    'assets/images/library/item_13/carousel/photo_3.jpg',
                    'assets/images/library/item_13/carousel/photo_4.jpg',
                    'assets/images/library/item_13/carousel/photo_5.jpg',
                    'assets/images/library/item_13/carousel/photo_6.jpg',
                    'assets/images/library/item_13/carousel/photo_7.jpg',
                    'assets/images/library/item_13/carousel/photo_8.jpg',
                    'assets/images/library/item_13/carousel/photo_9.jpg',
                    'assets/images/library/item_13/carousel/photo_10.jpg',
                    'assets/images/library/item_13/carousel/photo_11.jpg',
                ]
            },
            {
                id: 'monastery-rudi',
                banner: 'assets/images/library/item_14/banner.png',
                name: `${this.libraryTitleItem14}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription14}</span>`,
                mainDescription: this.item14DescriptionText,
                text_blocks: [
                    this.item14Article1,
                    this.item14Article2,
                    this.item14Article3,
                    this.item14Article4,
                    this.item14Article5,
                ],
                img_blocks: [
                    'assets/images/library/item_14/article/1.jpg',
                    'assets/images/library/item_14/article/2.jpg',
                    'assets/images/library/item_14/article/3.jpg',
                    'assets/images/library/item_14/article/4.jpg',
                    'assets/images/library/item_14/article/5.jpg',
                ],
                content360: 'assets/images/library/item_14/mock360.jpg',
                carousel: [
                    'assets/images/library/item_14/carousel/photo_1.jpg',
                    'assets/images/library/item_14/carousel/photo_2.jpg',
                    'assets/images/library/item_14/carousel/photo_3.jpg',
                    'assets/images/library/item_14/carousel/photo_4.jpg',
                    'assets/images/library/item_14/carousel/photo_5.jpg',
                    'assets/images/library/item_14/carousel/photo_6.jpg',
                ]
            },
            {
                id: 'struve-geodetic-arc',
                banner: 'assets/images/library/item_15/banner.png',
                name: `${this.libraryTitleItem15}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription15}</span>`,
                mainDescription: this.item15DescriptionText,
                text_blocks: [
                    this.item15Article1,
                    this.item15Article2,
                    this.item15Article3,
                ],
                img_blocks: [
                    'assets/images/library/item_15/article/1.jpg',
                    'assets/images/library/item_15/article/2.jpg',
                    'assets/images/library/item_15/article/3.jpg',
                ],
                content360: 'assets/images/library/item_15/mock360.jpg',
                carousel: [
                    'assets/images/library/item_15/carousel/photo_1.jpg',
                    'assets/images/library/item_15/carousel/photo_2.jpg',
                    'assets/images/library/item_15/carousel/photo_3.jpg',
                ]
            },
            {
                id: 'stone-age',
                banner: 'assets/images/library/item_16/banner.png',
                name: `${this.libraryTitleItem16}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription16}</span>`,
                mainDescription: this.item16DescriptionText,
                text_blocks: [
                    this.item16Article1,
                    this.item16Article2,
                    this.item16Article3,
                ],
                img_blocks: [
                    'assets/images/library/item_16/article/1.jpg',
                    'assets/images/library/item_16/article/2.jpg',
                    'assets/images/library/item_16/article/3.jpg',
                ],
                content360: 'assets/images/library/item_16/mock360.jpg',
                carousel: [
                    'assets/images/library/item_16/carousel/photo_1.JPG',
                    'assets/images/library/item_16/carousel/photo_2.jpg',
                    'assets/images/library/item_16/carousel/photo_3.jpg',
                    'assets/images/library/item_16/carousel/photo_4.jpg',
                    'assets/images/library/item_16/carousel/photo_5.jpg',
                    'assets/images/library/item_16/carousel/photo_6.jpg',
                    'assets/images/library/item_16/carousel/photo_7.jpg',
                    'assets/images/library/item_16/carousel/photo_8.jpg',
                    'assets/images/library/item_16/carousel/photo_9.jpg',
                ]
            },
            {
                id: 'chateau-vartely',
                banner: 'assets/images/library/item_17/banner.png',
                name: this.libraryTitleItem17,
                mainDescription: this.item17DescriptionText,
                text_blocks: [
                    this.item17Article1,
                    this.item17Article2,
                    this.item17Article3,
                    this.item17Article4,
                ],
                img_blocks: [
                    'assets/images/library/item_17/article/1.jpg',
                    'assets/images/library/item_17/article/2.jpg',
                    'assets/images/library/item_17/article/3.JPG',
                    'assets/images/library/item_17/article/4.jpg',
                ],
                content360: 'assets/images/library/item_17/mock360.jpg',
                carousel: [
                    'assets/images/library/item_17/carousel/photo_1.jpg',
                    'assets/images/library/item_17/carousel/photo_2.jpg',
                    'assets/images/library/item_17/carousel/photo_3.jpg',
                    'assets/images/library/item_17/carousel/photo_4.jpg',
                    'assets/images/library/item_17/carousel/photo_5.jpg',
                    'assets/images/library/item_17/carousel/photo_6.jpg',
                    'assets/images/library/item_17/carousel/photo_7.jpg',
                    'assets/images/library/item_17/carousel/photo_8.jpg',
                    'assets/images/library/item_17/carousel/photo_9.jpg',
                    'assets/images/library/item_17/carousel/photo_10.jpg',
                    'assets/images/library/item_17/carousel/photo_11.jpg',
                    'assets/images/library/item_17/carousel/photo_12.jpg',
                    'assets/images/library/item_17/carousel/photo_13.jpg',
                    'assets/images/library/item_17/carousel/photo_14.jpg',
                    'assets/images/library/item_17/carousel/photo_15.jpg',
                    'assets/images/library/item_17/carousel/photo_16.JPG',
                ]
            },
            {
                id: 'village-museum',
                banner: 'assets/images/library/item_18/banner.png',
                name: `${this.libraryTitleItem18}<br><span style="font-size: 20px; color: white">${this.libraryTitleDescription18}</span>`,
                mainDescription: this.item18DescriptionText,
                text_blocks: [
                    this.item18Article1,
                    this.item18Article2,
                    this.item18Article3,
                ],
                img_blocks: [
                    'assets/images/library/item_18/article/1.jpg',
                    'assets/images/library/item_18/article/2.jpg',
                    'assets/images/library/item_18/article/3.jpg',
                ],
                content360: 'assets/images/library/item_18/mock360.jpg',
                carousel: [
                    'assets/images/library/item_18/carousel/photo_1.jpg',
                    'assets/images/library/item_18/carousel/photo_2.jpg',
                    'assets/images/library/item_18/carousel/photo_3.jpg',
                    'assets/images/library/item_18/carousel/photo_4.jpg',
                    'assets/images/library/item_18/carousel/photo_5.jpg',
                    'assets/images/library/item_18/carousel/photo_6.jpg',
                    'assets/images/library/item_18/carousel/photo_7.jpg',
                    'assets/images/library/item_18/carousel/photo_8.jpg',
                    'assets/images/library/item_18/carousel/photo_9.jpg',
                    'assets/images/library/item_18/carousel/photo_10.jpg',
                    'assets/images/library/item_18/carousel/photo_11.jpg',
                    'assets/images/library/item_18/carousel/photo_12.jpg',
                    'assets/images/library/item_18/carousel/photo_13.jpg',
                    'assets/images/library/item_18/carousel/photo_14.jpg',
                    'assets/images/library/item_18/carousel/photo_15.jpg',
                ]
            },
        ];
    }

    private updateItem() {
        this.item = this.products.find(p => p.id === this.itemId);
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }
}

interface Item {
    id: string;
    banner: string | null;
    name: string;
    mainDescription: string | null;
    text_blocks: string[];
    img_blocks: string[];
    content360: string | null;
    carousel: string[] | null;
}
