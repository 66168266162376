<div class="header">
    <div class="nav">
        <img routerLink="/" ngSrc="assets/images/logo.svg" alt="UICD Logo" height="34" width="124" priority style="cursor: pointer">
        <div class="nav-links">
            <a routerLink="/" [class.active]="activeMenuItem === 'home'" (click)="setActiveMenuItem('home')">{{ menuItemHome }}</a>
            <a routerLink="/library" [class.active]="activeMenuItem === 'library'" (click)="setActiveMenuItem('library')">{{ menuItemLibrary }}</a>
            <a routerLink="/forum" [class.active]="activeMenuItem === 'forum'" (click)="setActiveMenuItem('forum')">{{ menuItemForum }}</a>
            <div class="dropdown" (click)="$event.stopPropagation()">
                <a class="dropbtn" (click)="toggleDropdown($event)" [class.active]="dropdownDisplay === 'block'">{{ currentLanguage.toUpperCase() }}</a>
                <div class="dropdown-content" [style.display]="dropdownDisplay">
                    <a *ngIf="currentLanguage !== 'en'" (click)="switchLanguage('en')">EN</a>
                    <a *ngIf="currentLanguage !== 'ru'" (click)="switchLanguage('ru')">RU</a>
                    <a *ngIf="currentLanguage !== 'ro'" (click)="switchLanguage('ro')">RO</a>
                    <a *ngIf="currentLanguage !== 'ua'" (click)="switchLanguage('ua')">UA</a>
                </div>
            </div>
        </div>
        <div class="menu-icon" id="menu-icon" (click)="toggleMobileNav()">
            <i class="fa-solid fa-bars"></i>
        </div>
    </div>
</div>

<div class="mobile-nav" id="mobile-nav" [style.display]="mobileNavDisplay" (click)="$event.stopPropagation()">
    <a class="close-btn" id="close-btn" (click)="closeMobileNav()">
        <i class="fa-solid fa-xmark"></i>
    </a>
    <a routerLink="/" [class.active]="activeMenuItem === 'home'" (click)="setActiveMenuItem('home')">{{ menuItemHome }}</a>
    <a routerLink="/library" [class.active]="activeMenuItem === 'library'" (click)="setActiveMenuItem('library')">{{ menuItemLibrary }}</a>
    <a routerLink="/forum" [class.active]="activeMenuItem === 'forum'" (click)="setActiveMenuItem('forum')">{{ menuItemForum }}</a>
    <br>
    <div class="language-switcher">
        <a *ngIf="currentLanguage !== 'en'" (click)="switchLanguage('en')">EN</a>
        <a *ngIf="currentLanguage !== 'ru'" (click)="switchLanguage('ru')">RU</a>
        <a *ngIf="currentLanguage !== 'ro'" (click)="switchLanguage('ro')">RO</a>
        <a *ngIf="currentLanguage !== 'ua'" (click)="switchLanguage('ua')">UA</a>
    </div>
</div>
