import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-slider',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements AfterViewInit {
    slides = [
        { src: 'assets/images/slider/card1.png' },
        { src: 'assets/images/slider/card2.jpg' },
        { src: 'assets/images/slider/card3.jpg' },
        { src: 'assets/images/slider/card4.jpg' },
        { src: 'assets/images/slider/card5.jpg' },
    ];
    currentIndex = 0;
    autoSlideInterval: any;

    @ViewChild('sliderWrapper') sliderWrapper: ElementRef | undefined;

    constructor() {}

    ngAfterViewInit(): void {
        this.updateSlidePosition();
        this.startAutoSlide();
    }

    startAutoSlide() {
        this.autoSlideInterval = setInterval(() => {
            this.nextSlide();
        }, 5000); // Перелистывание каждые 5 секунд
    }

    resetAutoSlide() {
        clearInterval(this.autoSlideInterval);
        this.startAutoSlide();
    }

    nextSlide() {
        this.currentIndex = (this.currentIndex + 1) % this.slides.length;
        this.updateSlidePosition();
        this.resetAutoSlide();
    }

    prevSlide() {
        this.currentIndex = (this.currentIndex - 1) % this.slides.length;
        this.updateSlidePosition();
        this.resetAutoSlide();
    }

    updateSlidePosition() {
        if (this.sliderWrapper?.nativeElement) {
            const sliderWrapper = this.sliderWrapper.nativeElement as HTMLElement;
            const slideWidth = sliderWrapper.querySelector('.slider-item')?.clientWidth || 0;
            const offset = slideWidth + 20; // Параметр отступа между слайдами

            if (this.currentIndex === this.slides.length || (window.innerWidth + this.currentIndex * offset) > offset * (this.slides.length + 1)) {
                setTimeout(() => {
                    sliderWrapper.style.transition = 'transform 0.5s ease-in-out';
                    this.currentIndex = 0;
                    sliderWrapper.style.transform = `translateX(-${this.currentIndex * offset}px)`;
                }, 50);
            } else if (this.currentIndex < 0) {
                setTimeout(() => {
                    sliderWrapper.style.transition = 'transform 0.5s ease-in-out';
                    this.currentIndex = Math.ceil((offset * this.slides.length - window.innerWidth)/offset);
                    sliderWrapper.style.transform = `translateX(-${this.currentIndex * offset}px)`;
                }, 50);
            } else {
                sliderWrapper.style.transition = 'transform 0.5s ease-in-out';
                sliderWrapper.style.transform = `translateX(-${this.currentIndex * offset}px)`;
            }
        }
    }
}
