import { Component } from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        NgOptimizedImage
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {}
