import {Component, OnInit} from '@angular/core';
import {LoadingService} from "../../services/loading.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-loading-page',
  standalone: true,
  imports: [],
  templateUrl: './loading-page.component.html',
  styleUrl: './loading-page.component.scss'
})
export class LoadingPageComponent implements OnInit {
    isLoading = false;
    private loadingSubscription?: Subscription;

    constructor(private loadingService: LoadingService) {}

    ngOnInit() {
        this.loadingSubscription = this.loadingService.loading$.subscribe(loading => {
            this.isLoading = loading;
        });
    }

    ngOnDestroy() {
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
        }
    }
}
